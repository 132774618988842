import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniquePipe } from './unique.pipe';
import { CompanyNameFilterPipe } from './company-name-filter.pipe';
import { ProfileFilterPipe } from './profile-filter.pipe';
import { FourDotFiveDateFormatPipe } from './four-dot-five-date-format.pipe';
import { ContractDurationPipe } from './contract-duration';
import { FourDotFiveTimeStampFormatPipe } from './four-dot-five-time-stamp-format';
import { CategoryInHierarchyPipe } from './category-in-hierarchy';
import { TextSentenceCasePipe } from './text-sentence-case';
import { FIlterSkillsPipe } from './filter-skills.pipe';
import { CompanyNameSettingPipe } from './company-name-setting';
import { ConvertDurationInYearsPipe } from './convert-duration-in-years.pipe';
import { VendorFilterPipe } from './vendor-filter';
import { TruncatePipe } from './truncate';

@NgModule({
  declarations: [
    UniquePipe, 
    CompanyNameFilterPipe, 
    ProfileFilterPipe, 
    FourDotFiveDateFormatPipe, 
    ContractDurationPipe, 
    FourDotFiveTimeStampFormatPipe, 
    CategoryInHierarchyPipe, 
    TextSentenceCasePipe, 
    FIlterSkillsPipe, 
    CompanyNameSettingPipe, 
    ConvertDurationInYearsPipe, 
    VendorFilterPipe,
    TruncatePipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    FourDotFiveTimeStampFormatPipe, 
    FourDotFiveDateFormatPipe, 
    CompanyNameSettingPipe, 
    ConvertDurationInYearsPipe
  ],
  exports: [
    UniquePipe, 
    CompanyNameFilterPipe, 
    ProfileFilterPipe, 
    FourDotFiveDateFormatPipe, 
    ContractDurationPipe, 
    FourDotFiveTimeStampFormatPipe, 
    CategoryInHierarchyPipe, 
    TextSentenceCasePipe, 
    FIlterSkillsPipe, 
    CompanyNameSettingPipe, 
    ConvertDurationInYearsPipe, 
    VendorFilterPipe,
    TruncatePipe
  ]
})
export class PipesModule { }
