import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-associate-vendor-action-modal',
  templateUrl: './associate-vendor-action-modal.component.html',
  styleUrls: ['./associate-vendor-action-modal.component.css']
})
export class AssociateVendorActionModalComponent implements OnInit {

  modalData: any;
  public onClose: Subject<any>;
  constructor(
    private bsModalService: BsModalService,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    if (this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  cancelCallback() {
    this.closeModal();
  }

  refresh() {
    this.onClose.next('refresh');
    this.closeModal();
  }

}
