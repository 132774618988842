import { Component, OnInit, Inject, Input, TemplateRef, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RequisitionEntitiesUploadComponent } from './requisition-entities-upload/requisition-entities-upload.component';
import { SkillTablePage } from 'src/app/core/models/skillTablePage';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { FourDotFiveDateFormatPipe } from 'src/app/core/pipes/four-dot-five-date-format.pipe';

declare var saveAs: any;
@Component({
  selector: 'app-manage-requisition-entities-page',
  templateUrl: './manage-requisition-entities-page.component.html',
  styleUrls: ['./manage-requisition-entities-page.component.css'],
  providers:[FourDotFiveDateFormatPipe]
})
export class ManageRequisitionEntitiesPageComponent implements OnInit {
  @Input() requisitionEntity;
  @Input() entitiesData;
  @Output() successCallback = new EventEmitter<any>();
  @ViewChild('degreeTemplate', { static: true }) degreeTemplate: TemplateRef<any>;
  @ViewChild('fieldOfStudyTemplate', { static: true }) fieldOfStudyTemplate: TemplateRef<any>;
  @ViewChild('entityActionsTemplate', {static: true}) entityActionsTemplateRef: TemplateRef<any>;
  @ViewChild('ignoreCheckboxTemplate', { static: true }) ignoreCheckboxTemplate!: TemplateRef<any>;
  @ViewChild('resolvedCheckboxTemplate', { static: true }) resolvedCheckboxTemplate!: TemplateRef<any>;

  uploadRequisitionEntitiesModal: BsModalRef;
  downloadRequisitionEntitiesModal: BsModalRef;
  addEntityModal: BsModalRef;
  editEntityModal: BsModalRef;
  requisitionEntitiesActivitiesTemplateModal: BsModalRef;
  viewUploadedEntitiesModal: BsModalRef;
  page = new SkillTablePage();
  allEntitiesList: any = [];
  columns: any = [];
  requisitionEntitiesList: any = [];
  downloadSkillFormat: any = 'xls';
  searchEntity: any = '';
  addEditEntityData: any;
  allRequisitionEntitiesList: any = [];
  setColumnMode: any = 'force';
  ignoreAndResolveList: any = [
    {name: 'True', value: 'true'},
    {name: 'False', value: 'false'},
  ];
  selectedIgnore: any = [];
  selectedResolved: any = [];

  isEntityLoading: boolean = false;
  isFiltersChanged: boolean = false;

  constructor(
    @Inject('$state') public $state: any,
    private alertAndNotificationService: AlertAndNotificationsService,
    private modalService: BsModalService,
    private angularModalService: AngularModalService,
    private cdr: ChangeDetectorRef,
    private skillsService: ManageSkillsService,
    private utilityService: UtilityService    
  ) { }

  ngOnInit() {
    this.setTemplate();
    this.setPage({ offset: 0 });
  }

  ngOnChanges(change) {
    if(change && change.entitiesData && !change.entitiesData.firstChange && (change.entitiesData.currentValue !== change.entitiesData.previousValue)) {
      this.requisitionEntitiesList = change.entitiesData ? this.setEntitiesData(change.entitiesData.currentValue.allEntities) : [];
      this.allRequisitionEntitiesList = this.setEntitiesData(this.requisitionEntitiesList);
      this.setTablePage(this.page);
    }
  }

  setEntitiesData(entity) {
    return JSON.parse(JSON.stringify(entity));
  }

  onSort(event) {
    this.isEntityLoading = true;

    setTimeout(() => {
      const rows = [...this.requisitionEntitiesList];
      const sort = event.sorts[0];
      rows.sort((a, b) => {
        const aValue = a[sort.prop] !== undefined && a[sort.prop] !== null ? a[sort.prop].toString() : '';
        const bValue = b[sort.prop] !== undefined && b[sort.prop] !== null ? b[sort.prop].toString() : '';
        return aValue.localeCompare(bValue) * (sort.dir === 'desc' ? -1 : 1);
      });

      let pageNumber = this.page.pageNumber;
      this.ngOnInit();
      this.requisitionEntitiesList = rows;
      this.setPage({ offset: pageNumber });
      this.setTablePage(this.page);
      this.isEntityLoading = false;
    }, 1000);
  }

  setPage(pageInfo) {
    this.page.pageNumber = 0;
    this.page.size = 20;
    this.page.pageNumber = pageInfo.offset;
    this.setTablePage(this.page);
  }

  setTablePage(pageInfo) {
    this.page.pageNumber = (!pageInfo.pageNumber && pageInfo.offset) ? pageInfo.offset : (pageInfo.pageNumber ? pageInfo.pageNumber : 0);
    this.page.totalElements = this.requisitionEntitiesList ? this.requisitionEntitiesList.length : 0;
    this.page.totalPages = this.page.totalElements / this.page.size;
    const start = this.page.pageNumber * this.page.size;
    const end = Math.min(start + this.page.size, this.page.totalElements);
    this.allEntitiesList = [];
    for (let i = start; i < end; i++) {
      let entity = this.requisitionEntitiesList[i];
      this.allEntitiesList.push(entity);
    }
    this.cdr.detectChanges();
  }

  setTemplate() {
    if(this.requisitionEntity === 'jdActions' || this.requisitionEntity === 'aactionWords') {
      this.setColumnMode = 'flex'
    }
    switch(this.requisitionEntity) {
      case 'degrees':
        this.columns = [{ prop: 'order', name: 'Level' }, { prop: 'degreeName', name: 'Degree Name' }, { prop: 'alternateName' }, { prop: 'fullName' }, { prop: 'enableFieldOfStudy' }, { prop: 'showInParenthesis' }, {prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'fieldOfStudy':
        this.columns = [{ prop: 'fieldOfStudy', name: 'Field of Study' }, { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'roles':
        this.columns = [{ prop: 'role', name: 'Role' }, { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'certificates':
        this.columns = [{ prop: 'name', name: 'Name' }, { prop: 'group', name: 'Group' }, { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'jdActions':
        this.columns = [
          { prop: 'order', name: "Level", maxWidth:100, flexGrow:1},
          { prop: 'actionword', name: 'Skills and Proficiency', minWidth:150, flexGrow:1 }, 
          { prop: 'description', name: 'Description', flexGrow:1 },
          { prop: 'yearsOfExperience', name: 'Experience in Years', flexGrow:1 },
          { prop: 'jobDescriptionLanguageDropdown', name: 'Job description Language Dropdown', flexGrow:2},
          { prop: 'alternates', name: 'Alternates', flexGrow:1 },
          { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false, flexGrow:1}
        ];
        break;
        case 'actionWords':
          this.columns = [
            { prop: 'words', name: "Skill/Action Word", flexGrow: 1, minWidth: 200 },
            { prop: 'displayRecognitionStatus', name: 'Recognition Status', flexGrow: 1, maxWidth: 150 },
            { prop: 'createdDate', name: 'First time date/time', flexGrow: 1, maxWidth: 180, pipe: new FourDotFiveDateFormatPipe() },
            { prop: 'mostRecentDateTime', name: 'Most Recent Date Time', flexGrow: 1, maxWidth: 220, pipe: new FourDotFiveDateFormatPipe() },
            { prop: 'count', name: 'Count', flexGrow: 1, maxWidth: 100 },
            { prop: 'identifier', name: 'Identifier/Marker', flexGrow: 1, maxWidth: 150 },
            { 
              prop: 'ignore', 
              name: 'Ignore', 
              flexGrow: 1, 
              maxWidth: 100, 
              sortable: false,
              cellTemplate: this.ignoreCheckboxTemplate 
            },
            { prop: 'resolved', name: 'Resolved', flexGrow: 1, maxWidth: 100, sortable: false, cellTemplate: this.resolvedCheckboxTemplate  }
          ];                   
        break;
      default:
        break;
    };
    
    this.requisitionEntitiesList = this.entitiesData ? this.setEntitiesData(this.entitiesData.allEntities) : [];
    this.allRequisitionEntitiesList = this.setEntitiesData(this.requisitionEntitiesList);
    this.setJdYearsOfExperience(this.requisitionEntitiesList);
    this.setJdYearsOfExperience(this.allRequisitionEntitiesList);
  }

  onIgnoreChange(row: any) {
    row.ignore = !row.ignore;
    this.saveEditEntity(row);
  }

  onResolveChange(row: any) {
    row.resolved = !row.resolved;
    this.saveEditEntity(row);
  }

  setSingleEntityTitle(type) {
    let title = '';
    switch (type) {
      case 'degrees':
        title = 'Degree';
        break;
      case 'fieldOfStudy':
        title = 'Field of Study';
        break;
      case 'roles':
        title = 'Role';
        break;
      case 'certificates':
        title = 'Certificate';
        break;
      case 'jdActions':
        title = 'JD Action Word';
        break;
      case 'actionWords':
        title = 'Action Word';
        break;
      default:
        title = 'Degrees';
        break;
    }
    return title;
  }

  setMultipleEntitiesTitle(type) {
    let title = '';
    switch (type) {
      case 'degrees':
        title = 'Degrees';
        break;
      case 'fieldOfStudy':
        title = 'Field of Study';
        break;
      case 'roles':
        title = 'Roles';
        break;
      case 'certificates':
        title = 'Certificates';
        break;
      case 'jdActions':
        title = 'JD Action Words';
        break;
      case 'actionWords':
        title = 'Action Words';
        break;
      default:
        title = 'Degrees';
        break;
    }
    return title;
  }

  uploadOrDownloadFile() {
      const config = new ModalConfig();
      const modalData: any = {
        action: this.requisitionEntity,
        type: 'uploadRequisitionEntities',
        title: `Upload ${this.setMultipleEntitiesTitle(this.requisitionEntity)}`
      };
      config.class = "custom-modal-65 custom-ngx-modal";
      config.initialState = modalData;
      config.ignoreBackdropClick = true;
      config.backdrop = true;
      this.uploadRequisitionEntitiesModal = this.modalService.show(RequisitionEntitiesUploadComponent, config);
      this.uploadRequisitionEntitiesModal.content.onClose.subscribe(result => {
        if (result && result == 'success') {
          if (this.successCallback) {
            this.successCallback.emit();
          }
        }
      });
    }

  downloadAllRequisitionEntities(template: TemplateRef<any>) {
    const config = new ModalConfig();
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.class = "custom-ngx-modal";
    const modalData: any = {};
    config.initialState = modalData;
    this.downloadRequisitionEntitiesModal = this.modalService.show(template, config);
  }

  closeDownloadRequisitionEntitiesModal() {
    this.downloadRequisitionEntitiesModal.hide();
  }

  setAndDownloadFile(response) {
    const headers = response.headers;
    const contentDisposition = headers.get('content-disposition');
    const fileName = contentDisposition.split(';')[1].trim().split('=')[1];
    saveAs(response.body, fileName);
    this.closeDownloadRequisitionEntitiesModal();
    this.utilityService.hideLoadingModal();
  }

  downloadAllRequisitionEntitiesFile(fileExtension) {
    this.utilityService.showLoadingModal("Downloading...");
    if (this.requisitionEntity === 'degrees') {
      this.skillsService.downloadDegreesFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.skillsService.downloadFieldOfStudyFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'roles') {
      this.skillsService.downloadCandidateRolesFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'certificates') {
      this.skillsService.downloadCertificationsFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'jdActions') {
      this.skillsService.downloadJdActionsFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    }
  } 

  onSearchTextChange(event) {
    if (this.requisitionEntity === 'actionWords') {
      this.isFiltersChanged = true;
    }
  }

  queryEntitySearches() {
    if (this.requisitionEntity === 'degrees') {
      this.getDegreesEntities();
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.getFieldOfStudyEntities();
    } else if (this.requisitionEntity === 'roles') {
      this.getRolesEntities();
    } else if (this.requisitionEntity === 'certificates') {
      this.getCertificatesEntities();
    } else if (this.requisitionEntity === 'jdActions') {
      this.getJdActionsEntities();
    } else if (this.requisitionEntity === 'actionWords') {
      this.getActionWordsEntities();
    }
  }

  getDegreesEntities() {
    this.isEntityLoading = true;
    let degreeFilerObj = {
      searchDegree: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllDegreesEntityLists(degreeFilerObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    })
  }

  getFieldOfStudyEntities() {
    this.isEntityLoading = true;
    let fieldOfStudyObj = {
      searchFieldOfStudy: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllFieldOfStudyLists(fieldOfStudyObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  getRolesEntities() {
    this.isEntityLoading = true;
    let roleObj = {
      searchRole: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCandidateRolesLists(roleObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  getCertificatesEntities() {
    this.isEntityLoading = true;
    let roleObj = {
      searchName: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCertificationsLists(roleObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  getJdActionsEntities() {
    this.isEntityLoading = true;
    let jdActionsObj = {
      searchName: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllJdActionsLists(jdActionsObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  formatSnakeCaseToTitle(text: string): string {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
  }

  getActionWordsEntities() {
    this.isEntityLoading = true;
    this.isFiltersChanged = false;

    let actionWordObj = {
      sortColumns: "",
      searchWords: this.searchEntity,
      resolved: this.selectedResolved.length === 1 ?  this.selectedResolved[0].value : 'none',
      recognitionStatus: null,
      ignore: this.selectedIgnore.length === 1 ?  this.selectedIgnore[0].value : 'none',
      sortDir: '',
    }

    this.skillsService.getAllActionWords(actionWordObj, (data) => {
      if (data.unknownMasterList.length > 0) {
        data.unknownMasterList.forEach(actionWord => {
            actionWord.displayRecognitionStatus = actionWord.recognitionStatus ? this.formatSnakeCaseToTitle(actionWord.recognitionStatus) : null;
        });
      }
      data.filteredEntities = this.setEntitiesData(data.unknownMasterList);
      data.allEntities = this.setEntitiesData(data.unknownMasterList);
      this.setAllEntitiesData(data);
      this.isEntityLoading = false;
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  convertExperienceOfJdActionsFromMonthsToYears(minExp, maxExp) {
    // Convert months to years and months
    const formatToYearsAndMonths = (months) => {
      const years = Math.floor(months / 12);
      const remainingMonths = months % 12;
      return { years, months: remainingMonths };
    };

    if (minExp === 0 && maxExp === 0) {
      // Case 1: Both minExp and maxExp are 0
      return '-';
    } else if (minExp > 0 && maxExp === 0) {
      // Case 2: minExp has a value, and maxExp is 0
      const minFormatted = formatToYearsAndMonths(minExp);
      if (minExp < 12) {
        return `${minExp}+ Months`;
      } else {
        return `${minFormatted.years}+ Year${minFormatted.years > 1 ? 's' : ''}`;
      }
    } else if (minExp < 12 && maxExp < 12) {
      // Case 3: Both minExp and maxExp are less than 12
      return `${minExp} - ${maxExp} Month${maxExp == 1 ? "" : 's'}`;
    } else if (minExp >= 12 && maxExp >= 12) {
      // Case 4: Both minExp and maxExp are greater than or equal to 12
      const minFormatted = formatToYearsAndMonths(minExp);
      const maxFormatted = formatToYearsAndMonths(maxExp);

      if (minFormatted.months === 0 && maxFormatted.months === 0) {
        return `${minFormatted.years} - ${maxFormatted.years} Years`;
      }

      const minText = `${minFormatted.years} Year${minFormatted.years > 1 ? 's' : ''} ${minFormatted.months ? `${minFormatted.months} Month${minFormatted.months > 1 ? 's' : ''}` : ''}`;
      const maxText = `${maxFormatted.years} Year${maxFormatted.years > 1 ? 's' : ''} ${maxFormatted.months ? `${maxFormatted.months} Month${maxFormatted.months > 1 ? 's' : ''}` : ''}`;
      return `${minText} - ${maxText}`;
    } else {
      // Case 5: Mixed values (minExp < 12 or maxExp < 12)
      let minFormattedText = "";
      let maxFormattedText = "";
      if(minExp < 12) {
        minFormattedText = minExp == 0 ? '0' : `${minExp} Month${minExp == 1 ? "" : 's'}`;
        const maxFormatted = formatToYearsAndMonths(maxExp);
        maxFormattedText = `${maxFormatted.years} Year${maxFormatted.years > 1 ? 's' : ''} ${maxFormatted.months ? `${maxFormatted.months} Month${maxFormatted.months > 1 ? 's' : ''}` : ''}`;
      } else {
        maxFormattedText = `${maxExp} Month${maxExp == 1 ? "" : 's'}`;
        const minFormatted = formatToYearsAndMonths(minExp);
        minFormattedText = `${minFormatted.years} Year${minFormatted.years > 1 ? 's' : ''} ${minFormatted.months ? `${minFormatted.months} Month${minFormatted.months > 1 ? 's' : ''}` : ''}`;
      }
      return `${minFormattedText} - ${maxFormattedText}`;
    }
  }

  formatYearsOfExperienceJdActionWord(minExp, maxExp) {
    let minExperienceText = "";
    if (minExp && minExp.sign == '+') {
      if(minExp.value < 12) {
        minExperienceText = `${minExp.value}+ Months`;
      } else if(minExp.value % 12 === 0) {
        let minExpYears = minExp.value/12;
        minExperienceText = `${minExpYears}+ Year${minExpYears == 1 ? "" : 's'}`;
      } else {
        let minExpYears = minExp.value / 12;
        let minExpMonths = minExp.value % 12;
        minExperienceText = `${Math.floor(minExpYears)} Year${minExpYears == 1 ? "" : 's'} ${minExp.value % 12} Month${minExpMonths == 1 ? "" : 's'} +`;
      }
      return minExperienceText;
    } else if (!minExp && !maxExp) {
      return '-';
    } else {
      return this.convertExperienceOfJdActionsFromMonthsToYears(minExp.value, maxExp.value);
    }
  }

  setJdYearsOfExperience(requisitionEntitiesList) {
    if(this.requisitionEntity === 'jdActions') {
      requisitionEntitiesList.forEach(jdActionWord => {
        jdActionWord.yearsOfExperience = this.formatYearsOfExperienceJdActionWord(jdActionWord.minExperienceInMonths, jdActionWord.maxExperienceInMonths)
      });
    }
  }

  setAllEntitiesData(data) {
    this.requisitionEntitiesList = [];
    this.requisitionEntitiesList = this.searchEntity !== '' ? this.setEntitiesData(data.filteredEntities) : this.setEntitiesData(data.allEntities);
    this.allRequisitionEntitiesList = this.setEntitiesData(data.allEntities);
    this.setJdYearsOfExperience(this.requisitionEntitiesList);
    this.setJdYearsOfExperience(this.allRequisitionEntitiesList);
    this.isEntityLoading = false;
    if (this.successCallback) {
      this.successCallback.emit(this.allRequisitionEntitiesList.length);
    }
    this.setPage({ offset: 0 });
  }

  setEntityClass() {
    if (this.requisitionEntity === 'degrees' || this.requisitionEntity === 'certificates' || this.requisitionEntity === 'jdActions') {
      return "modal-lg custom-ngx-modal";
    } else if (this.requisitionEntity === 'fieldOfStudy' || this.requisitionEntity === 'roles') {
      return "custom-ngx-modal";
    } else if(this.requisitionEntity === 'actionWords') {
      return "custom-ngx-modal modal-md";
    } else {
      return "modal-lg custom-ngx-modal"
    }
  }

  addEntity(template: TemplateRef<any>) {
    this.addEditEntityData = null;
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = this.setEntityClass();
    const modalData: any = {};
    config.initialState = modalData;
    this.addEntityModal = this.modalService.show(template, config);
  }

  addEditDegree(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.bulkAddUpdateDegrees([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Degree ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditFiledOfStudy(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateFieldOfStudy([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Field of Study ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditCandidateRoles(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateCandidateRoles([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Role ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditCertificates(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateCertifications([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Certificate ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditJdActions(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateJdActions([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`JD Action Word ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditActionWords(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateActionWords(entity, (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Action Word ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  saveAddEntity(event) {
    if (this.requisitionEntity === 'degrees') {
      if (event.alternateName && event.alternateName.length > 0) {
        let alternateNames = event.alternateName.map(alternateName => { return alternateName.value });
        event.alternateName = alternateNames;
      }
      this.addEditDegree(event, 'add');
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.addEditFiledOfStudy(event, 'add');
    } else if (this.requisitionEntity === 'roles') {
      this.addEditCandidateRoles(event, 'add');
    } else if (this.requisitionEntity === 'certificates') {
      this.addEditCertificates(event, 'add');
    } else if (this.requisitionEntity === 'jdActions') {
      if (event.jobDescriptionLanguageDropdown && event.jobDescriptionLanguageDropdown.length > 0) {
        let jobDescriptionLanguageDropdown = event.jobDescriptionLanguageDropdown.map(jobDescriptionLanguageDropdownName => { return jobDescriptionLanguageDropdownName.value });
        event.jobDescriptionLanguageDropdown = jobDescriptionLanguageDropdown;
      }
      if (event.alternates && event.alternates.length > 0) {
        let alternates = event.alternates.map(alternateName => { return alternateName.value });
        event.alternates = alternates;
      }
      this.addEditJdActions(event, 'add');
    }
  }

  saveEditEntity(event) {
    if (this.requisitionEntity === 'degrees') {
      if (event.alternateName && event.alternateName.length > 0) {
        let alternateNames = event.alternateName.map(alternateName => { return alternateName.value });
        event.alternateName = alternateNames;
      }
      this.addEditDegree(event, 'edit');
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.addEditFiledOfStudy(event, 'edit');
    } else if (this.requisitionEntity === 'roles') {
      this.addEditCandidateRoles(event, 'edit');
    } else if (this.requisitionEntity === 'certificates') {
      this.addEditCertificates(event, 'edit');
    } else if (this.requisitionEntity === 'jdActions') {
      if (event.jobDescriptionLanguageDropdown && event.jobDescriptionLanguageDropdown.length > 0) {
        let jobDescriptionLanguageDropdown = event.jobDescriptionLanguageDropdown.map(jobDescriptionLanguageDropdownName => { return jobDescriptionLanguageDropdownName.value });
        event.jobDescriptionLanguageDropdown = jobDescriptionLanguageDropdown;
      }
      if (event.alternates && event.alternates.length > 0) {
        let alternates = event.alternates.map(alternateName => { return alternateName.value });
        event.alternates = alternates;
      }
      this.addEditJdActions(event, 'edit');
    } else if(this.requisitionEntity === 'actionWords') {
      let updatedObj = {
        id: event.id,
        ignore: event.ignore,
        resolved: event.resolved
      }
      this.addEditActionWords({
        unknownMasterList: [updatedObj]
      }, 'edit');
    }
  }

  closeAddEntityModal() {
    this.addEntityModal.hide();
  }

  editEntity(template: TemplateRef<any>, row) {
    this.addEditEntityData = row;
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = this.setEntityClass();
    const modalData: any = {};
    config.initialState = modalData;
    this.editEntityModal = this.modalService.show(template, config);
  }

  closeEditEntityModal() {
    this.editEntityModal.hide();
  }

  deleteAllEntitiesPage() {
    let title = `Delete All ${this.setMultipleEntitiesTitle(this.requisitionEntity)}`;
    let message = `Are you sure you want to delete <b>ALL THE ${this.setMultipleEntitiesTitle(this.requisitionEntity).toUpperCase()} (${this.allRequisitionEntitiesList.length})</b> from the system.`
    this.alertAndNotificationService.showConfirm(title, message, 'warning', true, () => {
      if (this.requisitionEntity === 'degrees') {
        this.deleteDegrees(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'fieldOfStudy') {
        this.deleteFieldOfStudy(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'roles') {
        this.deleteRoles(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'certificates') {
        this.deleteCertificates(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'jdActions') {
        this.deleteJdActions(this.allRequisitionEntitiesList);
      }
    }, () => {
      // Do Nothing
    });
  }

  deleteEntity(entity) {
    let deleteEntity = this.requisitionEntity === 'degrees' ? entity.degreeName : this.requisitionEntity === 'fieldOfStudy' ? entity.fieldOfStudy : this.requisitionEntity === 'roles' ? entity.role : this.requisitionEntity === 'certificates' ? entity.name : this.requisitionEntity === 'jdActions' ? entity.actionword : 'Entity';
    let title = `Delete ${this.setSingleEntityTitle(this.requisitionEntity)}`;
    let message = `Do you want to delete the ${this.setSingleEntityTitle(this.requisitionEntity)} <b>'${deleteEntity}'</b>.`
    this.alertAndNotificationService.showConfirm(title, message, 'warning', true, () => {
      if (this.requisitionEntity === 'degrees') {
        this.deleteDegrees([entity]);
      } else if (this.requisitionEntity === 'fieldOfStudy') {
        this.deleteFieldOfStudy([entity]);
      } else if (this.requisitionEntity === 'roles') {
        this.deleteRoles([entity]);
      } else if (this.requisitionEntity === 'certificates') {
        this.deleteCertificates([entity]);
      } else if (this.requisitionEntity === 'jdActions') {
        this.deleteJdActions([entity]);
      }
    }, () => {
      // Do Nothing
    });
  }

  deleteDegrees(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteDegrees(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Degrees deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteFieldOfStudy(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteFieldOfStudy(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Field of Study deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteRoles(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteCandidateRoles(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Roles deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteCertificates(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteCertifications(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Certificates deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteJdActions(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteJdActions(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("JD Action Words deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  viewRequisitionEntitiesActivities(requisitionEntitiesActivitiesTemplate: TemplateRef<any>) {
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    this.requisitionEntitiesActivitiesTemplateModal = this.modalService.show(requisitionEntitiesActivitiesTemplate, config);
  }

  viewUploadedRequisitionEntities(viewUploadedRequisitionEntitiesTemplate: TemplateRef<any>) {
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = "custom-ngx-modal modal-lg";
    const modalData: any = {};
    config.initialState = modalData;
    this.viewUploadedEntitiesModal = this.modalService.show(viewUploadedRequisitionEntitiesTemplate, config);
  }

  onIgnoreSelect() {
    if (this.requisitionEntity === 'actionWords') {
      this.isFiltersChanged = true;
    }
  }

  resetUnknownActionWords() {
    if (this.requisitionEntity === 'actionWords') {
      this.isFiltersChanged = true;
      this.selectedResolved = [];
      this.selectedIgnore = [];
      this.searchEntity = "";
    }
  }

  selectOrDeselectAllIgnore(type) {
    if (type == 'selectAll') {
      this.selectedIgnore = [...this.ignoreAndResolveList];
      this.onIgnoreSelect();
    } else {
      this.selectedIgnore = [];
      this.onIgnoreSelect();
    }
  }

  selectOrDeselectAllResolved(type) {
    if (type == 'selectAll') {
      this.selectedResolved = [...this.ignoreAndResolveList];
      this.onIgnoreSelect();
    } else {
      this.selectedResolved = [];
      this.onIgnoreSelect();
    }
  }

}
