import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidateOutreachPageComponent } from './candidate-outreach-page.component';

@NgModule({
  declarations: [CandidateOutreachPageComponent],
  imports: [
    CommonModule
  ],
  exports: [CandidateOutreachPageComponent],
  entryComponents: [CandidateOutreachPageComponent]
})
export class CandidateOutreachPageModule { }
