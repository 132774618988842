<div class="approw">
    <div class="row">
        <div class="col-lg-12">
            <ol class="breadcrumb">
                <li><a
                        (click)="redirectToUrl('missioncontrol.dashboard', true)">{{MESSAGECONSTANTS.BREADCRUMBS.HOME}}</a>
                </li>
                <li class="active"><span>{{MESSAGECONSTANTS.BREADCRUMBS.VENDOR_MANAGEMENT}}</span></li>
            </ol>
            <h1 class="heading-css">{{MESSAGECONSTANTS.BREADCRUMBS.VENDOR_MANAGEMENT}}</h1>
        </div>
    </div>
    <div class="col-lg-12 col-sm-12 col-md-12">
        <div class="main-box clearfix">
            <div class="main-box-body clearfix table-responsive client-or-bu-table" *ngIf="!isLoading">
                <table id='vendorTable' datatable [dtOptions]="dtOptions" class="table table-hover">
                    <thead>
                        <tr>
                            <th class="vendor-table-header">Full Name</th>
                            <th class="vendor-table-header">Short Name</th>
                            <th class="vendor-table-header">Contact Name</th>
                            <th class="vendor-table-header">Phone</th>
                            <th class="vendor-table-header">Email</th>
                            <th class="text-center actions vendor-table-header">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vendor of vendors; let i = index">
                            <td>
                                <span>{{vendor.vendorName}}</span>
                            </td>
                            <td>
                                <span>{{vendor.vendorShortName}}</span>
                            </td>
                            <td>
                                {{vendor.contactName}}
                            </td>

                            <td><span
                                    *ngIf="vendor.contactList[0].workphone != null">{{vendor.contactList[0].workphone}}&nbsp;(w)</span><br>
                                <span
                                    *ngIf="vendor.contactList[0].mobilephone != null">{{vendor.contactList[0].mobilephone}}&nbsp;(m)</span>
                            </td>

                            <td>
                                <a href="mailto:{{vendor.contactList[0].email}}?Subject="
                                    target="_top">{{vendor.contactList[0].email}}</a>
                            </td>

                            <td class="text-center" style="padding : 12px 4px;">
                                <a (click)="updateVendor(vendor)" style="margin : 0px;" class="table-link primary">
                                    <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                                <a style="margin : 0px;" class="table-link danger" (click)="deleteVendor(vendor)">
                                    <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="far fa-trash-alt fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>