import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { JobLeftInfo } from 'src/app/core/models/jobLeftInfo';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobService } from 'src/app/core/services/job.service';
import moment from 'moment';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { DateTimeUtilityService } from 'src/app/core/services/date-time-utility.service';
import { NgForm } from '@angular/forms';
import { FourDotFiveDateFormatPipe } from 'src/app/core/pipes/four-dot-five-date-format.pipe';

@Component({
  selector: 'app-job-left',
  templateUrl: './job-left.component.html',
  styleUrls: ['./job-left.component.css'],
  providers: [FourDotFiveDateFormatPipe]
})
export class JobLeftComponent implements OnInit {

  @Input() jobId;
  @Input() candidateId;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();
  @ViewChild('jobLeftForm', {static: true}) jobLeftForm: NgForm;
  @ViewChild('jobLeftDateInput', {static: true}) jobLeftDateInput!: ElementRef;

  jobLeftInfo: any = { jobLeftDate: '' };
  availabilityType = 'jobLeft';

  companyId: any = "";
  companyName: any = "";
  candidates: any = [];
  reasonCodes: any = [];
  candidateJobDetails: any;
  requisitionOpeningNumber: any;
  context: any;
  copyNote: any = '';

  isLoading: boolean = true;
  savingFlag: boolean = false;
  isStaffingCompany: boolean = false;
  isCorporateCompany: boolean = false;
  showHiringOptions: boolean = false;
  showExternalNote: boolean = false;
  loadingCandidateJobDetails: boolean = true;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private candidateService: CandidateService,
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private jobMatchService: JobMatchService,
    private dateTimeUtilityService: DateTimeUtilityService,
    private fourDotFiveDateFormatPipe: FourDotFiveDateFormatPipe
  ) { }

  ngOnInit() {
    //bindings
    this.context = this.candidateId == undefined ? 'requisitionCard' : 'candidateCard';
    //variables
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.companyName = this.authService.getUserDetails().company.companyName;
    this.candidates = [];
    this.reasonCodes = [];
    this.candidateJobDetails;
    this.requisitionOpeningNumber;
    this.jobLeftInfo = new JobLeftInfo(this.jobId, this.companyId);
    //flag
    this.isLoading = true;
    this.savingFlag = false;
    this.isStaffingCompany = this.companyService.isStaffingCompany();
    this.isCorporateCompany = this.companyService.isCorporateCompany();
    this.showHiringOptions = false;
    this.showExternalNote = false;
    if (this.context == 'requisitionCard') {
      this._getJoinedCandidates();
    } else {
      this._getCandidateProfile(() => {
        this._initializeDatePicker();
      });
    }
    this._getReasonCodes();
  }

  _initializeDatePicker() {
    setTimeout(() => {
      ($(document) as any).ready(() => {
        let currentDate = new Date();
  
        if (this.availabilityType == 'jobLeft') {
          ($('#formJobLeftDate') as any).datepicker({
            format: "M d, yyyy",
            endDate: currentDate,
            weekStart: 1,
            autoclose: true
          });
        }
  
        // set current date as default value
        ($("#formJobLeftDate") as any).datepicker("setDate", currentDate);
        ($("#formJobLeftDate") as any).datepicker("update", this.fourDotFiveDateFormatPipe.transform(currentDate));
  
        this.jobLeftInfo.jobLeftDate = $(('#formJobLeftDate') as any).val();
      });
    }, 300);
  }

  _getJoinedCandidates() {
    this.isLoading = true;
    this.jobService.getCandidatesByJobStatus(this.jobId, this.companyId, 'Fill', (data) => {
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].joinCandidate.contact.lastname != null) {
            data[i].joinCandidate.name = data[i].joinCandidate.contact.firstname + " " + data[i].joinCandidate.contact.lastname;
          } else {
            data[i].joinCandidate.name = data[i].joinCandidate.contact.firstname;
          }
          this.candidates.push(data[i].joinCandidate);
        }
      } else {
        this.showAlert('Alert!', 'No candidates are available in Joined state to remove');
      }
      this.isLoading = false;
      this._initializeDatePicker();
    }, error => {
      this.isLoading = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _getCandidateProfile(successCallback) {
    this.isLoading = true;
    this.candidateService.getCandidateProfile(this.candidateId, (data) => {
      data.id = this.candidateId;
      data.name = _.isNull(data.lastName) ? data.firstName : data.firstName + ' ' + data.lastName;
      this.jobLeftInfo.candidate = data;
      this.onCandidateChange();
      this.isLoading = false;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }


  _getReasonCodes() {
    let steps = ['JOB_LEFT'];
    this.jobMatchService.getReasonCodes(steps, (data) => {
      this.reasonCodes = data['Job Left'];
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  showAlert(title, message) {
    this.alertsAndNotificationsService.showAlert(title, message,'warning');
  }

  onCandidateChange() {
    this.showHiringOptions = true;
    this.getCandidateJobLeaveDetails();
  }

  getCandidateJobLeaveDetails() {
    this.loadingCandidateJobDetails = true;
    this.jobService.getCandidateJobLeaveDetails(this.jobId, this.jobLeftInfo.candidate.id, this.companyId, data => {
      this.candidateJobDetails = data;
      this.candidateJobDetails.isFilledInOtherRequisition = this.candidateJobDetails.jobMatchDetails.some(jobMatch => jobMatch.filledInOtherRequisition);
      this.candidateJobDetails.filledInRequisition = this.candidateJobDetails.jobMatchDetails.find(jobMatch => jobMatch.filledInOtherRequisition);
      this.candidateJobDetails.hideOpeningsOptions = this.candidateJobDetails.sharedRequisition && this.isStaffingCompany;
      this._setShowExternalNote();
      this.loadingCandidateJobDetails = false;
    }, error => {
      this.loadingCandidateJobDetails = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setShowExternalNote() {
    this.showExternalNote = this.candidateJobDetails.sharedRequisition
      || (this.candidateJobDetails.vendorCandidate && this.isCorporateCompany);
  }

  doNotHireChange() {
    this.jobLeftInfo.doNotHireAtClient = this.jobLeftInfo.hireAgain === 'DO_NOT_HIRE_AGAIN' ? false : null;
  }

  onInternalNoteChanged() {
    this.jobLeftInfo.externalNote = this.jobLeftInfo.note;
  }

  onExternalNoteChange(event) {
    this.jobLeftInfo.externalNote = event.externalNote;
  }

  onCopyNoteChange(event) {
    this.copyNote = event.copyNote;
  }

  performJobLeft() {
    this._setJobLeftInfo();
    this.savingFlag = true;
    this.jobService.performJobLeft(this.jobLeftInfo, data => {
      this.savingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage("The candidate has been removed from the job successfully", "success");
      this.saveCallback.emit();
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.saveCallback.emit();
    });
  }

  _setJobLeftInfo() {
    this.jobLeftInfo.candidateIds.push(this.jobLeftInfo.candidate.id);
    if (this.jobLeftInfo.hireAgain == "DO_NOT_HIRE_AGAIN") {
      if (this.jobLeftInfo.doNotHireAtClient) {
        this.jobLeftInfo.dontHireAgainCompanyIds.push(this.candidateJobDetails.clientOrBu.id);
      } else {
        this.jobLeftInfo.dontHireAgainCompanyIds.push(this.companyId);
      }
    }
    this.jobLeftInfo.jobLeftDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.jobLeftInfo.jobLeftDate))
  }

}
