import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vendorFilter'
})
export class VendorFilterPipe implements PipeTransform {
  transform(vendors: any[], searchTerm: string): any[] {
    if (!vendors || !searchTerm) {
      return vendors;
    }

    // Convert search term to lowercase for case-insensitive search
    searchTerm = searchTerm.toLowerCase();

    // Filter vendors by specific fields (e.g., name, contactName)
    return vendors.filter(vendor =>
      (vendor.name && vendor.name.toLowerCase().includes(searchTerm)) ||
      (vendor.contactName && vendor.contactName.toLowerCase().includes(searchTerm)) ||
      (vendor.contactList[0] && vendor.contactList[0].workphone && vendor.contactList[0].workphone.toLowerCase().includes(searchTerm)) ||
      (vendor.contactList[0] && vendor.contactList[0].email && vendor.contactList[0].email.toLowerCase().includes(searchTerm))
    );
  }
}
