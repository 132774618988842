import { downgradeComponent } from "@angular/upgrade/static";
import { CandidateOutreachPageComponent } from "./candidate-outreach-page.component";

export const CandidateOutreachPageAjsModule = angular
    .module('candidateOutreachPage', [])
    .directive('candidateOutreachPage', downgradeComponent({ component: CandidateOutreachPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('candidateOutreach', {
                url: '/candidateOutreach',
                component: CandidateOutreachPageComponent,
                data: {
                  requireLogin: true
                },
                params: {
                    outreachId: null
                }
            });
        }
        ])
    .name;

