<div class="container main-box py-4">
    <form #addEntityForm="ngForm" novalidate>
        <div class="row" *ngIf="entityType === 'degrees'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && degreeLevel.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="degreeLevel" class="control-label mb-3">
                            Degree Level<em>*</em> :
                        </label>
                        <input #degreeLevel="ngModel" type="number" name="degreeLevel" class="form-control"
                            [(ngModel)]="addEditEntity.order" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && addEditEntity.invalid)">
                            Degree level is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6 mb-5" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && degreeName.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="degreeName" class="control-label mb-3">
                            Degree Name<em>*</em> :
                        </label>
                        <input #degreeName="ngModel" type="text" name="degreeName" class="form-control"
                            [(ngModel)]="addEditEntity.degreeName" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && degreeName.invalid)">
                            Degree name is mandatory
                        </small>
                    </div>
                    <!-- Alternate Name -->
                    <div class="form-group col-xs-12 col-md-6" [ngClass]="">
                        <label for="alternateNames" class="control-label mb-0">
                            <span>Alternate Names :
                                <span *ngIf="addEditEntity.isAlternateNameDuplicate"
                                    class="pl-1 duplicate-skill duplicate-skill-message"
                                    [innerHTML]="addEditEntity.duplicateNameMessage"></span>
                            </span>
                        </label>
                        <tag-input #alternateInput #alternateNames="ngModel" name="alternateNames"
                            [placeholder]="'Enter Alternate Name'" [secondaryPlaceholder]="'Enter Alternate Name'"
                            [(ngModel)]="addEditEntity.alternateName"
                            (keyup.enter)="addAlternateNameOnEnter($event, alternateInput)"
                            (keydown.Tab)="addAlternateNameOnEnter($event, alternateInput)" [addOnBlur]="true"
                            [ngClass]="{'duplicate-skill': addEditEntity.isAlternateNameDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isNameExists}">
                                    <span class="pr-2">{{ item.display }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="alternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Alternate Name for 'MD'' is 'Directorate of Medicine'</span>
                        </span>
                    </div>
                    <div class="col-xs-12 col-md-6 form-group pb-5" [ngClass]="">
                        <label for="degreeFullName" class="control-label mb-3">
                            Full Name :
                        </label>
                        <input #degreeFullName="ngModel" type="text" name="degreeFullName" class="form-control"
                            [(ngModel)]="addEditEntity.fullName">
                    </div>
                    <div class="col-xs-12 col-md-6" [ngClass]="">
                        <div class="d-flex align-items-center">
                            <div class="checkbox-nice mr-4">
                                <input type="checkbox" [(ngModel)]="addEditEntity.enableFieldOfStudy"
                                    id="enableFieldOfStudy" name="enableFieldOfStudy" />
                                <label for="enableFieldOfStudy">
                                    Enable Field of Study
                                </label>
                            </div>
                            <div class="checkbox-nice">
                                <input type="checkbox" [(ngModel)]="addEditEntity.showInParenthesis"
                                    id="showInParenthesis" name="showInParenthesis" />
                                <label for="showInParenthesis">
                                    Show in Parenthesis
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'fieldOfStudy'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && fieldOfStudy.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="fieldOfStudy" class="control-label mb-3">
                            Field of Study<em>*</em> :
                        </label>
                        <input #fieldOfStudy="ngModel" type="text" name="fieldOfStudy" class="form-control"
                            [(ngModel)]="addEditEntity.fieldOfStudy" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && fieldOfStudy.invalid)">
                            Field of Study is mandatory
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'roles'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && roles.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="roles" class="control-label mb-3">
                            Role<em>*</em> :
                        </label>
                        <input #roles="ngModel" type="text" name="roles" class="form-control"
                            [(ngModel)]="addEditEntity.role" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && roles.invalid)">
                            Role is mandatory
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'certificates'">
            <div class="col-xs-12 mb-4">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && certificateName.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="certificateName" class="control-label mb-3">
                            Name<em>*</em> :
                        </label>
                        <input #certificateName="ngModel" type="text" name="certificateName" class="form-control"
                            [(ngModel)]="addEditEntity.name" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && certificateName.invalid)">
                            Name is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && certificateGroup.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="certificateGroup" class="control-label mb-3">
                            Group<em>*</em> :
                        </label>
                        <input #certificateGroup="ngModel" type="text" name="certificateGroup" class="form-control"
                            [(ngModel)]="addEditEntity.group" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && certificateGroup.invalid)">
                            Group is mandatory
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'jdActions'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && jdActionWordLevel.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="jdActionWordLevel" class="control-label mb-3">
                            JD Action Word Level<em>*</em> :
                        </label>
                        <input #jdActionWordLevel="ngModel" type="number" name="jdActionWordLevel" class="form-control"
                            [(ngModel)]="addEditEntity.order" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && addEditEntity.invalid)">
                            JD Action Word level is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6 mb-5" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && skillsAndProficiency.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="skillsAndProficiency" class="control-label mb-3">
                            Skills and Proficiency<em>*</em> :
                        </label>
                        <input #skillsAndProficiency="ngModel" type="text" name="skillsAndProficiency" class="form-control"
                            [(ngModel)]="addEditEntity.actionword" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && skillsAndProficiency.invalid)">
                            Skills and Proficiency is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6 mb-5" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && minYearsOfExperience.invalid) || jDActionMinExperienceError, 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="minYearsOfExperience" class="control-label mb-3">
                            Minimum Experience in Months<em>*</em> :
                        </label>
                        <div class="d-flex">
                            <input #minYearsOfExperience="ngModel" type="number" name="minYearsOfExperience" class="form-control mr-2"
                                [(ngModel)]="addEditEntity.minExperienceInMonths.value" (change)="checkJdActionMinAndMaxValidation()" [min]="0" required>
                            <select #minYearsOfExperienceSelect="ngModel" name="minYearsOfExperienceSelect" (change)="setMinYearsIfExperienceSign()" 
                                [(ngModel)]="addEditEntity.minExperienceInMonths.sign" class="form-control" style="width: fit-content;">
                                <option value=""></option>
                                <option value="+">+</option>
                            </select>
                        </div>
                        <small class="error" *ngIf="(addEntityForm.submitted && minYearsOfExperience.invalid)">
                            Minimum Experience is mandatory
                        </small>
                        <small class="error" *ngIf="addEntityForm.submitted && minYearsOfExperience.valid && jDActionMinExperienceError">
                            Minimum Experience should be less than Maximum Experience
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6 mb-5" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && maxYearsOfExperience.invalid) || jDActionMinExperienceError, 
                            'pointer-events-none opacity-half': isDisabled || addEditEntity.minExperienceInMonths.sign == '+'}">
                        <label for="maxYearsOfExperience" class="control-label mb-3">
                            Maximum Experience in Months<em>*</em> :
                        </label>
                        <div class="d-flex">
                            <input #maxYearsOfExperience="ngModel" type="number" name="maxYearsOfExperience" class="form-control mr-2"
                                [(ngModel)]="addEditEntity.maxExperienceInMonths.value" (change)="checkJdActionMinAndMaxValidation()" [min]="0" required>
                        </div>
                        <small class="error" *ngIf="(addEntityForm.submitted && maxYearsOfExperience.invalid)">
                            Maximum Experience is mandatory
                        </small>
                        <small class="error" 
                            *ngIf="addEntityForm.submitted && ((maxYearsOfExperience.valid && jDActionMinExperienceError) || (minYearsOfExperience.invalid && maxYearsOfExperience.valid))"
                            style="color: transparent;">
                            Maximum Experience should be greater than Minimum Experience
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6 mb-4" [ngClass]="{'has-error mb-4': (addEntityForm.submitted && description.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="description" class="control-label mb-3">
                            Description<em>*</em> :
                        </label>
                        <input #description="ngModel" type="text" name="description" class="form-control"
                            [(ngModel)]="addEditEntity.description" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && description.invalid)">
                            Description is mandatory
                        </small>
                    </div>
                    <!-- <div class="col-xs-12 col-md-6 mb-5" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && jobDescriptionLanguageDropdown.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="jobDescriptionLanguageDropdown" class="control-label mb-3">
                            Job Description Language dropdown<em>*</em> :
                        </label>
                        <input #jobDescriptionLanguageDropdown="ngModel" type="text" name="jobDescriptionLanguageDropdown" class="form-control"
                            [(ngModel)]="addEditEntity.jobDescriptionLanguageDropdown" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && jobDescriptionLanguageDropdown.invalid)">
                            Job Description Language dropdown is mandatory
                        </small>
                    </div> -->
                    <!-- Job Description Language dropdown -->
                    <div class="form-group col-xs-12 col-md-6" [ngClass]="">
                        <label for="jobDescriptionLanguageDropdown" class="control-label mb-0">
                            Job Description Language dropdown
                        </label>
                        <tag-input #jobDescriptionLanguageDropdownInput #jobDescriptionLanguageDropdown="ngModel" name="jobDescriptionLanguageDropdown"
                            [placeholder]="'Enter Job Description Language dropdown'" [secondaryPlaceholder]="'Enter Job Description Language dropdown'"
                            [(ngModel)]="addEditEntity.jobDescriptionLanguageDropdown"
                            (keyup.enter)="addAlternateNameOnEnter($event, jobDescriptionLanguageDropdownInput)"
                            (keydown.Tab)="addAlternateNameOnEnter($event, jobDescriptionLanguageDropdownInput)" [addOnBlur]="true">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isNameExists}">
                                    <span class="pr-2">{{ item.display }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="jobDescriptionLanguageDropdownInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Job Description Language dropdown for 'Master' is 'Master of'</span>
                        </span>
                    </div>
                    <!-- Alternates -->
                    <div class="form-group col-xs-12 col-md-6" [ngClass]="">
                        <label for="alternatesOne" class="control-label mb-0">
                            <span>Alternates :
                                <span *ngIf="addEditEntity.isAlternateNameOneDuplicate"
                                    class="pl-1 duplicate-skill duplicate-skill-message"
                                    [innerHTML]="addEditEntity.duplicateNameMessage"></span>
                            </span>
                        </label>
                        <tag-input #alternatesOneInput #alternatesOne="ngModel" name="alternatesOne"
                            [placeholder]="'Enter Alternates'" [secondaryPlaceholder]="'Enter Alternates'"
                            [(ngModel)]="addEditEntity.alternates"
                            (keyup.enter)="addAlternateNameOnEnter($event, alternatesOneInput)"
                            (keydown.Tab)="addAlternateNameOnEnter($event, alternatesOneInput)" [addOnBlur]="true"
                            [ngClass]="{'duplicate-skill': addEditEntity.isAlternateNameOneDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isNameExists}">
                                    <span class="pr-2">{{ item.display }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="alternatesOneInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Alternate for 'Expert' is 'Expert in'</span>
                        </span>
                    </div>
                    <!-- Alternate Two -->
                    <!-- <div class="form-group col-xs-12 col-md-6" [ngClass]="">
                        <label for="alternatesTwo" class="control-label mb-0">
                            <span>Alternates 1 :
                                <span *ngIf="addEditEntity.isAlternateNameTwoDuplicate"
                                    class="pl-1 duplicate-skill duplicate-skill-message"
                                    [innerHTML]="addEditEntity.duplicateNameMessage"></span>
                            </span>
                        </label>
                        <tag-input #alternatesTwoInput #alternatesTwo="ngModel" name="alternatesTwo"
                            [placeholder]="'Enter Alternate 2'" [secondaryPlaceholder]="'Enter Alternate 2'"
                            [(ngModel)]="addEditEntity.alternatesTwo"
                            (keyup.enter)="addAlternateNameOnEnter($event, alternatesTwoInput)"
                            (keydown.Tab)="addAlternateNameOnEnter($event, alternatesTwoInput)" [addOnBlur]="true"
                            [ngClass]="{'duplicate-skill': addEditEntity.isAlternateNameTwoDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isNameExists}">
                                    <span class="pr-2">{{ item.display }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="alternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Alternate 2 for 'Expert' is 'Expertise in'</span>
                        </span>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'actionWords'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    
                    <div class="col-xs-12 col-md-6 mb-5 d-flex align-items-baseline" [ngClass]="{'has-error mb-0': addEntityForm.submitted, 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="actionWordsIgnore" class="control-label mr-3">
                            Ignore :
                        </label>
                        <div class="radio">
                            <input id="ignoreTrue" type="radio" name="ignore"
                                [(ngModel)]="addEditEntity.ignore" [value]="true">
                            <label for="ignoreTrue">True</label>
                            <input id="ignoreFalse" type="radio" name="ignore"
                                [(ngModel)]="addEditEntity.ignore" [value]="false">
                            <label for="ignoreFalse">False</label>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-6 mb-5 d-flex align-items-baseline" [ngClass]="{'has-error mb-0': addEntityForm.submitted, 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="actionWordsResolved" class="control-label mr-3">
                            Resolved :
                        </label>
                        <div class="radio">
                            <input id="resolveTrue" type="radio" name="resolved"
                                [(ngModel)]="addEditEntity.resolved" [value]="true">
                            <label for="resolveTrue">True</label>
                            <input id="resolveFalse" type="radio" name="resolved"
                                [(ngModel)]="addEditEntity.resolved" [value]="false">
                            <label for="resolveFalse">False</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-xs-12 text-right">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-2" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <button *ngIf="action === 'add'" type="submit" class="btn btn-success" (click)="addEntityDetails()">
                    Add
                </button>
                <button *ngIf="action === 'edit'" type="submit" class="btn btn-success" (click)="editEntityDetails()">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>