<div class="col-xs-12" *ngIf="!isCandidateDetailsUpdatedLoading">
    <div style="justify-content: space-between;" class="col-md-offset-4 col-xs-offset-2 col-md-8 col-xs-10 d-flex">
        <div class="d-flex">
            <span class="job-match-legend"></span>
            <span class="job-match-legend-info">Access to Requisition and all actions performable</span>
        </div>
        <div class="d-flex">
            <span class="job-match-legend job-match-disabled-legend"></span>
            <span class="job-match-legend-info">No access to Requisition and no actions performable</span>
        </div>
        <div class="d-flex">
            <span class="job-match-legend job-match-restricted-access-legend"></span>
            <span class="job-match-legend-info">No access to Requisition and only specific actions performable</span>
        </div>
    </div>
    <div *ngIf="!loadingFlag" class="col-xs-12 cursor-default table-responsive client-or-bu-table" style="max-height: 600px; overflow-y: auto;">
        <table class="table" id="jobMatchesTable" datatable [dtOptions]="dtOptions">
            <thead>
                <tr>
                    <th class="text-left">
                        Rank
                    </th>
                    <th class="text-left">
                        Job Match Date
                    </th>
                    <th class="text-left">
                        Job Title
                    </th>
                    <th class="text-left" style="width:14%">
                        Job Match Details
                    </th>
                    <th class="text-left">
                        Company
                    </th>
                    <th class="text-left">
                        Status
                    </th>
                    <th class="text-left">
                        Req# (Status)
                    </th>
                    <th class="text-left">
                        Recruiter (<sup class="fa fa-star emerald" style="font-size: 0.7em"></sup> = Assigned)
                    </th>
                    <th class="text-center">
                        Job Match Score
                    </th>
                    <th class="text-center">
                        T.A Score
                    </th>
                    <th class="text-center">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobMatch of jobMatches; let i = index" style="height: 60px;"
                    [ngClass]="{'job-match-disabled': jobMatch.jobMatchDisabled, 'job-match-restricted-access': jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser}">
                    <td class="text-left" [ngClass]="{'current-job-match-shared-requisition-color': jobMatch.candidateJobMatchCardDetails.isSharedRequisition}">
                        <span tooltip="Job Match Rank" placement="top-left">
                            <span *ngIf="!jobMatch.isLoading">{{jobMatch.jobMatchRank}}</span>
                            <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                        </span>                            
                    </td>
                     <td class="text-left" [attr.data-order]="jobMatch.jobMatchDate" [ngClass]="{'current-job-match-shared-requisition-color': jobMatch.candidateJobMatchCardDetails.isSharedRequisition}">
                        <span *ngIf="!jobMatch.isLoading">{{jobMatch.jobMatchDate | fourDotFiveDateFormatPipe}}</span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                     <td class="text-left">
                        <span tooltip="{{jobMatch.jobTitle}}" (click)="goToJobProfile(jobMatch.jobId, jobMatch.jobMatchDisabled, jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser)" *ngIf="!jobMatch.isLoading">
                            <a class="cursor-pointer" *ngIf="!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">{{jobMatch.jobTitle | truncatePipe:25}}
                                <span *ngIf="jobMatch.jobTitle.length > 25">..</span>
                            </a>
                           <span *ngIf="jobMatch.jobMatchDisabled || jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">{{jobMatch.jobTitle | truncatePipe:25}}
                                <span *ngIf="jobMatch.jobTitle.length > 25">..</span>
                            </span>
                        </span>
                        <app-info-icon *ngIf="jobMatch.requisitionNote" style="font-size:10px;"
                            [info]="jobMatch.requisitionNote" 
                            infoTitle="Requisition Info">
                        </app-info-icon>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                    <td class="text-left">
                        <span *ngIf="!jobMatch.isLoading">
                            <div>
                                <app-candidate-card-icons context="candidateCurrentJobMatches" [candidate]="jobMatch"
                                    [candidateAdditionalDetails]="jobMatch.candidateJobMatchCardDetails.candidateAdditionalDetails"
                                    [candidateSalaryDurationOptions]="salaryDurationOptions" [jobTypeList]="jobTypeList">
                                </app-candidate-card-icons>
                            </div>
                            <div *ngIf="jobMatch.contractDuration">
                                {{jobMatch.contractDuration | contractDurationPipe}}
                            </div>
                        </span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                     
                    <td  class="text-left" [ngClass]="{'current-job-match-shared-requisition-color': jobMatch.candidateJobMatchCardDetails.isSharedRequisition}">
                        <span *ngIf="!jobMatch.isLoading">
                            <span tooltip="{{jobMatch.companyName}}">
                                {{jobMatch.companyName | truncatePipe:25}}
                                <span *ngIf="jobMatch.companyName.length > 25">..</span>
                            </span>
                            <app-info-icon *ngIf="jobMatch.clientNote" style="font-size:10px;"
                                [info]="jobMatch.clientNote" 
                                infoTitle="Info">
                            </app-info-icon>
                            <div tooltip="{{jobMatch.jobDetailsText}}">
                                {{jobMatch.jobDetailsText | truncatePipe:25}}
                                <span *ngIf="jobMatch.jobDetailsText.length > 25">..</span>
                            </div>
                        </span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                    <td class="text-left" [ngClass]="{'current-job-match-shared-requisition-color': jobMatch.candidateJobMatchCardDetails.isSharedRequisition}">
                        <span *ngIf="!jobMatch.isLoading">
                            <div tooltip="{{jobMatch.currentStep}}">
                                <b>{{jobMatch.currentStep | truncatePipe:20}}</b>
                                <span *ngIf="jobMatch.currentStep.length > 20">..</span>
                            </div>
                            <div tooltip="{{jobMatch.currentState}}">
                                {{jobMatch.currentState | truncatePipe:18}}
                                <span *ngIf="jobMatch.currentState.length > 18">..</span>
                            </div>
                        </span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>

                    </td>
                    <td class="text-left pointer">
                        <span *ngIf="!jobMatch.isLoading">
                            <span tooltip="{{jobMatch.requisitionNumber}}" (click)="goToWorkflow(jobMatch, false)">
                                <a *ngIf="!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">{{jobMatch.requisitionNumber | truncatePipe:12}}
                                    <span *ngIf="jobMatch.requisitionNumber.length > 12">..</span>
                                </a>
                                <span *ngIf="jobMatch.jobMatchDisabled || jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">{{jobMatch.requisitionNumber | truncatePipe:12}}
                                     <span *ngIf="jobMatch.requisitionNumber.length > 12">..</span>
                                </span>
                            </span>
                            <span tooltip="{{jobMatch.requisitionStatus}}" [ngClass]="{'current-job-match-shared-requisition-color': jobMatch.candidateJobMatchCardDetails.isSharedRequisition}">
                                ({{jobMatch.requisitionStatus | truncatePipe:8}})
                                <span *ngIf="jobMatch.requisitionStatus.length > 8">..</span>
                            </span>
                        </span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                    <td class="text-left">
                        <div *ngIf="!jobMatch.isLoading">
                            <div *ngFor="let recruiter of jobMatch.recruitersList; let $index=index">
                                <span tooltip="{{recruiter.name}}" [innerHTML]="recruiter.displayName" 
                                    [ngClass]="{'candidate-current-job-vendor-recruiter':recruiter.name == jobMatch.poc,
                                    'emerald': jobMatch.candidateJobMatchCardDetails.isSharedRequisition && ((!isStaffingCompany && !recruiter.vendorRecruiter) || (isStaffingCompany && recruiter.vendorRecruiter))}"></span>
                                <sup tooltip="Assigned Recruiter of Job Match" *ngIf="recruiter.primaryRecruiter" class="fa fa-star emerald" style="font-size: 0.7em"></sup>
                                <sup tooltip="Point of contact" style="font-size: 0.7em" *ngIf="recruiter.primaryRecruiter && (recruiter.name == jobMatch.poc)"
                                    class="candidate-current-job-vendor-recruiter">POC</sup>
                                <span *ngIf="recruiter.primaryHiringManager" tooltip="Primary Hiring Manager" class="emarald current-job-matches-primary-hiring-manager-icon">P</span>
                            </div>
                        </div> 
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                    <td class="text-center">
                        <span class="job-match-score" [ngClass]="{'grey-bg': jobMatch.fourDotFiveIntelligentScore == 'N/A', 'dark-orange-bg': jobMatch.fourDotFiveIntelligentScore<jobMatch.fourDotFiveIntelligentThresholdScore, 'green-bg': jobMatch.fourDotFiveIntelligentScore>=jobMatch.fourDotFiveIntelligentThresholdScore}" *ngIf="!jobMatch.isLoading">
                            <span tooltip="Threshold score is {{jobMatch.fourDotFiveIntelligentThresholdScore}}" (click)="goToCandidateJobComparison(jobMatch.jobId, jobMatch.jobMatchId, jobMatch.jobMatchDisabled, jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser)">
                                <a class="cursor-pointer" style="color:white">
                                    <b>{{jobMatch.fourDotFiveIntelligentScore}}</b>
                                </a>
                            </span>
                        </span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                    <td class="text-center">
                        <span class="job-match-score" [ngClass]="{'grey-bg': jobMatch.techAssessmentScore == 'N/A', 'dark-orange-bg': jobMatch.techAssessmentScore<jobMatch.techAssessmentThresholdScore, 'green-bg': jobMatch.techAssessmentScore>=jobMatch.techAssessmentThresholdScore}" *ngIf="!jobMatch.isLoading">
                            <span *ngIf="jobMatch.techAssessmentThresholdScore == null">
                                N/A
                            </span>
                            <span *ngIf="jobMatch.techAssessmentThresholdScore != null">
                                <a *ngIf="!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser && jobMatch.techAssessmentScore !== 'N/A'" (click)="getReport(jobMatch)" tooltip="Threshold score is {{jobMatch.techAssessmentThresholdScore}}" style="color:white">
                                    <b>{{jobMatch.techAssessmentScore}}</b>
                                </a>
                                <span *ngIf="jobMatch.jobMatchDisabled || jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser || jobMatch.techAssessmentScore == 'N/A'" tooltip="Threshold score is {{jobMatch.techAssessmentThresholdScore}}">
                                    <b>{{jobMatch.techAssessmentScore}}</b>
                                </span> 
                            </span>
                        </span>
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                    <td class="text-center">
                        <div class="btn-group dropdown d-flex justify-content-center" id="{{jobMatch.jobMatchId}}-dropdown" uib-dropdown on-toggle="cardMenuToggled(open, jobMatch.jobMatchId + '-dropdown-menu')" *ngIf="!jobMatch.isLoading">
                            <button type="button" class="btn btn-primary" ng-disabled="jobMatch.jobMatchDisabled" data-toggle="dropdown" uib-dropdown-toggle>Actions</button>
                            <button type="button" class="btn btn-primary" ng-disabled="jobMatch.jobMatchDisabled" data-toggle="dropdown" uib-dropdown-toggle aria-haspopup="true" aria-expanded="false">
                                <span class="caret"></span> <span class="sr-only"></span>
                            </button>
                            <ul id="{{jobMatch.jobMatchId}}-dropdown-menu" class="dropdown-menu dropdown-menu-right pb-2" style="margin-bottom:100px" uib-dropdown-menu="" role="menu">
                                <li *ngIf="!jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">
                                    <a style="height: 30px;padding-left: 10px;" (click)="goToWorkflow(jobMatch, true)">
                                        <img src="assets/app-content/img/workflow3.svg" class="workflow-icon" alt="Go to filtered workflow">
                                        <span class="workflow-filter-icon fas fa-filter"></span><span>Go to filtered workflow</span>
                                    </a>
                                </li>
                                <li *ngIf="!jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">
                                    <a style="height: 30px;padding-left: 10px;" (click)="goToWorkflow(jobMatch, false)">
                                        <img src="assets/app-content/img/workflow3.svg" class="workflow-icon" alt="Go to workflow">
                                        <span style="padding-left:8px">Go to workflow</span>
                                    </a>
                                </li>
                                <li class="candidate-job-matches-actions-divider" *ngIf="!jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser || jobMatch.candidateJobMatchCardDetails.stateActions.length == 0"></li>
                                <li *ngIf="isOutreachEnabled && !isVendorOnly && isOureachActionAvailable && jobMatch.firstStep">
                                    <a (click)="sendOutreachEmail(jobMatch)" style="height: 30px;padding-left: 10px;">Send outreach email</a>
                                </li>
                                <li class="candidate-job-matches-actions-divider" *ngIf="isOutreachEnabled && !isVendorOnly && isOureachActionAvailable && jobMatch.firstStep"></li>
                                <ng-container *ngIf="jobMatch.candidateJobMatchCardDetails.stateActions.length > 0">
                                    <li *ngFor="let actionObject of jobMatch.candidateJobMatchCardDetails.stateActions; let i = index">
                                        <a (click)="performAction(actionObject, jobMatch.candidateJobMatchCardDetails)"
                                            style="height: 30px; padding-left: 25px;">
                                            <i [class]="actionObject.class"></i>
                                            <span class="pl-3">{{ actionObject.action }}</span>
                                        </a>
                                    </li>
                                </ng-container>
                                <li class="candidate-job-matches-actions-divider" *ngIf="!jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser || jobMatch.candidateJobMatchCardDetails.stepActions.length == 0"></li>
                                <ng-container *ngIf="jobMatch.candidateJobMatchCardDetails.stepActions.length > 0">
                                    <li [ngClass]="{'border-orange': actionObject.action == 'Delete Job Match'}" *ngFor="let actionObject of jobMatch.candidateJobMatchCardDetails.stepActions; let i = index;">
                                        <a (click)="performAction(actionObject, jobMatch.candidateJobMatchCardDetails)" style="height: 30px;padding-left: 10px;">{{actionObject.action}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div> 
                        <span *ngIf="jobMatch.isLoading" class="fa fa-spinner fa-spin"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="isCandidateDetailsUpdatedLoading" style="height: 200px; overflow-y: auto;"
    class="row d-flex justify-content-center align-items-center">
    <div class="col-xs-12 no_candidates_found text-center">
        <span>Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>

<ng-template #candidateJobMatchActionsTempalte>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>{{candidateJobMatchActionsLabel}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeCandidateJobMatchActionsModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row"> 
            <div class="col-xs-12" *ngIf="candidateJobMatchActionsActionType === 'jobProfile'">
                <app-job-profile [jobId]="candidateJobMatchActionsJobId"></app-job-profile>
            </div>
            <div class="col-xs-12" *ngIf="candidateJobMatchActionsActionType === 'candidateJobComparison'">
                <candidate-job-match-profile 
                    [jobId]="candidateJobMatchActionsJobId"
                    [candidateId]="candidateId"
                    [jobMatchId]="candidateJobMatchActionsJobMatchId"
                    context="candidateJobComparison">
                </candidate-job-match-profile>
            </div>
        </div>
    </div>
</ng-template>