import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidatesPageComponent } from './candidates-page.component';
import { CandidateJobMatchesComponent } from './candidate-job-matches/candidate-job-matches.component';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { InfoIconModule } from 'src/app/shared/info-icon/info-icon.module';
import { CandidateCardIconsModule } from 'src/app/shared/candidate-card-icons/candidate-card-icons.module';
import { DataTablesModule } from 'angular-datatables';
import { JobProfileModule } from '../job-profile-page/job-profile/job-profile.module';
import { CandidateJobMatchProfileComponentWrapper } from './candidate-job-matches/candidate-job-match-profile.component.wrapper';
import { CandidatePossibleJobMatchesComponent } from './candidate-possible-job-matches/candidate-possible-job-matches.component';
import { CandidateFileUploadComponent } from './candidate-file-upload/candidate-file-upload.component';
import { UploadFilesModule } from 'src/app/shared/upload-files/upload-files.module';
import { SendCandidateOutreachEmailModule } from 'src/app/shared/send-candidate-outreach-email/send-candidate-outreach-email.module';

@NgModule({
  declarations: [CandidatesPageComponent, CandidateJobMatchesComponent, CandidateJobMatchProfileComponentWrapper, CandidatePossibleJobMatchesComponent, CandidateFileUploadComponent],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    PipesModule,
    InfoIconModule,
    CandidateCardIconsModule,
    PipesModule,
    DataTablesModule,
    JobProfileModule,
    UploadFilesModule,
    SendCandidateOutreachEmailModule
  ],
  entryComponents: [CandidatesPageComponent, CandidateJobMatchesComponent, CandidatePossibleJobMatchesComponent, CandidateFileUploadComponent],
  exports: [CandidatesPageComponent, CandidateJobMatchesComponent, CandidateJobMatchProfileComponentWrapper, CandidatePossibleJobMatchesComponent, CandidateFileUploadComponent]
})
export class CandidatesPageModule { }
