<div class="container">
    <div class="row">
        <div class="col-xs-12">
            <div class="schedules-on-profile">
                <tabset>
                    <!-- Job Description -->
                    <tab heading="Job Detail">
                        <div>
                            <div *ngIf="!loadingFlag" class="animate-if main-box">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="infographic-box">
                                            <div class="row job-profile-header">
                                                <div class="col-xs-12 text-center job-profile-name">
                                                    <strong>{{job.title}}</strong>
                                                    <app-info-icon *ngIf="job.requisitionNote"
                                                        [info]="job.requisitionNote" infoTitle="Requisition Info" class="ml-2"></app-info-icon>
                                                    <span class="job-profile-download-requisition-icon ml-2" style="bottom: 3px;"
                                                        [ngClass]="{'position-relative': !job.isJobFilePdf}">
                                                        <a *ngIf="!job.isJobFilePdf" 
                                                            [href]="requisitionUrl" 
                                                            download 
                                                            class="dark-green"
                                                            placement="bottom"  
                                                            tooltip="Click to download Requisition">
                                                            <span class="fa-stack" style="font-size: 12px;">
                                                                <span class="fa fa-file fa-stack-2x"></span>
                                                                <span class="fa fa-download fa-stack-1x fa-inverse"></span>
                                                            </span>
                                                        </a>
                                                        <a [href]="jobDownloadPdfUrl" 
                                                            download 
                                                            placement="bottom"
                                                            tooltip="Click to download PDF Requisition"
                                                            class="ml-2">
                                                            <span style="font-size: 23.5px;"
                                                                [ngClass]="{'fa-stack-job-download-icon': !job.isJobFilePdf, 'pl-1': job.isJobFilePdf}">
                                                                <span class="fa fa-file-pdf red"></span>
                                                            </span>
                                                        </a>
                                                    </span>
                                                    <div class="pull-right" *ngIf="showEdit">
                                                        <button class="btn btn-primary mr-1" *ngIf="!isEdit"
                                                            (click)="isEdit = true"><span
                                                                class="fas fa-pencil-alt pr-1"></span>Edit</button>
                                                        <button class="btn btn-primary mr-1"
                                                            *ngIf="!isEdit && isSkillsEditSaved"
                                                            (click)="recalculateScore()">Recalculate Scores</button>
                                                        <button class="btn btn-warning mr-1" *ngIf="isEdit"
                                                            (click)="undoEdit()">Undo</button>
                                                        <button class="btn btn-primary" *ngIf="isEdit"
                                                            (click)="save()">Save</button>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 text-center">
                                                    <strong>{{job.companyName}}</strong>
                                                    <app-info-icon *ngIf="job.clientNote" [info]="job.clientNote"
                                                        infoTitle="Info"></app-info-icon>
                                                </div>
                                                <div class="col-xs-12 text-center">
                                                    <strong>{{job.jobType}}</strong>
                                                    <span *ngIf="job.contractDuration">
                                                        ({{job.contractDuration | contractDurationPipe}})
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Education</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div *ngIf="job.educations.length > 0">
                                                                <div *ngFor="let education of job.educations; let i = index">
                                                                    <div *ngIf="!education.isDeleted">
                                                                        <strong><span
                                                                            class="asset-value">{{education.degreeName}}</span>
                                                                        <span *ngIf="isEdit"
                                                                            class="red fas fa-times cursor-pointer pl-1"
                                                                            (click)="education.isDeleted = true"></span></strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="job.educations.length == 0">
                                                                <strong><span class="asset-value">No
                                                                        Requirements</span></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Experience</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div *ngIf="job.experiences.length > 0">
                                                                <div *ngFor="let experience of job.experiences; let i = index">
                                                                    <div *ngIf="!experience.isDeleted">
                                                                        <strong>
                                                                            <span class="asset-value">{{experience.title}}</span>
                                                                            <span *ngIf="isEdit"
                                                                                class="red fas fa-times cursor-pointer pl-1"
                                                                                (click)="experience.isDeleted = true"></span>
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="job.experiences.length == 0">
                                                                <strong><span class="asset-value">No
                                                                        Requirements</span></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Technical Skills</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div class="row">
                                                                <div *ngFor="let technicalSkill of job.technicalSkills; let i = index">
                                                                    <div class="col-xs-6" *ngIf="!technicalSkill.isDeleted">
                                                                        <strong>{{technicalSkill.competency}} <sup
                                                                            *ngIf="technicalSkill.required"
                                                                            class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                            <span class="asset-value pl-1"
                                                                                *ngIf="technicalSkill.experience != null">:
                                                                                {{technicalSkill.experience |
                                                                                convertDurationInYears}}</span>
                                                                            <span *ngIf="isEdit"
                                                                                class="red fas fa-times cursor-pointer pl-1"
                                                                                (click)="technicalSkill.isDeleted = true"></span>
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12"
                                                                    *ngIf="job.technicalSkills.length == 0">
                                                                    <strong><span class="asset-value">No
                                                                            Requirements</span></strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Operational Skills</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div class="row">
                                                                <div *ngFor="let operationalSkill of job.operationalSkills; let i = index">
                                                                    <div class="col-xs-6" *ngIf="!operationalSkill.isDeleted">
                                                                        <strong>{{operationalSkill.competency}}<sup
                                                                            *ngIf="operationalSkill.required"
                                                                            class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                        <span class="asset-value pl-1"
                                                                            *ngIf="operationalSkill.experience != null">: {{operationalSkill.experience
                                                                            | convertDurationInYears}}</span>
                                                                        <span *ngIf="isEdit"
                                                                            class="red fas fa-times cursor-pointer pl-1"
                                                                            (click)="operationalSkill.isDeleted = true"></span>
                                                                    </strong>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12"
                                                                    *ngIf="job.operationalSkills.length == 0">
                                                                    <strong><span class="asset-value">No Requirements</span></strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Soft Skills</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div class="row">
                                                                <div *ngFor="let softSkill of job.softSkills; let i = index">
                                                                    <div class="col-xs-6" *ngIf="!softSkill.isDeleted">
                                                                        <strong>{{softSkill.competency}}<sup
                                                                            *ngIf="softSkill.required"
                                                                            class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                        <span class="asset-value pl-1"
                                                                            *ngIf="softSkill.experience != null">: {{softSkill.experience
                                                                            | convertDurationInYears}}</span>
                                                                        <span *ngIf="isEdit"
                                                                            class="red fas fa-times cursor-pointer pl-1"
                                                                            (click)="softSkill.isDeleted = true"></span>
                                                                    </strong>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12"
                                                                    *ngIf="job.softSkills.length == 0">
                                                                    <strong><span class="asset-value">No
                                                                            Requirements</span></strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Other Skills</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div class="row">
                                                                <div *ngFor="let extraSkill of job.extraSkills; let i = index">
                                                                    <div class="col-xs-6" *ngIf="!extraSkill.isDeleted">
                                                                        <strong>{{extraSkill.competency}} <sup
                                                                            *ngIf="extraSkill.required"
                                                                            class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                        <span class="asset-value pl-1"
                                                                            *ngIf="extraSkill.experience != null">: {{extraSkill.experience
                                                                            | convertDurationInYears}}</span>
                                                                        <span *ngIf="isEdit"
                                                                            class="red fas fa-times cursor-pointer pl-1"
                                                                            (click)="extraSkill.isDeleted = true"></span>
                                                                    </strong>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12"
                                                                    *ngIf="job.extraSkills == null || job.extraSkills.length == 0">
                                                                    <strong><span class="asset-value">No
                                                                            Requirements</span></strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
    
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Certifications</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div class="row">
                                                                <div *ngFor="let certification of job.certifications; let i = index">
                                                                    <div class="col-xs-6" *ngIf="!certification.isDeleted && certification.name">
                                                                        <strong>
                                                                            <span class="asset-value">{{certification.name}}
                                                                                <span
                                                                                    *ngIf="certification.instituteName && certification.instituteName != ''">
                                                                                    from {{certification.instituteName}}</span>
                                                                                <span *ngIf="isEdit"
                                                                                    class="red fas fa-times cursor-pointer pl-1"
                                                                                    (click)="certification.isDeleted = true">
                                                                                </span>
                                                                            </span>
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12"
                                                                    *ngIf="job.certifications.length == 0">
                                                                    <strong><span class="asset-value">No
                                                                            Requirements</span></strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6">
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <h3><strong>Compensation</strong></h3>
                                                        </div>
                                                    </div>
                                                    <div class="row job-profile-assets">
                                                        <div class="col-xs-1 text-right">
                                                            <i class="fa fa-list green" title=""></i>
                                                        </div>
                                                        <div class="col-xs-11 text-left">
                                                            <div *ngFor="let compensation of job.compensations; let i = index">
                                                                <div *ngIf="!compensation.isDeleted">
                                                                    <strong>
                                                                        <span class="asset-value">{{compensation.salary}}</span>
                                                                        <span *ngIf="isEdit"
                                                                            class="red fas fa-times cursor-pointer pl-2"
                                                                            (click)="compensation.isDeleted = true"></span>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="job.compensations.length == 0">
                                                                <strong><span class="asset-value">No
                                                                        Requirements</span></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="loadingFlag" class="animate-if candidate-card-loader-div text-center"
                                style="height: 600px;">
                                <span class="fa fa-2x fa-spinner fa-spin"></span>
                            </div>
                        </div>
                    </tab>
                    <!-- Job PDF -->
                    <tab heading="Job Detail PDF" *ngIf="context == 'candidateJobMatchCard' || context == 'requisitionCard' || context == 'candidateJobComparison'">
                        <div class="row">
                            <div class="col-xs-12" *ngIf="!loadingFlag">
                                <object [data]="jobReportPdfViewUrl" type="application/pdf" style="width:100%; height: 100vh;">
                                    <embed [src]="jobReportPdfUrl" type="application/pdf" width="100%" height="500px"/>
                                </object>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>