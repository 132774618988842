<div class="candidate-job-comparison-container">
    <div class="main-box" *ngIf="!loadingFlag">
        <div class="row">
            <div class="col-xs-12">
                <div class="main-box">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="infographic-box">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <div class="col-xs-12 text-center">
                                            <h2>
                                                <strong>{{comparisonData.candidateName}} -
                                                    {{comparisonData.jobTitle}}</strong>
                                                <span *ngIf="isSuperUserOr4dot5Admin"
                                                    class="badge badge-primary cursor-pointer ml-2"
                                                    popoverClass="info-pop-over" [popover]="scoreTemplate"
                                                    placement="bottom" [outsideClick]="true">S
                                                </span>
                                            </h2>
                                        </div>
                                        <div class="col-xs-12 text-center">
                                            <div>
                                                Applying for the position of
                                                <strong>{{comparisonData.jobTitle}}</strong> in
                                                <strong>{{comparisonData.companyName}}</strong>
                                            </div>
                                            <div [innerHtml]="jobLocationForDisplay">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row candidate-job-compare">
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Education</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="educationMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of educationMatches; let i = index">
                                                    <strong>
                                                        <span
                                                            *ngIf="asset.jobDegreeType && asset.jobDegreeType != 'null'">{{asset.jobDegreeType}}:
                                                        </span><span>{{asset.jobDegreeName}} </span>
                                                        <span *ngIf="asset.candidateDegreeName !== null">
                                                            ( <span *ngIf="asset.candidateDegreeName !== null"
                                                                class="emerald">{{asset.candidateDegreeName}}</span>
                                                            <span class="emerald"> at
                                                                {{asset.candidateInstituteName}}</span> )
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="educationNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of educationNonMatches; let i = index">
                                                    <strong>
                                                        <span
                                                            *ngIf="asset.jobDegreeType && asset.jobDegreeType != 'null'">{{asset.jobDegreeType}}:
                                                        </span><span>{{asset.jobDegreeName}}</span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="educationAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of educationAlmost; let i = index">
                                                    <strong>
                                                        <span
                                                            *ngIf="asset.jobDegreeType && asset.jobDegreeType != 'null'">{{asset.jobDegreeType}}:
                                                        </span><span>{{asset.jobDegreeName}} </span>
                                                        <span *ngIf="asset.candidateDegreeName !== null">
                                                            (<span *ngIf="asset.candidateDegreeName !== null"
                                                                class="emerald"> {{asset.candidateDegreeName}}</span>
                                                            <span class="emerald"> at
                                                                {{asset.candidateInstituteName}}</span>
                                                            )
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="educationAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of educationAdditional; let i = index">
                                                    <strong>
                                                        <span *ngIf="asset.candidateDegreeName !== null">
                                                            <span *ngIf="asset.candidateDegreeName !== null"
                                                                class="emerald">{{asset.candidateDegreeName}}</span>
                                                            <span class="emerald"> at
                                                                {{asset.candidateInstituteName}}</span>
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!educationMatches.length && !educationNonMatches.length && !educationAlmost.length && !educationAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <strong>No Requirements (<span class="emerald">Not Specified</span>)</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Experience</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="experienceMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of experienceMatches; let $index = index">
                                                    <strong>
                                                        <span *ngIf="$index == 0" class="d-block pb-1">Experience: <span
                                                                tooltip="Job Experience Required" container="body"
                                                                placement="top">
                                                                <span
                                                                    *ngIf="asset.jobMaxYear && asset.jobMaxYear !== '0' && asset.jobMinYear && asset.jobMinYear !== '0'">{{asset.jobMinYear}}
                                                                    - {{asset.jobMaxYear}} years</span>
                                                                <span
                                                                    *ngIf="(asset.jobMinYear && asset.jobMinYear !== '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">{{asset.jobMinYear}}
                                                                    years</span>
                                                                <span
                                                                    *ngIf="(!asset.jobMinYear || asset.jobMinYear === '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">No
                                                                    Requirements</span>
                                                            </span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && comparisonData.totalExperience">(<span
                                                                    class="emerald">{{comparisonData.totalExperience}}
                                                                    years</span>)</span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && (!comparisonData.totalExperience || comparisonData.totalExperience == 0)">(<span
                                                                    class="emerald">Not Specified
                                                                    years</span>)</span>
                                                        </span>
                                                        <span class="">
                                                            <span>{{asset.dateRange}}</span>
                                                            <span class="emerald" tooltip="Candidate's Experience"
                                                                container="body" placement="top"> {{asset.candidateExp |
                                                                convertDurationInYears}}
                                                                <span *ngIf="asset.jobTitle && asset.jobTitle != ''"> as
                                                                    {{asset.jobTitle}}</span>
                                                                <span> at {{asset.company}} </span>
                                                            </span>
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="experienceNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of experienceNonMatches; let $index = index">
                                                    <strong>
                                                        <span *ngIf="$index == 0" class="d-block pb-1">Experience: <span
                                                                tooltip="Job Experience Required" container="body"
                                                                placement="top">
                                                                <span
                                                                    *ngIf="asset.jobMaxYear && asset.jobMaxYear !== '0' && asset.jobMinYear && asset.jobMinYear !== '0'">{{asset.jobMinYear}}
                                                                    - {{asset.jobMaxYear}} years</span>
                                                                <span
                                                                    *ngIf="(asset.jobMinYear && asset.jobMinYear !== '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">{{asset.jobMinYear}}
                                                                    years</span>
                                                                <span
                                                                    *ngIf="(!asset.jobMinYear || asset.jobMinYear === '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">No
                                                                    Requirements</span>
                                                            </span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && comparisonData.totalExperience">(<span
                                                                    class="emerald">{{comparisonData.totalExperience}}
                                                                    years</span>)</span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && (!comparisonData.totalExperience || comparisonData.totalExperience == 0)">(<span
                                                                    class="emerald">Not Specified
                                                                    years</span>)</span>
                                                        </span>
                                                        <span class="">
                                                            <span>{{asset.dateRange}}</span>
                                                            <span class="emerald" tooltip="Candidate's Experience"
                                                                container="body" placement="top"> {{asset.candidateExp |
                                                                convertDurationInYears}}
                                                                <span *ngIf="asset.jobTitle && asset.jobTitle != ''"> as
                                                                    {{asset.jobTitle}}</span>
                                                                <span> at {{asset.company}} </span>
                                                            </span>
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="experienceAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of experienceAlmost; let $index = index">
                                                    <strong>
                                                        <span *ngIf="$index == 0" class="d-block pb-1">Experience: <span
                                                                tooltip="Job Experience Required" container="body"
                                                                placement="top">
                                                                <span
                                                                    *ngIf="asset.jobMaxYear && asset.jobMaxYear !== '0' && asset.jobMinYear && asset.jobMinYear !== '0'">{{asset.jobMinYear}}
                                                                    - {{asset.jobMaxYear}} years</span>
                                                                <span
                                                                    *ngIf="(asset.jobMinYear && asset.jobMinYear !== '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">{{asset.jobMinYear}}
                                                                    years</span>
                                                                <span
                                                                    *ngIf="(!asset.jobMinYear || asset.jobMinYear === '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">No
                                                                    Requirements</span>
                                                            </span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && comparisonData.totalExperience">(<span
                                                                    class="emerald">{{comparisonData.totalExperience}}
                                                                    years</span>)</span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && (!comparisonData.totalExperience || comparisonData.totalExperience == 0)">(<span
                                                                    class="emerald">Not Specified
                                                                    years</span>)</span>
                                                        </span>
                                                        <span class="">
                                                            <span>{{asset.dateRange}}</span>
                                                            <span class="emerald" tooltip="Candidate's Experience"
                                                                container="body" placement="top"> {{asset.candidateExp |
                                                                convertDurationInYears}}
                                                                <span *ngIf="asset.jobTitle && asset.jobTitle != ''"> as
                                                                    {{asset.jobTitle}}</span>
                                                                <span> at {{asset.company}} </span>
                                                            </span>
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="experienceAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has additionally" placement="top"
                                                container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of experienceAdditional; let $index = index">
                                                    <strong>
                                                        <span *ngIf="$index == 0" class="d-block pb-1">Experience: <span
                                                                tooltip="Job Experience Required" container="body"
                                                                placement="top">
                                                                <span
                                                                    *ngIf="asset.jobMaxYear && asset.jobMaxYear !== '0' && asset.jobMinYear && asset.jobMinYear !== '0'">{{asset.jobMinYear}}
                                                                    - {{asset.jobMaxYear}} years</span>
                                                                <span
                                                                    *ngIf="(asset.jobMinYear && asset.jobMinYear !== '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">{{asset.jobMinYear}}
                                                                    years</span>
                                                                <span
                                                                    *ngIf="(!asset.jobMinYear || asset.jobMinYear === '0') && (!asset.jobMaxYear || asset.jobMaxYear === '0')">No
                                                                    Requirements</span>
                                                            </span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && comparisonData.totalExperience">(<span
                                                                    class="emerald">{{comparisonData.totalExperience}}
                                                                    years</span>)</span>
                                                            <span class="pl-1"
                                                                *ngIf="$index == 0 && (!comparisonData.totalExperience || comparisonData.totalExperience == 0)">(<span
                                                                    class="emerald">Not Specified
                                                                    years</span>)</span>
                                                        </span>
                                                        <span class="">
                                                            <span>{{asset.dateRange}}</span>
                                                            <span class="emerald" tooltip="Candidate's Experience"
                                                                container="body" placement="top"> {{asset.candidateExp |
                                                                convertDurationInYears}}
                                                                <span *ngIf="asset.jobTitle && asset.jobTitle != ''"> as
                                                                    {{asset.jobTitle}}</span>
                                                                <span> at {{asset.company}} </span>
                                                            </span>
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!experienceMatches.length && !experienceNonMatches.length && !experienceAlmost.length && !experienceAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <strong *ngIf="!totalJobExperience || totalJobExperience == 0">No Requirements (<span class="emerald">Not Specified</span>)</strong>
                                                <strong *ngIf="totalJobExperience && totalJobExperience > 0"> 
                                                    <span class="d-block">Experience:
                                                        <span tooltip="Job Experience Required" container="body"
                                                        placement="top">{{totalJobExperience}} years</span> (<span class="emerald">Not Specified</span>)
                                                     </span>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row candidate-job-compare">
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Technical Skills</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="techSkillsMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div class="col-xs-6"
                                                        *ngFor="let asset of techSkillsMatches; let $index = index">
                                                        <div
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                <span>
                                                                    ( <span class="emerald"
                                                                        *ngIf="asset.candidateExperience !== 0"
                                                                        tooltip="Candidate's Experience in Technical Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span>
                                                                    <span class="emerald fa fa-check"
                                                                        *ngIf="asset.candidateExperience == 0"
                                                                        tooltip="Candidate has Technical Skill"
                                                                        placement="top" container="body"></span> )
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="techSkillsMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="techSkillsNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of techSkillsNonMatches; let $index = index" class="col-xs-6">
                                                        <div
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                ( <span class="emerald"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate's Experience in Technical Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span><span
                                                                    class="emerald fa fa-times"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate does not have Technical Skill"
                                                                    placement="top" container="body"></span> )
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="techSkillsNonMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="techSkillsAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of techSkillsAlmost; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    ( <span class="emerald"
                                                                        tooltip="Candidate's Experience in Technical Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span>)
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="techSkillsAlmost.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="techSkillsAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of techSkillsAdditional; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span class="emerald">{{asset.competency}} </span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    ( <span class="emerald"
                                                                        tooltip="Candidate's Experience in Technical Skill"
                                                                        placement="top" container="body">
                                                                        {{asset.candidateExperience |
                                                                        convertDurationInYears}}</span>)
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="techSkillsAdditional.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!techSkillsMatches.length && !techSkillsNonMatches.length && !techSkillsAlmost.length && !techSkillsAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <span>No information available.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Operational Skills</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="operationalSkillsMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let asset of operationalSkillsMatches; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                ( <span class="emerald"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate's Experience in Operational Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span>
                                                                <span class="emerald fa fa-check"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate has Operational Skill"
                                                                    placement="top" container="body"></span> )
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="operationalSkillsMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="operationalSkillsNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let asset of operationalSkillsNonMatches; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                ( <span class="emerald"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate's Experience in Operational Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span><span
                                                                    class="emerald fa fa-times"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate does not have Operational Skill"
                                                                    placement="top" container="body"></span> )
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6"
                                                        *ngIf="operationalSkillsNonMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="operationalSkillsAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let asset of operationalSkillsAlmost; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    ( <span class="emerald"
                                                                        tooltip="Candidate's Experience in operationalSkillsMatches Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span> )
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="operationalSkillsAlmost.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="operationalSkillsAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let asset of operationalSkillsAdditional; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandTechAndOperationalSkills)">
                                                            <strong>
                                                                <span class="emerald">{{asset.competency}} </span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    ( <span class="emerald"
                                                                        tooltip="Candidate's Experience in Operational Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span> )
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6"
                                                        *ngIf="operationalSkillsAdditional.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandTechAndOperationalSkills = !expandTechAndOperationalSkills">
                                                            <span *ngIf="expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandTechAndOperationalSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!operationalSkillsMatches.length && !operationalSkillsNonMatches.length && !operationalSkillsAlmost.length && !operationalSkillsAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <span>No information available.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row candidate-job-compare">
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Soft Skills</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="softSkillsMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of softSkillsMatches; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                ( <span class="emerald"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate's Experience in Soft Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span>
                                                                <span class="emerald fa fa-check"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate has Soft Skill" placement="top"
                                                                    container="body"></span> )
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="softSkillsMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="softSkillsNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of softSkillsNonMatches; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                (<span class="emerald"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate's Experience in Soft Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span><span
                                                                    class="emerald fa fa-times"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate does not have Soft Skill"
                                                                    placement="top" container="body"></span>)
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="softSkillsNonMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="softSkillsAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of softSkillsAlmost; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    (<span class="emerald"
                                                                        tooltip="Candidate's Experience in Soft Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span>)
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="softSkillsAlmost.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="softSkillsAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of softSkillsAdditional; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span class="emerald">{{asset.competency}} </span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    ( <span class="emerald"
                                                                        tooltip="Candidate's Experience in Soft Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span> )
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="softSkillsAdditional.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!softSkillsMatches.length && !softSkillsNonMatches.length && !softSkillsAlmost.length && !softSkillsAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <span>No information available.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Other Skills</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="otherSkillsMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of otherSkillsMatches; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                ( <span class="emerald"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate's Experience in Other Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span>
                                                                <span class="emerald fa fa-check"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate has Soft Skill" placement="top"
                                                                    container="body"></span> )
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="otherSkillsMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="otherSkillsNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let asset of otherSkillsNonMatches; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                ( <span class="emerald"
                                                                    *ngIf="asset.candidateExperience !== 0"
                                                                    tooltip="Candidate's Experience in Other Skill"
                                                                    placement="top"
                                                                    container="body">{{asset.candidateExperience |
                                                                    convertDurationInYears}}</span><span
                                                                    class="emerald fa fa-times"
                                                                    *ngIf="asset.candidateExperience == 0"
                                                                    tooltip="Candidate does not have Other Skill"
                                                                    placement="top" container="body"></span> )
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="otherSkillsNonMatches.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="otherSkillsAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div *ngFor="let asset of otherSkillsAlmost; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span [innerHtml]="asset.competency"></span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span
                                                                    *ngIf="asset.experienceForJob !== null && asset.experienceForJob !== 0">:
                                                                    {{asset.experienceForJob | convertDurationInYears}}
                                                                </span>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    ( <span class="emerald"
                                                                        tooltip="Candidate's Experience in Other Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span> )
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="otherSkillsAlmost.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="otherSkillsAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let asset of otherSkillsAdditional; let $index = index" class="col-xs-6">
                                                        <div 
                                                            *ngIf="$index<=24 || ($index>24 && expandSoftAndOtherSkills)">
                                                            <strong>
                                                                <span class="emerald">{{asset.competency}} </span>
                                                                <sup *ngIf="asset.required"
                                                                    class="text-danger job-profile-skill-required-indicator">*</sup>
                                                                <span *ngIf="asset.candidateExperience !== 0">
                                                                    (<span class="emerald"
                                                                        tooltip="Candidate's Experience in Other Skill"
                                                                        placement="top"
                                                                        container="body">{{asset.candidateExperience |
                                                                        convertDurationInYears}}</span>)
                                                                </span>
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" *ngIf="otherSkillsAdditional.length >= 24">
                                                        <strong class="emerald cursor-pointer"
                                                            (click)="expandSoftAndOtherSkills = !expandSoftAndOtherSkills">
                                                            <span *ngIf="expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show Less...</span>
                                                            <span *ngIf="!expandSoftAndOtherSkills"
                                                                class="badge badge-primary">Show More...</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!otherSkillsMatches.length && !otherSkillsNonMatches.length && !otherSkillsAlmost.length && !otherSkillsAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <span>No information available.</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row candidate-job-compare">
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Certifications</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="certificationMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of certificationMatches; let $index = index">
                                                    <strong>
                                                        <span>{{asset.certificationName}} </span>
                                                        (<span class="emerald"
                                                            *ngIf="asset.candidateInstituteName !== null"
                                                            tooltip="Certification Institute" placement="top"
                                                            container="body">{{asset.candidateInstituteName}}</span><span
                                                            class="emerald fa fa-check"
                                                            *ngIf="asset.candidateInstituteName == null"
                                                            tooltip="Candidate has Certification" placement="top"
                                                            container="body"></span>)
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="certificationNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of certificationNonMatches; let $index = index">
                                                    <strong>
                                                        <span>{{asset.certificationName}} </span>
                                                        (<span class="emerald fa fa-times"
                                                            tooltip="Candidate does not have Certification"
                                                            placement="top" container="body"></span>)
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="certificationAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of certificationAlmost; let $index = index">
                                                    <strong>
                                                        <span>{{asset.certificationName}} </span>
                                                        <span *ngIf="asset.candidateInstituteName !== null">
                                                            (<span class="emerald" tooltip="Certification Institute"
                                                                placement="top"
                                                                container="body">{{asset.candidateInstituteName}}</span>)
                                                        </span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="certificationAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of certificationAdditional; let $index = index">
                                                    <strong>
                                                        <span class="emerald">{{asset.certificationName}}</span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!certificationMatches.length && !certificationNonMatches.length && !certificationAlmost.length && !certificationAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <span>No information available.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <h3><strong class="d-inline-block">Compensation</strong></h3>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="compensationMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Matching Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-up green"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of compensationMatches; let $index = index">
                                                    <strong>
                                                        <span>Salary {{asset.jobSalary}} </span>
                                                        (<span class="emerald"
                                                            *ngIf="asset.candidateSalary !== null">{{asset.candidateSalary}}</span><span
                                                            class="emerald fa fa-check"
                                                            *ngIf="asset.candidateSalary == null"></span>)
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="compensationNonMatches.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Missing Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-thumbs-down red"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of compensationNonMatches; let $index = index">
                                                    <strong>
                                                        <span>Salary {{asset.jobSalary}} </span>
                                                        (<span class="emerald"
                                                            *ngIf="asset.candidateSalary !== null">{{asset.candidateSalary}}</span><span
                                                            class="emerald fa fa-times"
                                                            *ngIf="asset.candidateSalary == null"></span>)
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="compensationAlmost.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right"
                                                tooltip="Assets which the Candidate has partially" placement="top"
                                                container="body">
                                                <i class="fa fa-hourglass-half yellow"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of compensationAlmost; let i = index">
                                                    <strong>
                                                        <span>Salary {{asset.jobSalary}} </span>
                                                        (<span class="emerald"
                                                            *ngIf="asset.candidateSalary !== null">{{asset.candidateSalary}}</span>)
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets" *ngIf="compensationAdditional.length > 0">
                                            <div class="col-xs-1 col-md-1 text-right" tooltip="Additional Assets"
                                                placement="top" container="body">
                                                <i class="fa fa-plus-circle emerald"></i>
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <div *ngFor="let asset of compensationAdditional; let i = index">
                                                    <strong>
                                                        <span class="emerald">{{asset.candidateSalary}}</span>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row job-assets"
                                            *ngIf="!compensationMatches.length && !compensationNonMatches.length && !compensationAlmost.length && !compensationAdditional.length">
                                            <div class="col-xs-1 col-md-1 text-right">
                                            </div>
                                            <div class="col-xs-11 col-md-11 text-left candidate-job-compare-asset-text">
                                                <span>No information available.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="loadingFlag">
        <div class="col-xs-12 loading-spinner candidate-job-comparison-loading-spinner text-center">
            <span class="pr-2">Loading</span>
            <span class="fa fa-spinner fa-spin"></span>
        </div>
    </div>
</div>

<!-- score popover -->
<ng-template #scoreTemplate>
    <span class="d-inline-block mb-4" style="border-bottom:0;font-weight: 400;padding-top: 0.1em; max-height: 40px;">
        <b style="display:inline-block; width:95px;">Total Score: </b>
        <span tooltip="Threshold Score" placement="top" container="body"
            class="candidate-job-match-card-intelligence-score-success bg-primary">
            {{comparisonData.jobMatchThresholdScore}}
        </span>
        <span class="d-inline-block pl-2"> | </span>
        <span tooltip="Job Match Score" placement="top" container="body" class="p-1 ml-2"
            [ngClass]="{'candidate-job-match-card-only-intelligence-score-success job-match-card-green-bg': comparisonData.jobMatchScore > comparisonData.jobMatchThresholdScore, 'candidate-job-match-card-only-intelligence-score-failure': !comparisonData.jobMatchScore < comparisonData.jobMatchThresholdScore}">
            {{comparisonData.jobMatchScore}}
        </span>
    </span>
    <div *ngIf="comparisonData.categoryScoreDTO.educationScoreDTO.totalScore !== null && comparisonData.categoryScoreDTO.educationScoreDTO.totalScore !==0"
        class="mb-4 border p-2">
        <b style="display:inline-block; width:87px;">Education: </b>
        <span class="d-inline-block mb-4"
            style="border-bottom:0;font-weight: 400;padding-top: 0.1em; max-height: 40px;">
            <span tooltip="Allocated split %" placement="top" container="body"
                class="candidate-job-match-card-tech-score-success bg-primary">
                {{comparisonData.categoryScoreDTO.educationScoreDTO.totalScore}}%
            </span>
            <span class="d-inline-block pl-2"> | </span>
            <span tooltip="Calculated % " placement="top" container="body"
                class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                {{comparisonData.categoryScoreDTO.educationScoreDTO.candidateScore}}%
            </span>
        </span>

        <div class="pl-4 mb-4"><b style="display:inline-block; width:73px;">Matching: </b>
            <span class="candidate-job-comparison-scores-container">
                <span tooltip="Allocated split %" placement="top" container="body"
                    class="candidate-job-match-card-tech-score-success bg-primary">
                    {{comparisonData.categoryScoreDTO.educationScoreDTO.totalScoreForMatching}}%
                </span>
                <span class="d-inline-block pl-2"> | </span>
                <span tooltip="Calculated % " placement="top" container="body"
                    class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                    {{comparisonData.categoryScoreDTO.educationScoreDTO.candidateScoreForMatching}}%
                </span>
            </span>
        </div>

        <div class="pl-4 mb-2"><b style="display:inline-block; width:73px;">Additional: </b>
            <span class="candidate-job-comparison-scores-container">
                <span tooltip="Allocated split %" placement="top" container="body"
                    class="candidate-job-match-card-tech-score-success bg-primary">
                    {{comparisonData.categoryScoreDTO.educationScoreDTO.totalScoreForAdditionalEducation}}%
                </span>
                <span class="d-inline-block pl-2"> | </span>
                <span tooltip="Calculated % " placement="top" container="body"
                    class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                    {{comparisonData.categoryScoreDTO.educationScoreDTO.candidateScoreForAdditionalEducation}}%
                </span>
            </span>
        </div>
    </div>
    <div *ngIf="comparisonData.categoryScoreDTO.experienceScoreDTO.totalScore !== null && comparisonData.categoryScoreDTO.experienceScoreDTO.totalScore !==0"
        class="mb-4 p-2 border">
        <b style="display:inline-block; width:89px;">Experience: </b>
        <span class="candidate-job-comparison-scores-container">
            <span tooltip="Allocated split %" placement="top" container="body"
                class="candidate-job-match-card-tech-score-success bg-primary">
                {{comparisonData.categoryScoreDTO.experienceScoreDTO.totalScore}}%
            </span>
            <span class="d-inline-block pl-2"> | </span>
            <span tooltip="Calculated % " placement="top" container="body"
                class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                {{comparisonData.categoryScoreDTO.experienceScoreDTO.candidateScore}}%
            </span>
        </span>
    </div>
    <div *ngIf="comparisonData.categoryScoreDTO.skillScoreDTO.totalScore !== null && comparisonData.categoryScoreDTO.skillScoreDTO.totalScore !== 0"
        class="mb-4 border p-2">
        <b style="display:inline-block; width:89px;">Skills: </b>
        <span class="d-inline-block mb-4" class="candidate-job-comparison-scores-container">
            <span tooltip="Allocated split %" placement="top" container="body"
                class="candidate-job-match-card-tech-score-success bg-primary">
                {{comparisonData.categoryScoreDTO.skillScoreDTO.totalScore}}%
            </span>
            <span class="d-inline-block pl-2"> | </span>
            <span tooltip="Calculated % " placement="top" container="body"
                class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                {{comparisonData.categoryScoreDTO.skillScoreDTO.candidateScore}}%
            </span>
        </span>

        <!-- Required skills -->
        <div class="border p-2 mb-2 mt-3">
            <div class="mb-4"><b style="display:inline-block; width:55px;">Required:</b>
                <span *ngIf="isSuperUserOr4dot5Admin" class="badge badge-primary cursor-pointer ml-2"
                    popoverClass="info-pop-over" [popover]="requiredSkillInfoTemplate" placement="bottom"
                    [outsideClick]="true" container="body">i
                </span>
                <span class="candidate-job-comparison-scores-container">
                    <span tooltip="Allocated split %" placement="top" container="body"
                        class="candidate-job-match-card-tech-score-success bg-primary ml-2">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.totalRequiredSkillScore}}%
                    </span>
                    <span class="d-inline-block pl-2"> | </span>
                    <span tooltip="Calculated % " placement="top" container="body"
                        class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.candidateRequiredSkillScore}}%
                    </span>
                </span>
            </div>

            <div class="mb-4 pl-2"><b style="display:inline-block; width:78px;">Matching: </b>
                <span class="candidate-job-comparison-scores-container">
                    <span tooltip="Total" placement="top" container="body"
                        class="candidate-job-match-card-tech-score-success bg-primary">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.noOfTotalRequiredSkills}}
                    </span>
                    <span class="d-inline-block pl-2"> | </span>
                    <span tooltip="Matched" placement="top" container="body"
                        class="candidate-job-match-card-tech-score-success bg-primary ml-2">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.noOfMatchedRequiredSkills}}
                    </span>
                </span>
            </div>
        </div>

        <!-- Optional skills -->
        <div class="border p-2">
            <div class="mb-4"><b style="display:inline-block; width:55px;">Optional: </b>
                <span *ngIf="isSuperUserOr4dot5Admin" class="badge badge-primary cursor-pointer ml-2"
                    popoverClass="info-pop-over" [popover]="optionalSkillsInfoTemplate" placement="bottom"
                    [outsideClick]="true" container="body">i
                </span>
                <span class="candidate-job-comparison-scores-container">
                    <span tooltip="Allocated split %" placement="top" container="body"
                        class="candidate-job-match-card-tech-score-success bg-primary ml-2">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.totalNonRequiredSkillScore}}%
                    </span>
                    <span class="d-inline-block pl-2"> | </span>
                    <span tooltip="Calculated % " placement="top" container="body"
                        class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.candidateNonRequiredSkillScore}}%
                    </span>
                </span>
            </div>

            <div class="mb-4 pl-2"><b style="display:inline-block; width:78px;">Matching: </b>
                <span class="candidate-job-comparison-scores-container">
                    <span tooltip="Total" placement="top" container="body"
                        class="candidate-job-match-card-tech-score-success bg-primary">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.noOfTotalNonRequiredSkills}}
                    </span>
                    <span class="d-inline-block pl-2"> | </span>
                    <span tooltip="Matched" placement="top" container="body"
                        class="candidate-job-match-card-tech-score-success bg-primary ml-2">
                        {{comparisonData.categoryScoreDTO.skillScoreDTO.noOfMatchedNonRequiredSkills}}
                    </span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #requiredSkillInfoTemplate>
    <div style="overflow-y: auto; width:200px; height: 250px;">
        <div class="mb-3">
            <b>Required Skills Info: </b>
        </div>
        <div class="mb-4" style="font-size:10px;"
            *ngFor="let skillInfo of comparisonData.categoryScoreDTO.skillScoreDTO.individualRequiredSkillScoreInfoList; let i = index">
            <span class="candidate-job-match-card-intelligence-score-success bg-primary mr-1">
                {{skillInfo.name}}
            </span>
            <span> | </span>
            <span class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                {{skillInfo.value}}
            </span>
        </div>
        <div class="mb-4"
            *ngIf="!comparisonData.categoryScoreDTO.skillScoreDTO.individualRequiredSkillScoreInfoList || comparisonData.categoryScoreDTO.skillScoreDTO.individualRequiredSkillScoreInfoList.length === 0">
            <span style="font-size:10px;">
                No Required Skills
            </span>
        </div>
    </div>
</ng-template>

<ng-template #optionalSkillsInfoTemplate>
    <div style="overflow-y: auto; width:200px; height: 250px;">
        <div class="mb-3">
            <b>Optional Skills Info: </b>
        </div>
        <div class="mb-4" style="font-size:10px;"
            *ngFor="let skillScoreInfo of comparisonData.categoryScoreDTO.skillScoreDTO.individualNonRequiredSkillScoreInfoList; let i = index">
            <span class="candidate-job-match-card-intelligence-score-success bg-primary mr-1">
                {{skillScoreInfo.name}}
            </span>
            <span> | </span>
            <span class="candidate-job-match-card-intelligence-score-success job-match-card-green-bg ml-2">
                {{skillScoreInfo.value}}
            </span>
        </div>
        <div class="mb-4"
            *ngIf="!comparisonData.categoryScoreDTO.skillScoreDTO.individualNonRequiredSkillScoreInfoList || comparisonData.categoryScoreDTO.skillScoreDTO.individualNonRequiredSkillScoreInfoList.length === 0">
            <span style="font-size:10px;">
                No Optional Skills
            </span>
        </div>
    </div>
</ng-template>