<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.actionLabel}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xs-12" *ngIf="modalData.actionType === 'associateVendors'">
            <app-associate-vendors [associatedVendorsIds]="associatedVendorIds" (saveCallback)="refresh()">
            </app-associate-vendors>
        </div>
        <div class="col-xs-12" *ngIf="modalData.actionType === 'deleteVendor'">
            <app-delete-entity entity="corpVendorRelationship" [corpVendorInfo]="modalData.corpVendorInfo"
                (cancelCallback)="cancelCallback()" (deleteCallback)="refresh()">
            </app-delete-entity>
        </div>
    </div>
</div>