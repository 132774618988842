import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';

declare var Dropzone: any;
declare var $: any;
declare var libphonenumber: any;

@Component({
  selector: 'app-candidate-file-upload',
  templateUrl: './candidate-file-upload.component.html',
  styleUrls: ['./candidate-file-upload.component.css']
})
export class CandidateFileUploadComponent implements OnInit {

  @Input() jobId;
  @Input() clientOrBuId;
  @Output() cancelCallback = new EventEmitter<any>();
  @Output() completeCallback = new EventEmitter<any>();
  @ViewChild('viewCandidateFileUploadFormat', null) viewCandidateFileUploadFormatRef: TemplateRef<any>;

  viewCandidateFileUploadModal: BsModalRef;

  isFileUploaded: boolean = false;
  disableUploadButtonFlag: boolean = true;
  fileExistsFlag: boolean = false;
  disablePreviousButtonFlag: boolean = false;
  disableNextButtonFlag: boolean = true;
  fileUploadInProgressFlag: boolean = false;
  jobContextExistsFlag: boolean = false;
  sendOutreachEmail: boolean = false;
  isOutreachEnabled: boolean = false;

  resumeList: any = [];
  uploadCandidateFileResponse: any;
  candidateList: any = [];
  candidateCount: any;
  fileFormatModal: any;
  uploadFileWizard: any;
  fileUploadDropzone: any;
  candidateCounts: any;
  candidateUploadResponseList: any = [];
  userId: any;
  companyId: any;
  candidateFileUpload: string = "candidateFileUpload"; 

  constructor(
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private candidateService: CandidateService,
    private authService: AuthService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    //initialize the variables
    if (!_.isNull(this.jobId) && this.jobId !== undefined && this.jobId != '') {
      this.jobContextExistsFlag = true;
    }
    // wait for changes to take effect and then create wizard
    setTimeout(() => {
      this.uploadFileWizard = this.createFileUploadWizard();
      this.fileUploadDropzone = this.createFileUploadDropzone();
    }, 300);
    this._checkIfOutreachEnabled();
  }

  /**
       * create the wizard for the upload resume workflow
       */
  createFileUploadWizard() {
    let uploadFileWizard = $("#uploadFileWizard").wizard();

    $('#uploadFileWizard').on('actionclicked.fu.wizard', (evt, data) => {
      if (data.direction == "next") {
        switch (data.step) {
          case 1:
            // remove the remove button from the file.
            $('.dz-remove').remove();
            break;
          case 2:
            // do nothing
            // complete click
            // handled in finish event
            break;
          default:
            break;
        }
      } else if (data.direction == "previous") {
        switch (data.step) {
          case 1:
            // not possible
            break;
          case 2:
            this.updateActionButtons();
            break;
          default:
            break;
        }
      }
    });

    $('#uploadFileWizard').on('finished.fu.wizard', (evt, data) => {
      // Send outreach to candidates
      if (this.sendOutreachEmail) {
        let passedCandidateList = [];
        if (this.candidateUploadResponseList.length > 0) {
          this.candidateUploadResponseList.map(candidate => {
            if (candidate.status === 'PASSED') {
              passedCandidateList.push({ id: candidate.candidateId, candidateType: 'OWN_CANDIDATES' });
            }
          });
        }

        let outreachObject = {
          candidates: passedCandidateList,
          userId: this.authService.getUserDetails().id,
          companyId: this.authService.getUserDetails().company.companyId,
          clientOrBuId: this.clientOrBuId
        }

        if (passedCandidateList.length > 0) {
          this.candidateService.sendOutreachEmail(outreachObject, (data) => {
            this.alertsAndNotificationsService.showBannerMessage(data.message, 'success');
            if (this.completeCallback) {
              this.completeCallback.emit();
            }
          }, (error) => {
            if (this.completeCallback) {
              this.completeCallback.emit();
            }
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
          });
        }
      } else {
        if (this.completeCallback) {
          this.completeCallback.emit()
        }
      }

    });

    return uploadFileWizard;
  }

  /**
   * creates the candidates file upload drop zone.
   */
  createFileUploadDropzone() {
    let dropZoneSettings = this._getDropZoneSettings();
    let fileUploadDropzone = new Dropzone("form#fileUploadDropzone", dropZoneSettings);
    let vm = this;

    // add event handlers for the drop zone
    fileUploadDropzone.on('addedfile', (file, response) => {
      vm.fileExistsFlag = true;
      vm.updateActionButtons();
      $('.dz-progress').css({ top: '70%' });
    });

    fileUploadDropzone.on('removedfile', (file, response) => {
      if (vm.fileUploadDropzone.files.length === 0) {
        vm.fileExistsFlag = false;
        // enable adding of further files if it was disabled
        $(".dz-hidden-input").prop("disabled", false);
      }
      vm.updateActionButtons();
    });

    fileUploadDropzone.on('sending', (file, xhr, formData) => {
      // disable the upload button to avoid multiple clicks
      vm.disableUploadButtonFlag = true;
      vm.isFileUploaded = false;
      // indicate that file upload is in progress
      vm.fileUploadInProgressFlag = true;
      if (vm.jobContextExistsFlag) {
        formData.append("jobId", vm.jobId);
      }
    });

    fileUploadDropzone.on('success', (file, response) => {
      // remove file upload in progress flag
      vm.fileUploadInProgressFlag = false;
      vm.isFileUploaded = true;

      vm.alertsAndNotificationsService.showBannerMessage('Candidate File uploaded successfully', 'success');
      // merge the response into the job object
      vm._setCandidates(response);
      vm.updateActionButtons();
      // disable adding of further files
      $(".dz-hidden-input").prop("disabled", true);
    });

    fileUploadDropzone.on("error", (file, error) => {
      let elements = document.querySelectorAll(".dz-file-preview");
      angular.forEach(elements, (element, index) => {
        let filename = element.querySelectorAll(".dz-filename span")[0].textContent;
        let errorMessage = element.querySelectorAll(".dz-error-message span")[0];
        if (filename === file.name) {
          errorMessage.textContent = error.message == undefined ? error : error.message;
        }
      });
    });

    return fileUploadDropzone;
  }

  _getDropZoneSettings() {
    let dropZoneSettings = {};
    dropZoneSettings = {
      url: this.candidateService.getUploadUrl() + '/' + this.authService.getUserDetails().company.companyId,
      paramName: () => {
        return "file";
      },
      acceptedFiles: ".csv,.xlsx",
      maxFiles: 1,
      maxFilesize: 10,
      maxThumbnailFilesize: 10,
      timeout: 300000,
      addRemoveLinks: true,
      autoProcessQueue: false,
      uploadMultiple: false
    }
    return dropZoneSettings;
  }

  _setCandidates(candidatesResponse) {
    this.candidateList = [];
    this.candidateCounts = candidatesResponse.candidateCount;
    this.candidateUploadResponseList = candidatesResponse.candidateUploadResponseList;
    angular.forEach(candidatesResponse.candidateUploadResponseList, (candidate, key) => {
      if (_.isNull(candidate.firstName) || candidate.firstName == "") {
        candidate.firstName = "-";
      }
      if (_.isNull(candidate.lastName) || candidate.lastName == "") {
        candidate.lastName = "-";
      }
      if (_.isNull(candidate.email) || candidate.email == "") {
        candidate.email = "-";
      }
      if (_.isNull(candidate.phoneNumber) || candidate.phoneNumber == "") {
        candidate.phoneNumber = "-";
      }
      candidate.name = candidate.firstName + ' ' + candidate.lastName;
      candidate.disabled = false;
      if (candidate.phoneNumber.startsWith("+")) {
        let phoneNumberObject = libphonenumber.parse(candidate.phoneNumber.toString());
        if (phoneNumberObject.phone !== undefined) {
          candidate.phoneNumber = libphonenumber.format(phoneNumberObject.phone, phoneNumberObject.country, 'International');
        }
      }
      candidate.message = "";
      angular.forEach(candidate.messages, (message, key) => {
        if (key == 0) {
          candidate.message = message;
        } else {
          candidate.message = candidate.message + ". " + message;
        }
      });
      this.candidateList.push(candidate);
      // enable previous and next (complete) button after processing
      this.disableNextButtonFlag = false;
      this.disablePreviousButtonFlag = false;
    });
  }

  /**
   * update the action buttons
   */
  updateActionButtons() {
    let wizardCurrentStep = $('#uploadFileWizard').wizard('selectedItem').step;
    switch (wizardCurrentStep) {
      case 1:
        // if resumes exist and upload completed, then the functions on this tab are disabled.
        if (this.isFileUploaded) {
          this.fileUploadDropzone.clickable = false;
          this.disableUploadButtonFlag = true;
          this.disableNextButtonFlag = false;
        } else {
          // in else because no resumes have been uploaded yet
          if (this.fileExistsFlag) {
            // if files exist in drop zone
            this.disableUploadButtonFlag = false;
          } else {
            // files do not exist in drop zone
            this.disableUploadButtonFlag = true;
          }
          // disable the next button
          this.disableNextButtonFlag = true;
        }
        break;
      case 2:
        break;
      default:
        break;
    }
    // this.$timeout(() => {
    //   this.$scope.$apply();
    // }, 200);
  }

  uploadFile() {
    this.fileUploadDropzone.processQueue();
  }

  showFileFormat() {
    const config = new ModalConfig();
    const modalData: any = {};
    config.class = "modal-lg custom-ngx-modal";
    config.initialState = modalData;
    this.viewCandidateFileUploadModal = this.bsModalService.show(this.viewCandidateFileUploadFormatRef, config);
  }

  closeFileFormateModal() {
    this.viewCandidateFileUploadModal.hide();
  }

  wizardCancel() {
    if (this.cancelCallback) {
      this.cancelCallback.emit();
    }
  }

  _checkIfOutreachEnabled() {
    // this.candidateService.getOutreachEmailEnabledStatus((data) => {
    //     this.isOutreachEnabled = data;
    // }, (error) => {
    //     this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    // });
    this.isOutreachEnabled = true;
  }

  updateSendOutreachEmail(event) {
    this.sendOutreachEmail = event;
  }

}
