import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-associate-vendors',
  templateUrl: './associate-vendors.component.html',
  styleUrls: ['./associate-vendors.component.css']
})
export class AssociateVendorsComponent implements OnInit {

  @Input() associatedVendorsIds;
  @Output() saveCallback = new EventEmitter<any>();

  vendors: any = [];
  vendorsToAssociate: any = [];
  createAssociationsObject: any = {};
  searchTerm: any = "";
  companyId: any;

  isGettingVendorsInProgress: boolean = false;
  associatingVendorsInProgress: boolean = false;

  constructor(
    private companyService: CompanyService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.getVendors();
  }

  getVendors() {
    this.isGettingVendorsInProgress = true;
    let filterObject = { searchTerm: this.searchTerm, page: 1, size: 2000, sort: "name", sortDir: "ASC" }
    this.vendors = [];
    this.companyService.getAllStaffingCompanies(filterObject, (vendorsResponse) => {
      this._massageVendorsData(vendorsResponse);
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _massageVendorsData(vendorsResponse) {
    angular.forEach(vendorsResponse, (vendor, key) => {
      if (!_.isNull(vendor.contactList[0]) && angular.isDefined(vendor.contactList[0])) {
        vendor.contactName = vendor.contactList[0].firstname + ' ';
        if (!_.isNull(vendor.contactList[0].lastname)) {
          vendor.contactName += vendor.contactList[0].lastname;
        }
      }

      vendor.selectedFlag = false;
      vendor.isDisabled = false;

      if (!_.includes(this.associatedVendorsIds, vendor.id)) {
        this.vendors.push(vendor);
      }
      this.isGettingVendorsInProgress = false;
    });
  }

  vendorToAssociateToggle(vendor) {
    if (vendor.selectedFlag) {
      this._addVendorToAssociationList(vendor);
    } else {
      this._removeVendorFromAssociationList(vendor);
    }
  }

  _addVendorToAssociationList(vendor) {
    let vendorToAssociate = { vendorId: vendor.id, contactList: vendor.contactList };
    this.vendorsToAssociate.push(vendorToAssociate);
  }

  _removeVendorFromAssociationList(vendor) {
    _.remove(this.vendorsToAssociate, {
      vendorId: vendor.id
    });
  }

  associateVendors() {
    this._setVendorAssociationCreationObject();
    this.associatingVendorsInProgress = true;
    this.companyService.saveCorporateVendors(this.createAssociationsObject, (data) => {
      this.associatingVendorsInProgress = false;
      this.alertsAndNotificationsService.showBannerMessage("Vendor(s) associated successfully", 'success');
      this.saveCallback.emit();
    }, (error) => {
      this.associatingVendorsInProgress = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setVendorAssociationCreationObject() {
    this.createAssociationsObject = {
      corporateCompanyId: this.companyId,
      vendorsList: this.vendorsToAssociate
    };
  }

  cancel() {
    this.saveCallback.emit();
  }

  filterVendor() {
    this.getVendors();
  }

}
