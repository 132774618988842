import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  urlConstants = {
    QUERY_CANDIDATE_ID_NAMES: 'api/candidate/findidnames',
    GET_CANDIDATE_CARD: 'api/candidate/candidatecard',
    GET_ACTIVE_CANDIDATE_JOB_MATCHES: 'api/job/jobmatchbycandidate',
    GET_ALL_CLIENT_OR_BU: 'api/requisition/getclientorbulist',
    GET_CANDIDATE_PROFILE: 'api/candidate/candidateprofile',
    GET_CANDIDATE_COUNTS: 'api/candidate/statuscount',
    GET_CANDIDATE_REPORT: 'api/candidate/candidatepdfreport',
    GET_CANDIDATE_ACTIVITIES: 'api/candidate/candidateActivity',
    GET_CANDIDATE_CLIENT_OR_BU: 'api/candidate/getcandidateclientorbus',
    GET_CANDIDATE_JOBS: 'api/candidate/getcandidatejobandrole',
    ADD_CANDIDATES: 'api/candidate/addcandidates',
    SET_CANDIDATE_AVAILABILITY: 'api/candidate/setcandidateavailability',
    DELETE_CANDIDATE: 'api/candidate/delete',
    ADD_UPDATE_CANDIDATE_NAME: 'api/candidate/addorupdatename',
    ADD_UPDATE_CANDIDATE_EMAIL: 'api/candidate/addorupdatecontactemail',
    ADD_UPDATE_CANDIDATE_PHONE: 'api/candidate/addorupdatecontactphone',
    ADD_UPDATE_CANDIDATE_ADDRESS: 'api/candidate/addorupdatecontactaddress',
    DELETE_CANDIDATE_ON_RESUME_REMOVE: 'api/candidate/deletecandidateonresumeremove',
    GET_CANDIDATES_COUNT: 'api/candidate/statuscountforcompany',
    SEND_TECH_ASSESSMENTS: 'api/candidate/sendtechassessments',
    SEND_VALUE_ASSESSMENTS: 'api/candidate/sendvalueassessments',
    CANCEL_TECH_ASSESSMENTS: 'api/candidate/canceltechassessments',
    CANCEL_VALUE_ASSESSMENTS: 'api/candidate/cancelvalueassessments',
    UPDATE_CANDIDATE_REPORT_DETAILS: 'api/candidate/updatecandidatereportinfo',
    DELETE_COMMENT_FROM_CANDIDATE_REPORT_DETAILS: 'api/candidate/deletecommentfromcandidatereportdetails',
    ADD_OR_UPDATE_CANDIDATE_ADDITIONAL_DETAILS: 'api/candidate/addorupdatecandidateadditionaldetails',
    ALLOW_SUSPENSION_OVERRIDE: 'api/candidate/allowoverride',
    CANCEL_SENT_ASSESSMENTS: 'api/candidate/cancelassessmentsonassessmentupdate',
    UPLOAD_CANDIDATES: 'api/candidate/uploadcandidates',
    UPDATE_CANDIDATE_CONTACT_DETAILS: 'api/candidate/updateCandidateContactDetails',
    DO_NOT_HIRE_CANDIDATE: 'api/candidate/performDoNotHireAction',
    SEND_OUTREACH_EMAIL: 'api/candidate/out-reach/email',
    GET_OUTREACH_EMAIL_STATUS: 'api/candidate/getOutreachEmailStatus'
  }

  constructor(@Inject(StorageService) private StorageService:StorageService, @Inject(GenericService) private genericService:GenericService) { }

  queryCandidateIdNames(filterObject, successCallback, errorCallback) {
    let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.QUERY_CANDIDATE_ID_NAMES, filterObject);
    promise.subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
    return promise;
}

getCandidateCard(candidateId, userId, filterParams, successCallback, errorCallback) {
    let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_CARD + '/' + candidateId + '/' + userId, filterParams);
    promise.subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
    return promise;
}

getCandidateJobMatches(jobMatchesObject, page, size, successCallback, errorCallback) {
    let queryString = "";
    if(size !== '' && page !== '') {
        queryString = "?size=" + size + "&page=" + page;
    }
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ACTIVE_CANDIDATE_JOB_MATCHES + queryString, jobMatchesObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getCandidateActivities(candidateFilterParams, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_ACTIVITIES, candidateFilterParams).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getCandidateClientsOrBu(candidateId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_CLIENT_OR_BU + '/' + candidateId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getCandidateJobs(candidateId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_JOBS + '/' + candidateId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getAllClientsOrBu(userDetails, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CLIENT_OR_BU + '/' + userDetails.id + '/' + userDetails.company.companyId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getCandidateProfile(candidateId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_PROFILE + '/' + candidateId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getCandidateCounts(userId, companyId, visibility, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_COUNTS + '/' + userId + '/' + companyId;
    if(!_.isUndefined(visibility) && visibility.toString().trim() != '') {
        url += '?visibility=' + visibility;
    }
    this.genericService.getObjects(url).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

getCandidateReport(filepath, successCallback, errorCallback) {
    let options = {responseType: 'arraybuffer'};

    this.genericService.getObjectWithOptions(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_REPORT + '?filepath=' + filepath, options).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

addCandidates(candidatesArray, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_CANDIDATES, candidatesArray).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

setCandidateAvailability(candidateAvailabilityObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SET_CANDIDATE_AVAILABILITY, candidateAvailabilityObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

deleteCandidate(deleteCandidateObject, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_CANDIDATE, deleteCandidateObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

addUpdateCandidateName(contactObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_CANDIDATE_NAME, contactObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

addUpdateCandidateEmail(contactObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_CANDIDATE_EMAIL, contactObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

addUpdateCandidatePhone(contactObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_CANDIDATE_PHONE, contactObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

addUpdateCandidateAddress(contactObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_UPDATE_CANDIDATE_ADDRESS, contactObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

// deleteCandidateOnResumeRemove(resumeId, successCallback, errorCallback) {
//     this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_CANDIDATE_ON_RESUME_REMOVE + '/' + resumeId).subscribe((data) => {
//         successCallback(data.body);
//     }, (error) => {
//         errorCallback(error.error);
//     });
// }

getCandidatesCount(companyId, userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATES_COUNT + '/' + companyId + '/' + userId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

sendAssessments(assessmentType, assessmentObject, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.SEND_TECH_ASSESSMENTS;
    if(assessmentType == 'Value') {
        url = this.StorageService.get('baseurl') + this.urlConstants.SEND_VALUE_ASSESSMENTS;
    }
    this.genericService.addObject(url, assessmentObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

cancelAssessments(assessmentType, assessmentObject, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.CANCEL_TECH_ASSESSMENTS;
    if(assessmentType == 'Value') {
        url = this.StorageService.get('baseurl') + this.urlConstants.CANCEL_VALUE_ASSESSMENTS;
    }
    this.genericService.addObject(url, assessmentObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
} 

updateCandidateReportDetails(companyId, candidateAssessmentDetailId, reportDetailsObject, successCallback, errorCallback){
    let url = this.StorageService.get('baseurl') + this.urlConstants.UPDATE_CANDIDATE_REPORT_DETAILS + '/' + candidateAssessmentDetailId;
    if(!_.isNull(companyId)){
        url = url + "?companyId=" + companyId;
    }
    this.genericService.addObject(url , reportDetailsObject).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

deleteCommentFromCandidateReportDetails(assessmentDetailId, commentId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.DELETE_COMMENT_FROM_CANDIDATE_REPORT_DETAILS + '/' + assessmentDetailId;
    if(!_.isNull(commentId)) {
        url = url + '?commentId=' + commentId;
    }
    this.genericService.getObjects(url).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

addOrUpdateCandidateAdditionalDetails(additionalDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_OR_UPDATE_CANDIDATE_ADDITIONAL_DETAILS, additionalDetails).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

allowSuspensionOverride(candidateId, recruiterDetails, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.ALLOW_SUSPENSION_OVERRIDE + '/' + candidateId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

cancelSentAssessments(candidateAssessmentIds, companyId, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.CANCEL_SENT_ASSESSMENTS + '/' + companyId, candidateAssessmentIds).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

updateCandidateContactDetails(candidateId, contactDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_CANDIDATE_CONTACT_DETAILS + '/' + candidateId, contactDetails).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

doNotHireCandidate(doNotHireDetails, successCallback, errorCallback){
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.DO_NOT_HIRE_CANDIDATE, doNotHireDetails).subscribe(data => {
        successCallback(data.body);
    }, error => {
        errorCallback(error.error);
    });
}

getUploadUrl() {
    return this.urlConstants.UPLOAD_CANDIDATES;
}

sendOutreachEmail(candidatesDetails, successCallback, errorCallback){
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SEND_OUTREACH_EMAIL, candidatesDetails).subscribe(data => {
        successCallback(data.body);
    }, error => {
        errorCallback(error.error);
    });
}

getOutreachEmailEnabledStatus(companyId, successCallback, errorCallback) { // Update when server side done
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_OUTREACH_EMAIL_STATUS + '/' + companyId).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

}
