import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobLeftComponent } from './job-left.component';
import { ExternalNotesModule } from '../external-notes/external-notes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [JobLeftComponent],
  imports: [
    CommonModule,
    FormsModule,
    ExternalNotesModule
  ],
  entryComponents: [JobLeftComponent],
  exports: [JobLeftComponent]
})
export class JobLeftModule { }
