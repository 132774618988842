import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { Config } from 'datatables.net';
import { Subject } from 'rxjs';
import { CompanyService } from 'src/app/core/services/company.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AssociateVendorActionModalComponent } from './associate-vendor-action-modal/associate-vendor-action-modal.component';

@Component({
  selector: 'app-vendors-page',
  templateUrl: './vendors-page.component.html',
  styleUrls: ['./vendors-page.component.css']
})
export class VendorsPageComponent implements OnInit {
  @Input() $transition$;

  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  MESSAGECONSTANTS: any = MESSAGECONSTANTS;
  vendorsActionsModal: BsModalRef;

  stateParams: any;
  vendors: any = [];
  vendorIds: any = [];
  actionType: any = "";
  companyId: any;
  companyType: any;
  vendorToBeDeleted: any;
  vendorActionModalLabel: String = "";


  isLoading: boolean = true;
  constructor(
    @Inject('$state') private $state: any,
    private authService: AuthService,
    private companyService: CompanyService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.actionType = "";
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.companyType = this.authService.getUserDetails().company.companyType;

    this._setDataTable();
    this.getVendors();
  }

  _setDataTable() {
    let buttonLabel = 'Search & Associate Vendors';
    let backGroundEmptyLabel = 'No Vendors to display';
    this.dtOptions = {
      pagingType: 'full_numbers',
      order: [[4, 'desc']],
      searching: true,
      lengthChange: false,
      info: true,
      language: { search: '', searchPlaceholder: 'Type to filter', zeroRecords: backGroundEmptyLabel },
      dom: "<'row'<'col-xs-12 text-right'Bf>><t>ip",
      buttons: [
        {
          text: buttonLabel,
          className: 'btn btn-primary add-vendor-button',
          key: '2',
          action: () => {
            this.associateVendors();
          }
        }
      ],
      columnDefs: [
        { orderable: false, targets: [-1] }
      ]
    };
    // this.dtOptions = this.DTOptionsBuilder.newOptions()
    //   .withPaginationType('full_numbers')
    //   .withOption('order', [4, 'desc'])
    //   .withOption('bFilter', true)
    //   .withOption('bLengthChange', false)
    //   .withOption('bInfo', true)
    //   .withOption('language', { search: "", searchPlaceholder: 'Type to filter', zeroRecords: backGroundEmptyLabel })
    //   .withBootstrap()
    //   // Active Buttons extension
    //   .withButtons([
    //     {
    //       tag: 'button',
    //       className: 'btn btn-primary add-vendor-button',
    //       text: buttonLabel,
    //       key: '2',
    //       action: (e, dt, node, config) => {
    //         this.associateVendors()
    //       }
    //     }
    //   ])
    //   .withDOM("<'row'<'col-xs-12 text-right'Bf>><t>ip");
  }

  getVendors() {
    this.isLoading = true;
    this.companyService.getAllCorporateVendors(this.companyId, (data) => {
      this.vendors = [];
      angular.forEach(data, (vendor, key) => {
        if (vendor.contactList.length > 0 && !_.isNull(vendor.contactList[0]) || !_.isUndefined(vendor.contactList[0])) {
          vendor.contactName = vendor.contactList[0].firstname + ' ';
          if (!_.isNull(vendor.contactList[0].lastname)) {
            vendor.contactName += vendor.contactList[0].lastname;
          }
        }
        this.vendors.push(vendor);
        this.isLoading = false;
      });
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  associateVendors() {
    this.actionType = 'associateVendors';
    this.launchActionsModal();
  }

  updateVendor(vendor) {
    this.$state.go('missioncontrol.companymanagement', { vendorId: vendor.vendorId }, {});
  }

  _getParsedContactList(vendor) {
    var parsedContactList = [];
    angular.forEach(vendor.contactList, (contact, key) => {
      var parsedContact: any = {};
      parsedContact.email = contact.email;
      parsedContact.firstName = contact.firstname;
      parsedContact.lastName = contact.lastname;
      parsedContact.im = contact.im;
      parsedContact.isPrimary = contact.isPrimary;
      parsedContact.mobilePhone = contact.mobilephone;
      parsedContact.workPhone = contact.workphone;
      parsedContactList.push(parsedContact);
    });
    return parsedContactList;
  }

  getAssociatedVendorIds() {
    var vendorIds = [];
    angular.forEach(this.vendors, (vendor, key) => {
      vendorIds.push(vendor.vendorId);
    });
    return vendorIds;
  }

  deleteVendor(vendor) {
    this.actionType = 'deleteVendor';
    this.vendorToBeDeleted = angular.copy(vendor);
    this.getSharedReqAndBUCount(() => {
      this.launchActionsModal();
    });
  }

  getSharedReqAndBUCount(callback) {
    this.companyService.getSharedReqAndBuCount(this.vendorToBeDeleted, (data) => {
      angular.merge(this.vendorToBeDeleted, data);
      if (callback) {
        callback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  launchActionsModal() {
    this.vendorIds = this.getAssociatedVendorIds();
    let modalWindowClass = "";
    if (this.actionType == "deleteVendor") {
      this.vendorActionModalLabel = "Delete Vendor";
      modalWindowClass = "custom-modal-65";
    } else {
      this.vendorActionModalLabel = "Associate Vendors";
      modalWindowClass = "custom-modal-xl";
    }

    const config = new ModalConfig();
    const modalData: any = {
      actionType: this.actionType,
      actionLabel: this.vendorActionModalLabel,
      associatedVendorIds: this.vendorIds,
      corpVendorInfo: this.vendorToBeDeleted
    };
    config.class = `custom-ngx-modal ${modalWindowClass}`;
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    this.vendorsActionsModal = this.bsModalService.show(AssociateVendorActionModalComponent, config);
    this.vendorsActionsModal.content.onClose.subscribe(result => {
      if (result && result == 'refresh') {
        this.getVendors();
      }
    });
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}
