import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { JobMatchQueryFilter } from 'src/app/core/models/jobMatchQueryFilter';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { JobService } from 'src/app/core/services/job.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Config, DataTables } from 'datatables.net';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { CandidateService } from 'src/app/core/services/candidate.service';

@Component({
  selector: 'app-candidate-job-matches',
  templateUrl: './candidate-job-matches.component.html',
  styleUrls: ['./candidate-job-matches.component.css']
})
export class CandidateJobMatchesComponent implements OnInit {

  @Input() candidateId;
  @Input() userId;
  @Input() vendorIds;
  @Input() candidateType;
  @ViewChild('candidateJobMatchActionsTempalte', {static: true}) candidateJobMatchActionsTempalteRef: TemplateRef<any>;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtInstance: any;
  candidateActionsModal: BsModalRef;

  jobMatches: any = [];
  companyId: any = "";
  userName: any = "";
  salaryDurationOptions: any;
  jobTypeList: any;
  selectedJobMatchId: any;
  candidateJobMatchActionsActionType: any;
  candidateJobMatchActionsJobId: any;
  candidateJobMatchActionsJobMatchId: any;
  candidateJobMatchActionsLabel: any;

  loadingFlag: boolean = true;
  isUserAdmin: boolean = false;
  isStaffingCompany: boolean = false;
  isCandidateDetailsUpdatedLoading: boolean = false;
  isPocUnavailable: boolean = false;
  isLoading: boolean = false;
  isOutreachEnabled: boolean = false;
  isVendorOnly: boolean = false;
  isOureachActionAvailable: boolean = false;

  constructor(
    private authService: AuthService,
    private userRoleService: UserRoleService,
    private companyService: CompanyService,
    private utilityService: UtilityService,
    private jobMatchService: JobMatchService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private jobService: JobService,
    @Inject('sharedAjsService') private sharedAjsService:any,
    @Inject('jobMatchActionsService') private jobMatchActionsService:any,
    @Inject('$rootScope') private $rootScope:any,
    @Inject('$state') private $state:any,
    private bsModalService: BsModalService,
    private candidateService: CandidateService
  ) { }

  ngOnInit() {
    //variables
    this.jobMatches = [];
    this.companyId = "";
    //flags
    this.loadingFlag = true;
    this.isUserAdmin = false;
    this.isStaffingCompany = false;
    this.isCandidateDetailsUpdatedLoading = false;
    this.isVendorOnly = this.companyService.isCompanyVendor();

    this._setDataTable();

    this.userName = this.authService.getUserDetails().firstname + ' ' + this.authService.getUserDetails().lastname;
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.isUserAdmin = (this.userRoleService.isLoggedInUserSuperUserOr4dot5Admin() || this.userRoleService.isLoggedInUserCompanyAdmin()) ? true : false;
    this.isStaffingCompany = this.companyService.isStaffingCompany();
    this.getCandidateJobMatches(null);
    this._listenToJobMatchesRefresh();
    this.getSalaryDurationOptions();
    this.getAllPreferredJobTypes();
    this._checkIfOutreachEnabled();
  }

  _listenToJobMatchesRefresh() {
    this.sharedAjsService.receiveRefreshJobMatchesEvent().then(null, null, (jobMatch) => {
      if (this.candidateId == jobMatch.candidateId && jobMatch.jobMatchesActiveTab == 'jobMatches') {
        console.log("refresh job matches table");
        this.getCandidateJobMatches(jobMatch);
      }
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  _setDataTable() {
    // Register custom sorting function
    ($ as any).extend(($ as any).fn.dataTableExt.oSort, {
      'locale-asc': (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      },

      'locale-desc': (a, b) => {
        return b.localeCompare(a, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      }
    });

    this.dtInstance = {};
    this.dtOptions = {
      pagingType: 'full_numbers',
      order: [[0, 'asc']], // Sorting by first column
      info: true,
      processing: true,
      serverSide: false,
      columnDefs: [
        { targets: 0, type: 'locale' },
        { targets: 6, type: 'locale' }, // Req Number
        { targets: [7, 10], orderable: false } // Recruiters & Actions columns not sortable
      ]
    };
    
    // this.dtOptions = this.DTOptionsBuilder.newOptions()
    //   .withPaginationType('full_numbers')
    //   .withOption('order', [0, 'asc']);

    // this.dtColumnDefs = [
    //   this.DTColumnDefBuilder.newColumnDef(0), // rank
    //   this.DTColumnDefBuilder.newColumnDef(1), //Job match date
    //   this.DTColumnDefBuilder.newColumnDef(2), //Job title
    //   this.DTColumnDefBuilder.newColumnDef(3), //Job type
    //   this.DTColumnDefBuilder.newColumnDef(4), //Company
    //   this.DTColumnDefBuilder.newColumnDef(5), //Status
    //   this.DTColumnDefBuilder.newColumnDef(6).withOption('type', 'locale'), //Req Number
    //   this.DTColumnDefBuilder.newColumnDef(7).notSortable(), //Recruiters
    //   this.DTColumnDefBuilder.newColumnDef(8), //Job Match score
    //   this.DTColumnDefBuilder.newColumnDef(9), // TA score
    //   this.DTColumnDefBuilder.newColumnDef(10).notSortable() //Actions
    // ];
  }

  getCandidateJobMatches(jobMatch) {
    this.loadingFlag = true;
    if (this.dtInstance.DataTable) {
      this.dtInstance.DataTable.destroy();
    }
    if (!jobMatch) {
      this.utilityService.showLoadingModal("Fetching Job Matches");
    } else {
      this.isCandidateDetailsUpdatedLoading = true;
    }
    let jobMatchesObject = new JobMatchQueryFilter();
    jobMatchesObject.candidateId = this.candidateId;
    jobMatchesObject.userId = this.authService.getUserDetails().id;
    jobMatchesObject.companyId = this.authService.getUserDetails().company.companyId;
    jobMatchesObject.top5Flag = false;
    jobMatchesObject.candidateType = this.candidateType;
    jobMatchesObject.searchText = '';
    jobMatchesObject.vendorIds = this.vendorIds;
    this.jobMatchService.getDetailedCandidateJobMatches(jobMatchesObject, this.companyId, 1, 250, (data) => {
      this._massageJobMatches(data);
      this.loadingFlag = false;
      this.utilityService.hideLoadingModal();
      this.isCandidateDetailsUpdatedLoading = false;
    }, (error) => {
      this.loadingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getSalaryDurationOptions() {
    this.jobService.getSalaryDurationOptions((data) => {
      this.salaryDurationOptions = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getAllPreferredJobTypes() {
    this.jobService.getAllJobTypes((data) => {
      this.jobTypeList = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  _setRecruiterDisplayName(name, type, primaryRecruiter, primaryHiringManager, vendorRecruiter) {
    let displayType = {
      name: '', displayName: '', type: type, primaryRecruiter: primaryRecruiter, primaryHiringManager: primaryHiringManager, vendorRecruiter: vendorRecruiter
    };
    displayType.name = name;
    displayType.displayName = name.length > 30 ? name.substring(0, 30) + "..." : name;
    displayType.displayName = `<span>${displayType.displayName} <sup>${type}</sup><span>`;
    return displayType;
  }

  _setRecruiters(val) {
    val.recruitersList = [];
    val.staffingCompanyRecruiterList = [];
    val.corporateCompanyRecruiterList = [];
    this.isPocUnavailable = false;
    if (val.recruitersInfo && val.recruitersInfo.length > 0) {
      val.recruitersInfo.map(recruiter => {
        recruiter.name = recruiter.firstName + " " + recruiter.lastName;
        let type = recruiter.isPrimary ? "" : "R";
        if (!recruiter.isPrimary && val.poc && val.poc.length > 0 && (recruiter.name == val.poc) && (this.isStaffingCompany || (!this.isStaffingCompany && val.candidateJobMatchCardDetails.isSharedRequisition))) {
          type = "R POC";
          this.isPocUnavailable = true;
        }
        val.recruitersList.push(this._setRecruiterDisplayName(recruiter.name, type, recruiter.isPrimary, false, recruiter.vendorRecruiter));
      })
    }

    if (val.hiringManager && val.hiringManager.length > 0 && !this.isStaffingCompany) {
      val.hiringManager.map(hiringManger => {
        let type = "HM";
        if (hiringManger.isPoc && val.isPoc && val.candidateJobMatchCardDetails.isSharedRequisition) {
          type = "HM POC"
          this.isPocUnavailable = true;
        }
        val.recruitersList.push(this._setRecruiterDisplayName(hiringManger.name, type, false, hiringManger.isPrimary, false));
      })
    }

    if (val.poc && val.poc.length > 0 && (val.primaryRecruiterName != val.poc)) {
      if (!this.isPocUnavailable) {
        val.recruitersList.push(this._setRecruiterDisplayName(val.poc, "POC", false, false, false));
      }
    }

    val.staffingCompanyRecruiterList = val.recruitersList.filter(recruiter => (recruiter.vendorRecruiter && recruiter.type != 'POC'));
    val.corporateCompanyRecruiterList = val.recruitersList.filter(recruiter => !recruiter.vendorRecruiter);

    if (this.isStaffingCompany) {
      val.recruitersList = _.union(val.staffingCompanyRecruiterList, val.corporateCompanyRecruiterList);
    } else {
      val.recruitersList = _.union(val.corporateCompanyRecruiterList, val.staffingCompanyRecruiterList)
    }

  }

  _massageJobMatches(jobMatches) {
    this.jobMatches = [];
    angular.forEach(jobMatches, (val, key) => {
      val.candidateJobMatchCardDetails.jobMatchId = val.jobMatchId;
      this._massageCandidateJobMatchCardData(val.candidateJobMatchCardDetails);
      this._massageCandidateJobMatchBasicData(val);
      this.jobMatches.push(val);
    });
  }

  _massageCandidateJobMatchBasicData(jobMatch) {
    jobMatch.jobId = jobMatch.candidateJobMatchCardDetails.jobMatchJobId;
    jobMatch.candidateName = jobMatch.candidateJobMatchCardDetails.firstName + " " + jobMatch.candidateJobMatchCardDetails.lastName;
    jobMatch.candidateEmail = jobMatch.candidateJobMatchCardDetails.email;
    jobMatch.jobType = jobMatch.candidateJobMatchCardDetails.jobType;
    jobMatch.contractDuration = jobMatch.candidateJobMatchCardDetails.contractDuration;
    jobMatch.requisitionNote = jobMatch.candidateJobMatchCardDetails.requisitionNote;
    jobMatch.clientNote = jobMatch.candidateJobMatchCardDetails.clientNote;
    jobMatch.requisitionNumber = jobMatch.candidateJobMatchCardDetails.jobMatchRequisitionNum;
    jobMatch.requisitionStatus = jobMatch.candidateJobMatchCardDetails.jobMatchRequisitionStatus;
    jobMatch.jobMatchDate = new Date(jobMatch.candidateJobMatchCardDetails.jobMatchDate);
    jobMatch.currentStep = jobMatch.candidateJobMatchCardDetails.currentStep;
    jobMatch.currentState = jobMatch.candidateJobMatchCardDetails.jobMatchCurrentState;
    jobMatch.fourDotFiveIntelligentScore = jobMatch.candidateJobMatchCardDetails.roundedJobMatchScore;
    jobMatch.fourDotFiveIntelligentThresholdScore = jobMatch.candidateJobMatchCardDetails.fourDotFiveIntelligenceScore;
    jobMatch.fourDotFiveIntelligentThresholdScore = jobMatch.candidateJobMatchCardDetails.fourDotFiveIntelligenceScore;
    jobMatch.techAssessmentScore = jobMatch.candidateJobMatchCardDetails.techAssessmentScore;
    jobMatch.techAssessmentThresholdScore = jobMatch.candidateJobMatchCardDetails.techAssessmentThresholdScore;
    jobMatch.disable = false;
    jobMatch.primaryRecruiterName = jobMatch.candidateJobMatchCardDetails.primaryRecruiterName;
    // jobMatch.allRecruiters = jobMatch.recruitersInfo.filter((recruiter) => {
    //     recruiter.name = recruiter.firstName + " " + recruiter.lastName;
    //     return recruiter.name !== jobMatch.primaryRecruiterName;
    // });

    this._setRecruiters(jobMatch);

    if (_.isNull(jobMatch.fourDotFiveIntelligentScore)) {
      jobMatch.fourDotFiveIntelligentScore = 'N/A';
    }
    if (_.isNull(jobMatch.techAssessmentScore)) {
      jobMatch.techAssessmentScore = 'N/A';
    } else {
      jobMatch.techAssessmentScore = Math.round(jobMatch.techAssessmentScore);
    }
    if (!_.isNull(jobMatch.jobLocation)) {
      jobMatch.jobDetailsText = this.jobService.getParsedJobLocationText(jobMatch.jobLocation, false);
    }
    if ((!jobMatch.recruiters.includes(this.userName) && !this.isUserAdmin) || jobMatch.requisitionStatus === 'CLOSED') {
      jobMatch.disable = true;
    }
    if (jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser) {
      jobMatch.stateActions = [];
      _.remove(jobMatch.candidateJobMatchCardDetails.stepActions, (availableAction:any) => {
        return availableAction.action.toLowerCase().includes("skip")
          || availableAction.action.toLowerCase().includes("schedule")
          || availableAction.action.toLowerCase().includes("modify")
          || availableAction.action.toLowerCase().includes("cancel");
      });
    }
    jobMatch.workLocationsMatchStatus = this.jobMatchService.getJobAndCandidateWorkLocationsMatchStatus(jobMatch.jobWorkLocations, jobMatch.candidateWorkLocations);
    jobMatch.isLoading = false;
    jobMatch.candidateType = this.candidateType;
    jobMatch.firstStep = jobMatch.firstStep;
  }

  _massageCandidateJobMatchCardData(candidateJobMatch) {
    candidateJobMatch.name = this._setCandidateName(candidateJobMatch);
    candidateJobMatch.jobMatchDate = new Date(candidateJobMatch.jobMatchDate);
    if (_.isNull(candidateJobMatch.jobMatchRequisitionNum)) {
      candidateJobMatch.jobMatchRequisitionNum = '-';
    }
    candidateJobMatch.primaryRecruiter = {};
    candidateJobMatch.primaryRecruiterName = '';
    candidateJobMatch.corpRecruiters = [];
    candidateJobMatch.vendorRecruiters = [];
    let tempPrimaryRecruiterIndex = -1;
    if (!_.isNull(candidateJobMatch.recruitersList) && candidateJobMatch.recruitersList.length) {
      angular.forEach(candidateJobMatch.recruitersList, (val, key) => {
        val.name = val.firstName;
        if (!_.isNull(val.lastName)) {
          val.name += ' ' + val.lastName;
        }
        if (val.isPrimary) {
          candidateJobMatch.primaryRecruiter = val;
          candidateJobMatch.primaryRecruiterName = val.name;
          candidateJobMatch.isPrimaryRecruiterVendor = val.vendorRecruiter;
          tempPrimaryRecruiterIndex = key;
        } else if (val.vendorRecruiter) {
          candidateJobMatch.vendorRecruiters.push(val);
        } else {
          candidateJobMatch.corpRecruiters.push(val);
        }
      });
    }
    if (tempPrimaryRecruiterIndex != -1) {
      // remove the primary recruiter object from the recruiter list array
      candidateJobMatch.recruitersList.splice(tempPrimaryRecruiterIndex, 1);
    }
    if (_.isNull(candidateJobMatch.candidateStatus)) {
      candidateJobMatch.candidateStatus = 'Active';
    }
    if (_.isNull(candidateJobMatch.jobMatchStatus)) {
      candidateJobMatch.jobMatchStatus = 'ACTIVE';
    }
    candidateJobMatch.numberOfOpenOpenings = (candidateJobMatch.totalOpenings - candidateJobMatch.numberOfFilledOpenings);
    this._setStepActions(candidateJobMatch);
  }

  _setCandidateName(candidate) {
    if (_.isNull(candidate.firstName)) {
      candidate.firstName = "-";
    }
    if (_.isNull(candidate.lastName)) {
      candidate.lastName = "-";
    }
    if (candidate.firstName == "-" && candidate.lastName == "-") {
      candidate.nameWarning = 'First and Last Name Missing';
    } else if (candidate.firstName == "-") {
      candidate.nameWarning = "First Name Missing";
    } else if (candidate.lastName == "-") {
      candidate.nameWarning = "Last Name Missing"
    } else {
      candidate.nameWarning = "";
    }
    return candidate.firstName + ' ' + candidate.lastName;
  }

  _setStepActions(jobMatch) {
    const stateActions = new Set([
        'COMMUNICATION', 'PEER_COMMUNICATION', 'NOTES',
        'ASSIGN_RECRUITER', 'RELEASE', 'NOT_INTERESTED'
    ]);

    jobMatch.stepActions = [];
    jobMatch.stateActions = [];

    angular.forEach(jobMatch.availableActions, (action) => {
        if (action.actionType === 'SEND_OUTREACH') {
            this.isOureachActionAvailable = true;
            action.actionCategory = "outreach";
        } else {
            action.actionCategory = stateActions.has(action.actionType) ? "state" : "step";
        }
    });

    jobMatch.stepActions = jobMatch.availableActions.filter(action => action.actionCategory === "step");
    jobMatch.stateActions = jobMatch.availableActions.filter(action => action.actionCategory === "state");

    const actionIcons = {
        'Communication': 'fa fa-comments pl-2',
        'Peer Communication': 'fa fa-comments pl-2',
        'Notes': 'fa fa-sticky-note pl-2',
        'Assign Recruiter': 'fa fa-user-plus pl-2',
        'Not Interested': 'fa fa-thumbs-down pl-2',
        'Release': 'fas fa-sign-out-alt pl-2'
    };

    jobMatch.stateActions.forEach(action => {
        action.class = actionIcons[action.action] || '';
    });
}

  performAction(actionObject, jobMatch) {
    this.selectedJobMatchId = jobMatch.jobMatchId;
    this.jobMatchActionsService.performAction('candidateJobMatches', actionObject, jobMatch, () => {
      this.getCandidateJobMatches(null);
    }, () => {
      this.removeJobMatch();
    });
  }

  refreshJobMatch() {
    let jobMatchToBeReplacedIndex = _.findIndex(this.jobMatches, { jobMatchId: this.selectedJobMatchId });
    this.jobMatches[jobMatchToBeReplacedIndex].isLoading = false;
    let jobMatchToBeReplaced = angular.copy(this.jobMatches[jobMatchToBeReplacedIndex]);
    this.jobMatches[jobMatchToBeReplacedIndex].isLoading = true;
    this.jobMatchService.getCandidateJobMatchCard(this.selectedJobMatchId, this.companyId, (data) => {
      let jobMatch = data;
      this._massageCandidateJobMatchCardData(jobMatch);
      jobMatchToBeReplaced.candidateJobMatchCardDetails = angular.copy(jobMatch);
      jobMatchToBeReplaced.candidateJobMatchCardDetails.jobMatchId = jobMatchToBeReplaced.jobMatchId;
      this._massageCandidateJobMatchBasicData(jobMatchToBeReplaced);
      this.jobMatches.splice(jobMatchToBeReplacedIndex, 1, angular.copy(jobMatchToBeReplaced));
      // this.loadingFlag = true;
      // this.$timeout(()=>{
      //     this.loadingFlag = false;
      // },10);
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      this.$rootScope.$emit("refreshCandidateCard", { candidateId: this.candidateId });
    }, (error) => {
      this.$rootScope.$emit("refreshCandidateCard", { candidateId: this.candidateId });
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    }, undefined);
  }

  goToJobProfile(jobId, isDisabled, hasMeetingScheduleAndNoScopeForLoggedInUser) {
    if (!isDisabled && !hasMeetingScheduleAndNoScopeForLoggedInUser) {
      let actionType = 'jobProfile';
      this._openViewModal(actionType, jobId, '');
    }
  }

  _openViewModal(actionType, jobId, jobMatchId) {
    this.candidateJobMatchActionsActionType = actionType;
    this.candidateJobMatchActionsJobId = jobId;
    this.candidateJobMatchActionsJobMatchId = jobMatchId;
    this.candidateJobMatchActionsLabel = "Job Profile";
    if (actionType == "candidateJobComparison") {
      this.candidateJobMatchActionsLabel = "Candidate Job Comparison";
    }
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = `custom-ngx-modal custom-modal-xl custom-candidate-job-match-modal`;
    const modalData: any = {};
    config.initialState = modalData;
    this.candidateActionsModal = this.bsModalService.show(this.candidateJobMatchActionsTempalteRef, config);
  }

  goToCandidateJobComparison(jobId, jobMatchId, isDisabled, hasMeetingScheduleAndNoScopeForLoggedInUser) {
    this._openViewModal('candidateJobComparison', jobId, jobMatchId);
  }

  closeCandidateJobMatchActionsModal() {
    this.candidateActionsModal.hide();
  }

  getReport(jobMatch) {
    if (jobMatch.techAssessmentScore !== 'N/A') {
      let isSectionalReport = false;
      if (jobMatch.sharedJob && this.isStaffingCompany) {
        isSectionalReport = true;
      }
      this.jobMatchActionsService.getReport(jobMatch.jobMatchId, 'Technical', isSectionalReport, jobMatch.candidateJobMatchCardDetails.techAssessmentPdfReportURL, jobMatch.candidateJobMatchCardDetails.testInvitationId);
    }
  }

  removeJobMatch() {
    let jobMatchToBeReplacedIndex = _.findIndex(this.jobMatches, { jobMatchId: this.selectedJobMatchId });
    this.jobMatches.splice(jobMatchToBeReplacedIndex, 1);
    this.$rootScope.$emit("refreshCandidateCard", { candidateId: this.candidateId });
  }

  goToWorkflow(jobMatch, filtered) {
    if (!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser) {
      let searchString = jobMatch.candidateEmail;
      if (_.isNull(searchString)) {
        searchString = jobMatch.candidateName;
      }
      if (filtered) {
        this.$state.go('missioncontrol.candidateJobMatchesWorkflow', {
          jobId: jobMatch.jobId,
          searchString: searchString,
          jobMatchId: jobMatch.jobMatchId
        });
      } else {
        this.$state.go('missioncontrol.candidateJobMatchesWorkflow', {
          jobId: jobMatch.jobId
        });
      }
    }
  }

  cardMenuToggled(openFlag: boolean, id: string): void {
    if (openFlag) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
      }, 220);
    }
  }

  _checkIfOutreachEnabled() {
    // this.candidateService.getOutreachEmailEnabledStatus((data) => {
    //     this.isOutreachEnabled = data;
    // }, (error) => {
    //     this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    // });
    this.isOutreachEnabled = true;
  }

  sendOutreachEmail(jobMatch) {
    let message = "Do you want us to send the candidate an outreach email to determine their interest?";
    this.alertsAndNotificationsService.showConfirm("Send outreach", message, "warning", true, () => {
      let candidateIds = [{ id: this.candidateId, candidateType: this.candidateType }];
      let outreachObject = {
        candidates: candidateIds,
        userId: this.userId,
        companyId: this.companyId,
        clientOrBuId: jobMatch.clientOrBuId
      }
      this.candidateService.sendOutreachEmail(outreachObject, (data) => {
        this.alertsAndNotificationsService.showBannerMessage(data.message, 'success');
        this.getCandidateJobMatches(null)
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }, () => {
      // do nothing
    });
  }

  

}
