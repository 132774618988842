<div class="container">
    <div class="row" *ngIf="!isLoading">
        <div class="col-xs-12 p-4">
            <form #jobLeftForm="ngForm" (ngSubmit)="jobLeftForm.valid && performJobLeft()" novalidate>
                <div class="row">
                    <!-- Candidate -->
                    <div class="row" *ngIf="context === 'candidateCard'">
                        <div class="col-xs-12 mb-3">
                            Candidate: <span class="emerald">{{jobLeftInfo.candidate.name}}</span>
                        </div>  
                    </div>

                    <div class="row" *ngIf="context =='requisitionCard'">
                        <div class="col-xs-6">
                            <div class="form-group" [ngClass]="{'has-error': (jobLeftForm.submitted && jobLeftCandidate.invalid)}">
                                <label for="candidate" class="control-label">
                                    Candidate<em>*</em>:
                                </label>  
                                <select #jobLeftCandidate="ngModel" 
                                    class="form-control" 
                                    name="jobLeftCandidate" 
                                    [(ngModel)]="jobLeftInfo.candidate"
                                    (change)="onCandidateChange()"
                                    required>
                                    <option *ngFor="let candidate of candidates" [ngValue]="candidate">
                                        {{ candidate.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Job Left date -->
                        <div class="col-xs-6">
                            <div class="form-group" [ngClass]="{'has-error': (jobLeftForm.submitted && formJobLeftDate.invalid)}">
                                <label for="jobLeftDate" class="control-label">
                                    <span>Job Left Date<em>*</em> :</span>
                                </label>
                                <div class="input-group">
                                    <div class="input-group-addon">
                                        <div class="input-group-text">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                    </div>
                                    <input #formJobLeftDate="ngModel" id="formJobLeftDate" 
                                        type="text" name="formJobLeftDate" 
                                        class="form-control" [(ngModel)]="jobLeftInfo.jobLeftDate" required>
                                </div>
                            </div>
                        </div>

                        <!-- Reason -->
                        <div class="col-xs-6">
                            <div class="form-group" [ngClass]="{'has-error': (jobLeftForm.submitted && jobleftReason.invalid)}">
                                <label for="reason" class="control-label">
                                    Reason<em>*</em>:
                                </label>  
                                <select 
                                    #jobleftReason="ngModel"
                                    class="form-control" 
                                    name="jobleftReason" 
                                    [(ngModel)]="jobLeftInfo.reasonCodeDTO"
                                    required>
                                    <option *ngFor="let reason of reasonCodes" [ngValue]="reason">
                                        {{ reason.reason }}
                                      </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- Other job matches of candidate -->
                    <div class="row" *ngIf="candidateJobDetails && candidateJobDetails.jobMatchDetails.length > 0 && !loadingCandidateJobDetails">
                        <div class="col-xs-12">
                            <div class="panel panel-info">
                                <div class="panel-heading">
                                    Current Job Matches of Candidate
                                </div>
                                <div class="panel-body p-0"  style="max-height: 300px; overflow-y: auto;">
                                    <table class="table table-bordered table-responsive mb-0">
                                        <tbody>
                                            <tr>
                                                <th>Req #</th>
                                                <th>Title</th>
                                                <th>
                                                    <span *ngIf="isStaffingCompany">
                                                        Client
                                                    </span>
                                                    <span *ngIf="!isStaffingCompany">
                                                        BU
                                                    </span>
                                                </th>
                                                <th>Recruiter(s)</th>
                                                <th>Current Step</th>
                                                <th>Current State</th>
                                            </tr>
                                            <tr *ngFor="let jobMatch of candidateJobDetails.jobMatchDetails; let i = index">
                                                <td>
                                                    <span>{{jobMatch.requisitionNumber}}</span>
                                                </td>
                                                <td>
                                                    <span>{{jobMatch.title}}</span>
                                                </td>
                                                <td>
                                                    <span>{{jobMatch.clientOrBuOrCorporateName}}</span>
                                                </td>
                                                <td>
                                                    <span class="d-block" *ngFor="let recruiterName of jobMatch.recruiterNames; let i = index">
                                                        {{recruiterName}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>{{jobMatch.currentStep}}</span>
                                                </td>
                                                <td>
                                                    <span>{{jobMatch.currentState}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Loading job matches -->
                    <div class="row" *ngIf="candidateJobDetails && candidateJobDetails.jobMatchDetails.length > 0 && loadingCandidateJobDetails" style="height:300px">
                        <div class="col-xs-12 loading-spinner text-center">
                            <span>Loading</span>
                            <span class="fa fa-spinner fa-spin"></span>
                        </div>
                    </div>

                    <!-- Options -->
                    <div class="row" *ngIf="!loadingCandidateJobDetails">
                        <!-- Opening count change for open requisition-->
                        <div class="col-xs-6" *ngIf="candidateJobDetails && candidateJobDetails.requisitionOpen && !candidateJobDetails.hideOpeningsOptions">
                            <div class="form-group">
                                <div class="panel panel-info">
                                    <div class="panel-heading">Openings</div>

                                    <div class="panel-body">
                                        <div class="radio">
                                            <input id="reduceJobOpeningLeft" type="radio" name="reduceJobOpening" [value]="true"
                                                [(ngModel)]="jobLeftInfo.reduceJobOpening">
                                            <label for="reduceJobOpeningLeft" class="cursor-pointer">
                                                <span *ngIf="candidateJobDetails.openingsLeft !== 1">
                                                    Reduce the # of Job Opening(s) of Open Requisition <b>{{ candidateJobDetails.requisitionNumber }}</b>
                                                    with title <b>{{ candidateJobDetails.title }}</b> by 1
                                                </span>
                                                <span *ngIf="candidateJobDetails.openingsLeft === 1">
                                                    Cancel the Open Requisition <b>{{ candidateJobDetails.requisitionNumber }}</b>
                                                    with title <b>{{ candidateJobDetails.title }}</b> since it has only 1 Opening
                                                </span>
                                            </label>
                                        </div>
                                    
                                        <div class="radio">
                                            <input id="doNotReduceJobOpening" type="radio" name="reduceJobOpening" [value]="false"
                                                [(ngModel)]="jobLeftInfo.reduceJobOpening">
                                            <label for="doNotReduceJobOpening" class="cursor-pointer">
                                                I plan to Fill the opening of the Open Requisition <b>{{ candidateJobDetails.requisitionNumber }}</b>
                                                with title <b>{{ candidateJobDetails.title }}</b> with another candidate
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- Options for Hire again -->
                        <div class="col-xs-6">
                            <div class="panel-body">
                                <div class="radio">
                                    <input type="radio" name="hireAgain" id="hireBasedOnCompanyPolicy" value="HIRE_ON_COMPANY_POLICY"
                                        [(ngModel)]="jobLeftInfo.hireAgain" (change)="doNotHireChange()">
                                    <label for="hireBasedOnCompanyPolicy">
                                        Evaluate/hire again based on Company policy of {{ candidateJobDetails.doNotHireForMonths }} months
                                    </label>
                                </div>
                            
                                <div class="radio">
                                    <input type="radio" name="hireAgain" id="hireOverridingCompanyPolicy" value="HIRE_OVERRIDING_COMPANY_POLICY"
                                        [(ngModel)]="jobLeftInfo.hireAgain" (change)="doNotHireChange()">
                                    <label for="hireOverridingCompanyPolicy">
                                        Evaluate/hire again as soon as possible overriding company policy
                                    </label>
                                </div>
                            
                                <div class="radio">
                                    <input type="radio" name="hireAgain" id="joinAnotherRoleWithinCompany" value="JOIN_ANOTHER_ROLE_IN_COMPANY"
                                        [(ngModel)]="jobLeftInfo.hireAgain" (change)="doNotHireChange()">
                                    <label for="joinAnotherRoleWithinCompany">
                                        Joining another role within the company
                                        <span *ngIf="candidateJobDetails && candidateJobDetails.isFilledInOtherRequisition" style="font-size: 13px;">
                                            (<b>{{ candidateJobDetails.filledInRequisition.title }}</b> with Req # <b>{{
                                                candidateJobDetails.filledInRequisition.requisitionNumber }}</b> at
                                            <b>{{ candidateJobDetails.filledInRequisition.clientOrBuOrCorporateName }}</b>)
                                        </span>
                                    </label>
                                </div>
                            
                                <div class="radio">
                                    <input type="radio" name="hireAgain" id="doNotHireAgain" value="DO_NOT_HIRE_AGAIN"
                                        [(ngModel)]="jobLeftInfo.hireAgain" (change)="doNotHireChange()">
                                    <label for="doNotHireAgain" class="text-danger">
                                        Do not evaluate/hire again
                                        <span *ngIf="isStaffingCompany"> at</span>
                                    </label>
                                </div>
                            
                                <!-- Do not hire options for vendor candidate -->
                                <div class="form-group ml-5" *ngIf="isStaffingCompany">
                                    <div class="radio">
                                        <input type="radio" name="doNotHireAt" id="doNotHireAtClient" [value]="true"
                                            [(ngModel)]="jobLeftInfo.doNotHireAtClient">
                                        <label for="doNotHireAtClient">
                                            {{ candidateJobDetails.clientOrBu.name }}
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" name="doNotHireAt" id="doNotHireAtVendor" [value]="false"
                                            [(ngModel)]="jobLeftInfo.doNotHireAtClient">
                                        <label for="doNotHireAtVendor">
                                            {{ companyName }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Note -->
                    <div class="row" *ngIf="!loadingCandidateJobDetails">
                        <div class="col-xs-12">
                          <div class="form-group" [ngClass]="{'has-error': jobLeftForm.submitted && jobLeftName.invalid}">
                            <label>Notes <em>*</em></label>
                            <div>
                              <textarea 
                                #jobLeftName="ngModel"
                                name="note"
                                class="form-control"
                                style="height: 100px; width: 100%; resize: none;"
                                maxlength="503"
                                [(ngModel)]="jobLeftInfo.note"
                                required
                                (focus)="jobLeftName.focused = true"
                                (blur)="jobLeftName.focused = false"
                                (ngModelChange)="showExternalNote && copyNote && onInternalNoteChanged()">
                              </textarea>
                            </div>
                            <div class="error error-gap" *ngIf="jobLeftForm.submitted && jobLeftName.invalid">
                              <small *ngIf="jobLeftName.errors?.required">
                                <em>*</em> Note is required
                              </small>
                            </div>
                          </div>
                          
                          <app-external-notes
                            *ngIf="showExternalNote"
                            [form]="jobLeftForm"
                            [note]="jobLeftInfo.note"
                            [copyNote]="copyNote"
                            [externalNote]="jobLeftInfo.externalNote"
                            (onExternalNoteChange)="onExternalNoteChange($event)"
                            (onCopyNoteChange)="onCopyNoteChange($event)">
                          </app-external-notes>
                        </div>
                      </div>                      
                </div>

                <div class="row">
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button class="btn btn-danger mr-2" (click)="cancelCallback.emit()">Cancel</button>
                            <button type="submit" class="btn btn-success" [disabled]="savingFlag">
                                <span *ngIf="!savingFlag">Job Left</span>
                                <span *ngIf="savingFlag">Job Left..</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Loading -->
    <div class="row" *ngIf="isLoading" style="height:300px">
        <div class="col-xs-12 loading-spinner text-center">
            <span class="pr-2">Loading</span>
            <span class="fa fa-spinner fa-spin"></span>
        </div>
    </div>
</div>