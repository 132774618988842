import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';

@Component({
  selector: 'app-manage-master-lists-page',
  templateUrl: './manage-master-lists-page.component.html',
  styleUrls: ['./manage-master-lists-page.component.css']
})
export class ManageMasterListsPageComponent implements OnInit {

  @ViewChild('skillsTemplate', {static: true}) skillsTemplateRef: TemplateRef<any>;
  @ViewChild('degreesTemplate', {static: true}) degreesTemplateRef: TemplateRef<any>; 
  degreesEntities:any;
  fieldOfStudyEntities: any;
  rolesEntities: any;
  certificatesEntities: any;
  jdActions: any;
  actionWordsEntities: any;
  degreeEntitiesCount: number = 0;
  fieldOfStudyCount: number = 0;
  rolesCount: number = 0;
  certificatesCount: number = 0;
  jdActionsCount: number = 0;
  actionsWordCount: number = 0;
  entityTabs: any[] = [];

  isDegreesEntitiesLoading:boolean = true;
  isFieldOfStudyEntitiesLoading: boolean = true;
  isRolesLoading: boolean = true;
  isCertificatesLoading: boolean = true;
  isJdActionsLoading: boolean = true;
  isActionWordLoading: boolean = true;

  constructor(
    @Inject('$state') private $state: any,
    private skillsService: ManageSkillsService,
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { }

  ngOnInit() {
    this.entityTabs = [
      { id: 'degree', title: 'Degrees', content: this.degreesTemplateRef, active: true},
      { id: 'fieldOfStudy', title: 'Field of Study', content: this.degreesTemplateRef, active: false},
      { id: 'role', title: 'Roles', content: this.degreesTemplateRef, active: false},
      { id: 'certificates', title: 'Certificates', content: this.degreesTemplateRef, active: false},
      { id: 'jdActions', title: 'JD Action Words', content: this.degreesTemplateRef, active: false},
      { id: 'actionWords', title: 'Unknown Skills/Action Words', content: this.degreesTemplateRef, active: false}
    ];
    this.getDegreesEntities();
    this.getFieldOfStudyEntities();
    this.getRoles();
    this.getCertificates();
    this.getJdActions();
    this.getActionWords();
  }

  getDegreesEntities() {
    this.isDegreesEntitiesLoading = true;
    let degreeFilerObj = {
      searchDegree: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllDegreesEntityLists(degreeFilerObj, (data) => {
      this.degreesEntities = data;
      this.degreeEntitiesCount = this.degreesEntities.allEntities.length;
      this.setEntitiesCount('degree', 'Degrees', this.degreeEntitiesCount);
      this.isDegreesEntitiesLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isDegreesEntitiesLoading = false;
    })
  }

  getFieldOfStudyEntities() {
    this.isFieldOfStudyEntitiesLoading = true;
    let fieldOfStudyObj = {
      searchFieldOfStudy: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllFieldOfStudyLists(fieldOfStudyObj, (data) => {
      this.fieldOfStudyEntities = data;
      this.fieldOfStudyCount = this.fieldOfStudyEntities.allEntities.length;
      this.setEntitiesCount('fieldOfStudy', 'Field of Study', this.fieldOfStudyCount);
      this.isFieldOfStudyEntitiesLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isFieldOfStudyEntitiesLoading = false;
    });
  }

  getRoles() {
    this.isRolesLoading = true;
    let roleObj = {
      searchRole: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCandidateRolesLists(roleObj, (data) => {
      this.rolesEntities = data;
      this.rolesCount = this.rolesEntities.allEntities.length;
      this.setEntitiesCount('role', 'Roles', this.rolesCount);
      this.isRolesLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isRolesLoading = false;
    });
  }

  getCertificates() {
    this.isCertificatesLoading = true;
    let certificatesObj = {
      searchName: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCertificationsLists(certificatesObj, (data) => {
      this.certificatesEntities = data;
      this.certificatesCount = this.certificatesEntities.allEntities.length;
      this.setEntitiesCount('certificates', 'Certificates', this.certificatesCount);
      this.isCertificatesLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isCertificatesLoading = false;
    });
  }

  getJdActions() {
    this.isJdActionsLoading = true;
    let jdActionsObj = {
      searchName: '',
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllJdActionsLists(jdActionsObj, (data) => {
      this.jdActions = data;
      this.jdActionsCount = this.jdActions.allEntities.length;
      this.setEntitiesCount('jdActions', 'JD Action Words', this.jdActionsCount);
      this.isJdActionsLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isJdActionsLoading = false;
    });
  }

  getActionWords() {
    this.isActionWordLoading = true;

    let actionWordObj = {
      sortColumns: '',
      searchWords: '',
      resolved: null,
      recognitionStatus: null,
      ignore: null,
      sortDir: '',
    }

    this.skillsService.getAllActionWords(actionWordObj, (data) => {
      if (data.unknownMasterList.length > 0) {
        data.unknownMasterList.forEach(actionWord => {
            actionWord.displayRecognitionStatus = actionWord.recognitionStatus ? this.formatSnakeCaseToTitle(actionWord.recognitionStatus) : null;
        });
      }
      this.actionWordsEntities = data;
      this.actionWordsEntities.filteredEntities = data.unknownMasterList;
      this.actionWordsEntities.allEntities = data.unknownMasterList;
      this.actionsWordCount = this.actionWordsEntities.allEntities.length;
      this.setEntitiesCount('actionWords', 'Unknown Skills/Action Words', this.actionsWordCount);
      this.isActionWordLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isActionWordLoading = false;
    });
  }

  formatSnakeCaseToTitle(text: string): string {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
  }

  redirectTo(url) {
    this.$state.go(url, null, { reload: true });
  }

  refreshDegreesCallback(event) {
    if(event) {
      this.degreeEntitiesCount = event;
      this.setEntitiesCount('degree', 'Degrees', this.degreeEntitiesCount);
    } else {
      this.getDegreesEntities();
    }
  }

  refreshFieldOfStudyCallback(event) {
    if(event) {
      this.fieldOfStudyCount = event;
      this.setEntitiesCount('fieldOfStudy', 'Field of Study', this.fieldOfStudyCount);
    } else {
      this.getFieldOfStudyEntities();
    }
  }

  refreshRolesCallback(event) {
    if(event) {
      this.rolesCount = event;
      this.setEntitiesCount('role', 'Roles', this.rolesCount);
    } else {
      this.getRoles();
    }
  }

  refreshCertificatesCallback(event) {
    if(event) {
      this.certificatesCount = event;
      this.setEntitiesCount('certificates', 'Certificates', this.certificatesCount);
    } else {
      this.getCertificates();
    }
  }

  refreshJdActionsCallback(event) {
    if(event) {
      this.jdActionsCount = event;
      this.setEntitiesCount('jdActions', 'JD Action Words', this.jdActionsCount);
    } else {
      this.getJdActions();
    }
  }

  refreshActionWordCallback(event) {
    if(event || event == 0) {
      // this.actionsWordCount = event;
      this.setEntitiesCount('actionWords', 'Unknown Skills/Action Words', this.actionsWordCount);
    } else {
      this.getActionWords();
    }
  }

  setEntitiesCount(type, heading, count) {
    this.entityTabs.forEach(entity => {
      if (entity.id === type) {
        entity.title = `${heading} (${count})`
      }
    });
  }
}
