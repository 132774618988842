import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-outreach-page',
  templateUrl: './candidate-outreach-page.component.html',
  styleUrls: ['./candidate-outreach-page.component.css']
})
export class CandidateOutreachPageComponent implements OnInit {

  @Input() $transition$: any;

  stateParams: any;
  outreachId: any = "";

  constructor(
    @Inject('$state') public $state: any
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.outreachId = this.stateParams.outreachId;
  }

}
