import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Config, DataTables } from 'datatables.net';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { JobService } from 'src/app/core/services/job.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { JobMatchAction } from 'src/app/core/models/jobMatchAction';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { CandidateService } from 'src/app/core/services/candidate.service';
declare var bootbox: any;

@Component({
  selector: 'app-candidate-possible-job-matches',
  templateUrl: './candidate-possible-job-matches.component.html',
  styleUrls: ['./candidate-possible-job-matches.component.css']
})
export class CandidatePossibleJobMatchesComponent implements OnInit {

  @Input() candidateId;
  @Input() candidateName;
  @Input() userId;
  @Input() candidateType;
  @ViewChild('candidateJobMatchActionsTempalte', {static: true}) candidateJobMatchActionsTempalteRef: TemplateRef<any>;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtInstance: any;
  candidateActionsModal: BsModalRef;

  jobMatches: any = [];
  createJobMatchesObject: any;
  jobMatchesLimits: any = [];
  jobMatchesLimit: number;
  userName: any;
  salaryDurationOptions: any;
  jobTypeList: any;
  candidateJobMatchActionsActionType: any;
  candidateJobMatchActionsJobId: any;
  candidateJobMatchActionsLabel: any;

  loadingFlag: boolean = true;
  isUserAdmin: boolean = false;
  isStaffingCompany: boolean = false;
  isCandidateDetailsUpdatedLoading: boolean = false;
  isPocUnavailable: boolean = false;
  savingFlag: boolean = false;
  isOutreachEnabled: boolean = false;


  constructor(
    private authService: AuthService,
    private userRoleService: UserRoleService,
    private companyService: CompanyService,
    private utilityService: UtilityService,
    private jobMatchService: JobMatchService,
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private angularModalService: AngularModalService,
    private bsModalService: BsModalService,
    private candidateService: CandidateService,
    @Inject('sharedAjsService') private sharedAjsService: any,
    @Inject('$state') private $state: any,
    @Inject('$rootScope') private $rootScope: any,
    @Inject('jobMatchActionsService') private jobMatchActionsService: any
  ) { }

  ngOnInit() {
    this.jobMatchesLimits = [
      { name: '5', value: 5 },
      { name: '10', value: 10 },
      { name: 'All', value: -1 }
    ];
    this.jobMatchesLimit = this.jobMatchesLimits[0].value;

    this._setDataTable();
    this.userName = this.authService.getUserDetails().firstname + ' ' + this.authService.getUserDetails().lastname;
    this.isUserAdmin = (this.userRoleService.isLoggedInUserSuperUserOr4dot5Admin() || this.userRoleService.isLoggedInUserCompanyAdmin()) ? true : false;
    this.isStaffingCompany = this.companyService.isStaffingCompany();
    //get the candidate job matches
    this.getPossibleJobMatchesOfCandidate(null);
    this.getSalaryDurationOptions();
    this.getAllPreferredJobTypes();
    this._listenToJobMatchesRefresh();
  }

  _listenToJobMatchesRefresh() {
    this.sharedAjsService.receiveRefreshJobMatchesEvent().then(null, null, (jobMatch) => {
      if (this.candidateId == jobMatch.candidateId && jobMatch.jobMatchesActiveTab === 'possibleJobMatches') {
        console.log("refresh job matches table");
        this.getPossibleJobMatchesOfCandidate(jobMatch);
      }
    });
  }

  _setRecruiterDisplayName(name, type, assignedRecruiter, primaryHiringManager, vendorRecruiter) {
    let displayType = {
      name: '', displayName: '', type: type, assignedRecruiter: assignedRecruiter, primaryHiringManager: primaryHiringManager, vendorRecruiter: vendorRecruiter
    };
    displayType.name = name;
    displayType.displayName = name.length > 28 ? name.substring(0, 28) + "..." : name;
    displayType.displayName = `<span>${displayType.displayName} <sup>${type}</sup><span>`;
    return displayType;
  }

  _setRecruiters(val) {
    val.recruitersList = [];
    val.staffingCompanyRecruiterList = [];
    val.corporateCompanyRecruiterList = [];
    this.isPocUnavailable = false;
    if (val.recruitersInfo && val.recruitersInfo.length > 0) {
      val.recruitersInfo.map(recruiter => {
        recruiter.name = recruiter.firstName + " " + recruiter.lastName;
        let type = recruiter.isPrimary ? "" : "R";
        if (!recruiter.isPrimary && val.poc && val.poc.length > 0 && (recruiter.name == val.poc) && (this.isStaffingCompany || (!this.isStaffingCompany && val.sharedJob))) {
          type = "R POC";
          this.isPocUnavailable = true;
        }
        val.recruitersList.push(this._setRecruiterDisplayName(recruiter.name, type, recruiter.isPrimary, false, recruiter.vendorRecruiter));
      })
    }

    if (val.hiringManager && val.hiringManager.length > 0 && !this.isStaffingCompany) {
      let type = "HM"
      val.hiringManager.map(hiringManger => {
        if (hiringManger.isPoc && val.isPoc && val.sharedJob) {
          type = "HM POC"
          this.isPocUnavailable = true;
        }
        val.recruitersList.push(this._setRecruiterDisplayName(hiringManger.name, type, false, hiringManger.isPrimary, false));
      })
    }

    if (val.poc && val.poc.length > 0) {
      if (!this.isPocUnavailable && (val.assignedRecruiterName != val.poc)) {
        val.recruitersList.push(this._setRecruiterDisplayName(val.poc, "POC", false, false, false));
      }
    }

    val.staffingCompanyRecruiterList = val.recruitersList.filter(recruiter => (recruiter.vendorRecruiter && recruiter.type != 'POC'));
    val.corporateCompanyRecruiterList = val.recruitersList.filter(recruiter => !recruiter.vendorRecruiter);

    if (this.isStaffingCompany) {
      val.recruitersList = _.union(val.staffingCompanyRecruiterList, val.corporateCompanyRecruiterList);
    } else {
      val.recruitersList = _.union(val.corporateCompanyRecruiterList, val.staffingCompanyRecruiterList)
    }

  }

  _setDataTable() {
    this.dtInstance = {};
    this.dtOptions = {
      pagingType: 'full_numbers',
      order: [[0, 'asc']], // Sorting by first column
      info: true,
      processing: true,
      serverSide: false,
      lengthChange: false,
      columnDefs: [
        { targets: 0, type: 'locale' },
        { targets: 3, type: 'locale' }, // Req Number
        { targets: [4, 6], orderable: false } // Recruiters & Actions columns not sortable
      ]
    };
  }

  getPossibleJobMatchesOfCandidate(jobMatch) {
    this.loadingFlag = true;
    this.jobMatches = [];

    if (!jobMatch) {
      this.utilityService.showLoadingModal("Fetching Possible Job Matches");
    } else {
      this.isCandidateDetailsUpdatedLoading = true;
    }

    let filter = {
      candidateId: this.candidateId,
      companyId: this.authService.getUserDetails().company.companyId,
      responseSize: this.jobMatchesLimit
    };

    this.jobMatchService.getPossibleJobMatchesOfCandidate(filter, (data) => {
      angular.forEach(data, (val, key) => {
        val.jobMatchDate = new Date(val.jobMatchDate);
        val.disable = false;
        if (_.isNull(val.fourDotFiveIntelligentScore)) {
          val.fourDotFiveIntelligentScore = 'N/A';
        }
        if (_.isNull(val.techAssessmentScore)) {
          val.techAssessmentScore = 'N/A';
        }
        if (!_.isNull(val.jobLocation)) {
          val.jobDetailsText = this.jobService.getParsedJobLocationText(val.jobLocation, false);
        }
        if ((!val.recruiters.includes(this.userName) && !this.isUserAdmin) || val.requisitionStatus === 'CLOSED') {
          val.disable = true;
        }
        if (!_.isNull(val.assignedRecruiter)) {
          val.assignedRecruiterName = val.assignedRecruiter.firstname;
          if (!_.isNull(val.assignedRecruiter.lastname)) {
            val.assignedRecruiterName = val.assignedRecruiterName + " " + val.assignedRecruiter.lastname;
          }
          val.recruiters = val.recruiters.filter((recruiter) => {
            return recruiter !== val.assignedRecruiterName;
          });
        }
        this._setRecruiters(val);
        val.workLocationsMatchStatus = this.jobMatchService.getJobAndCandidateWorkLocationsMatchStatus(val.jobWorkLocations, val.candidateWorkLocations);
        this.jobMatches.push(val);
      });
      this.utilityService.hideLoadingModal();
      this.loadingFlag = false;
      this.isCandidateDetailsUpdatedLoading = false;
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getSalaryDurationOptions() {
    this.jobService.getSalaryDurationOptions((data) => {
      this.salaryDurationOptions = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getAllPreferredJobTypes() {
    this.jobService.getAllJobTypes((data) => {
      this.jobTypeList = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  goToWorkflow(jobMatch, isDisabled) {
    if (!isDisabled) {
      this.$state.go('missioncontrol.candidateJobMatchesWorkflow', {
        jobId: jobMatch.jobId
      });
    }
  }

  goToJobProfile(jobId, isDisabled) {
    let actionType = 'jobProfile';
    if (!isDisabled) {
      this._openViewModal(actionType, jobId);
    }
  }

  closeCandidateJobMatchActionsModal() {
    this.candidateActionsModal.hide();
  }

  _openViewModal(actionType, jobId) {
    let label = "Job Profile";
    let windowTopClass = "four-dot-five-modal-medium";
    this.candidateJobMatchActionsActionType = actionType;
    this.candidateJobMatchActionsJobId = jobId;
    this.candidateJobMatchActionsLabel = "Job Profile";
    if (actionType == "candidateJobComparison") {
      this.candidateJobMatchActionsLabel = "Candidate Job Comparison";
    }
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = `custom-ngx-modal custom-modal-xl custom-candidate-job-match-modal`;
    const modalData: any = {};
    config.initialState = modalData;
    this.candidateActionsModal = this.bsModalService.show(this.candidateJobMatchActionsTempalteRef, config);
  }

  onAssociateToJobClick(jobMatch) {
    this.createJobMatchesObject = {
      companyId: this.authService.getUserDetails().company.companyId,
      requisitionId: jobMatch.jobId,
      candidateIds: [],
      isAddCandidate: false,
      isAssociateCandidate: true,
      overrideNoteDetails: []
    };
    this.sharedAjsService.onAssociateToJobClick(jobMatch, this.createJobMatchesObject, this.candidateName, () => {
      // this.candidateService.getOutreachEmailEnabledStatus((data) => {
      //     this.isOutreachEnabled = data;
      //     this._confirmAssociation(jobMatch);
      // }, (error) => {
      //     this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      // });
      this.isOutreachEnabled = true;
      this._confirmAssociation(jobMatch);
    }, () => {
      //do nothing
    });
  }

  _checkIfCandidatesInformationMatch(jobMatch) {
    let candidateInformationMatch = false;

    if (jobMatch.candidateAdditionalDetailsCard &&
      (!jobMatch.jobType || jobMatch.jobType === "" || jobMatch.candidateAdditionalDetailsCard.preferredJobIcon === 'GREEN') &&
      (!jobMatch.candidateAdditionalDetailsCard.requisitionSalary || jobMatch.candidateAdditionalDetailsCard.salaryIcon === 'GREEN') &&
      (!jobMatch.candidateAdditionalDetailsCard.requisitionSponsorships || jobMatch.candidateAdditionalDetailsCard.requisitionSponsorships.length === 0 || jobMatch.candidateAdditionalDetailsCard.sponsorshipIcon === 'GREEN') &&
      (!jobMatch.jobWorkLocations || jobMatch.jobWorkLocations.length === 0 || (jobMatch.workLocationsMatchStatus && jobMatch.workLocationsMatchStatus === 'match'))
    ) {
      candidateInformationMatch = true;
    }
    return candidateInformationMatch;
  }

  _setMessageCandidateInformation(jobMatch) {
    let messageOptions = [];

    if (jobMatch.candidateAdditionalDetailsCard) {
      if (jobMatch.jobType && jobMatch.jobType != "" && jobMatch.candidateAdditionalDetailsCard.preferredJobIcon != 'GREEN') {
        messageOptions.push("Preferred job types");
      }
      if (jobMatch.candidateAdditionalDetailsCard.requisitionSalary && jobMatch.candidateAdditionalDetailsCard.salaryIcon != 'GREEN') {
        messageOptions.push("Salary");
      }
      if (jobMatch.candidateAdditionalDetailsCard.requisitionSponsorships && jobMatch.candidateAdditionalDetailsCard.requisitionSponsorships.length > 0 && jobMatch.candidateAdditionalDetailsCard.sponsorshipIcon != 'GREEN') {
        messageOptions.push("Sponsorships");
      }
      if (jobMatch.jobWorkLocations && jobMatch.jobWorkLocations.length > 0 && (!jobMatch.workLocationsMatchStatus || jobMatch.workLocationsMatchStatus != 'match')) {
        messageOptions.push("Work locations");
      }
    }

    if (messageOptions.length > 0) {
      return messageOptions.toString().split(',').join(', ');
    } else {
      return "";
    }
  }

  _confirmAssociation(jobMatch) {
    let message = "<span class='d-block mb-3'>Associating candidate <span class='emerald'>'" + this.candidateName + "'</span> to the job <span class='emerald'>'" + jobMatch.title + "'</span> at <span class='emerald'>'" + jobMatch.company + ".'</span></span>";
    message = message + "Are you sure you want to continue?"
    if (this.isOutreachEnabled) {
      message = message + "<div class='checkbox-nice mt-3'><input type='checkbox' name='sendOutreachEmail' id='sendOutreachEmail' value='true'/><label for='sendOutreachEmail' class='ml-1'>Send outreach to candidate</label></div>";
    }
    if (!this._checkIfCandidatesInformationMatch(jobMatch)) {
      message = "<span class='d-block mb-3'><strong>" + this.candidateName + "</strong> '<span class='emerald'>" + this._setMessageCandidateInformation(jobMatch) + "'</span> entry does not match with the job " + "<span class='emerald'>'" + jobMatch.title + "'</span>.</span>";
      message = message + "Add it Anyway?"
      if (this.isOutreachEnabled) {
        message = message + "<div class='checkbox-nice mt-3'><input type='checkbox' name='sendOutreachEmail' id='sendOutreachEmail' value='true'/><label for='sendOutreachEmail' class='ml-1'>Send outreach to candidate</label></div>";
      }
    }
    this.alertsAndNotificationsService.showConfirm('Confirm', message, 'warning', true, () => {
      let checkbox = document.getElementById("sendOutreachEmail") as HTMLInputElement;
      let sendOutreachEmail = checkbox ? checkbox.checked : false;
      this._associateCandidateToJob(sendOutreachEmail);
    }, () => {
      this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    });
  }

  _associateCandidateToJob(sendOutreachEmail) {
    this.createJobMatchesObject.candidateIds.push(this.candidateId);
    this.savingFlag = true;
    let associatingDialog = bootbox.dialog({
      message: '<p class="text-center">Associating Candidate <i class="fa fa-spinner fa-spin"></i></p>',
      closeButton: false
    });
    this.jobMatchService.createJobMatches(this.createJobMatchesObject, (data) => {
      this.savingFlag = false;
      associatingDialog.modal('hide');
      let candidateIds = [{id: this.candidateId, candidateType: this.candidateType}];
      this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      if (sendOutreachEmail) {
        let outreachObject = {
          candidates: candidateIds,
          userId: this.userId,
          companyId: this.authService.getUserDetails().company.companyId,
          clientOrBuId: this.createJobMatchesObject.clientOrBuId
        }
        this.candidateService.sendOutreachEmail(outreachObject, (data) => {
          this.savingFlag = false;
          associatingDialog.modal('hide');
          this.alertsAndNotificationsService.showBannerMessage(data.message, 'success');
          this.alertsAndNotificationsService.showAlertWithCallBack("Success!", "Candidate associated successfully.", "success", () => {
            this.getPossibleJobMatchesOfCandidate(null);
          });
        }, (error) => {
          this.savingFlag = false;
          associatingDialog.modal('hide');
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
      } else {
        this.savingFlag = false;
        associatingDialog.modal('hide');
        this.alertsAndNotificationsService.showAlertWithCallBack("Success!", "Candidate associated successfully.", "success", () => {
          this.getPossibleJobMatchesOfCandidate(null);
        });
      }
      this.$rootScope.$emit("refreshCandidateCard", { candidateId: this.candidateId });
    }, (error) => {
      this.savingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  jobCompare(jobMatch) {
    if (jobMatch.fourDotFiveIntelligentScore !== 'N/A') {
      let actionObject = new JobMatchAction("Candidate Job Comparison", "CANDIDATE_JOB_COMPARE", true, null);
      jobMatch.jobMatchJobId = jobMatch.jobId;
      jobMatch.id = this.candidateId;
      this.jobMatchActionsService.performAction('possibleJobMatches', actionObject, jobMatch, () => {
      }, () => {
      });
    }
  }

}
