<div class="row">
    <div class="col-xs-12">
        <h3 style="height:32px; border-color: #e7ebee;" class="skills-container d-flex justify-content-between mt-0">
            <strong>
                <span class="pr-3">{{skillHeading}}</span>
                <!-- <span *ngIf="!isEditSkills" class="fas fa-edit pointer d-inline-block text-primary"
                    style="height:30px; font-size: 15px;" (click)="onEditSkill()" tooltip="Edit"
                    container="true"></span> -->
            </strong>
        </h3>
    </div>
</div>
<div class="row candidate-profile-assets">
    <!-- Skills -->
    <div *ngIf="skillType !== 'education' && skillType !== 'experience' && skillType !== 'certification'"
        class="col-xs-12 text-left skills-container">
        <div class="row">
            <div class="col-xs-12 pl-2" *ngIf="skills.length > 0">
                <div *ngFor="let skill of skills; index as i; trackBy:i" id="skill-{{skillType}}">
                    <div class="d-flex align-items-center pb-2">
                        <!-- <app-add-edit-skill-category
                            [skills]="skills"
                            [skillType]="skillType"
                            [isEditSkills]="isEditSkills"
                            [timePeriods]="timePeriods"
                            [allExistingSkills]="allExistingSkills"
                            (saveCallback)="saveCallback()"
                            (cancelCallback)="cancelCallback()"
                            (setOtherSkillsCallback)="setOtherSkillsCallback()"
                            (setSkillsAsPerCategoryCallback)="setSkillsAsPerCategoryCallback()"
                            (removeSectionCallback)="removeSection(i)"
                            [isRemoveSectionActive]="isRemoveSectionActive"
                        ></app-add-edit-skill-category> -->
                        <!-- <div *ngIf="!isEditSkills && skill.competency && skill.competency != ''" class="d-flex pl-3">
                            <div class="mr-2 font-bold" *ngIf="!skill.convertedDisplayName || skill.convertedDisplayName == ''" [ngClass]="{'text-success other-skill': skill.competency.toLowerCase() == 'other'}">{{skill.competency}}</div>
                            <div class="mr-2 font-bold" *ngIf="skill.convertedDisplayName && skill.convertedDisplayName != ''">{{skill.convertedDisplayName}}</div>
                            <div *ngIf="skill.mandatory || skill.favorite || skill.niceToHave || skill.preferred" class="pr-2">
                                <span class="pr-2 fas fa-asterisk color-red" *ngIf="skill.mandatory" tooltip="Mandatory"
                                    container="true"></span>
                                <span class="pr-2 fas fa-heart text-danger" *ngIf="skill.favorite" tooltip="Favorite"
                                    container="true"></span>
                                <span class="pr-2 fas fa-check-circle text-success" *ngIf="skill.preferred"
                                    tooltip="Preferred" container="true"></span>
                                <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="skill.niceToHave"
                                    tooltip="Nice to have" container="true"></span>
                            </div>
                            <div class="pr-2" *ngIf="skills.length - 1 > $index">
                                <span class="text-muted font-bold pt-2" tooltip="Priority" container="true">{{skill.priority ? '(OR)' : '(AND)'}}</span>
                            </div>
                        </div> -->
                        <div *ngIf="isEditSkills"
                            class="d-flex justify-content-around mt-2 candidate-skills-item pl-1 col-xs-11">
                            <input
                                #newSkillsInput
                                id="skillsInput{{skillType}}-{{i}}"
                                style="font-size: 15px;width:72%"
                                [(ngModel)]="skill.displayName"
                                [typeahead]="skillsObservable"
                                [typeaheadAsync]="true"
                                typeaheadOptionField="name"
                                class="d-inline-block mr-3"
                                placeholder="Enter Skill"
                                (typeaheadOnSelect)="onRequisitionSkillSelect($event, skill, newSkillsInput)"
                                (focus)="skill.typeAheadSelected = false"
                                container="body"
                                [typeaheadScrollable]="true"
                                [typeaheadOptionsInScrollableView]="8"
                                (ngModelChange)="onSearchSkillChange($event)"
                                (keyup.enter)="newSkill(skill.displayName, 'onEnter', newSkillsInput, skill)"
                                (keyup.tab)="skill.typeAheadSelected = false"
                                autocomplete="off"
                                [disabled]="isViewOnly"
                                [typeaheadOptionsLimit]="100"
                            >                        
                            <span class="pt-1 d-flex justify-content-end mt-2 mandatory-favorite-icon">
                                <span *ngIf="!skill.niceToHave && !skill.preferred && skill.mandatory"
                                    class="cursor-pointer color-red pr-2" 
                                    tooltip="Mandatory" container="true"
                                    (click)="updateMandatory(skill)"
                                    style="position: relative; bottom: 1px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                        width="12.5" height="20" viewBox="0 0 1200 1200">
                                        <path fill="red" d="M489.838 29.354v443.603L68.032 335.894 0 545.285l421.829 137.086-260.743 358.876 178.219 129.398L600.048 811.84l260.673 358.806 178.146-129.398-260.766-358.783L1200 545.379l-68.032-209.403-421.899 137.07V29.443H489.84z"/>
                                    </svg>
                                </span>
                                <span *ngIf="!skill.niceToHave && !skill.mandatory && !skill.preferred" class="cursor-pointer pr-2" tooltip="Mandatory" container="true" (click)="updateMandatory(skill)">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                        width="30" height="40" viewBox="0 0 238 272">
                                        <path d="M100 68.1c0 30.5-.2 37-1.3 36.6-.8-.3-9.9-3.3-20.3-6.7s-25.6-8.4-33.8-11.1-15.1-4.8-15.2-4.6c-.4.4-10.6 32.2-10.8 33.5-.1 1.2 46 16.9 64 21.9l6.1 1.6-20.5 28.1c-11.2 15.5-21.4 29.1-22.5 30.4-1.1 1.2-1.6 2.2-1 2.2.5 0 7.2 4.5 14.8 10 7.5 5.6 14.1 10.2 14.5 10.3.6.2 41.9-55.6 43.5-58.8.3-.7.7-.7 1 0 1.7 3.4 42.9 59 43.6 58.8.4-.2 6.7-4.6 14-9.9s13.8-9.8 14.6-10.1c1.1-.4 1-.9-.4-2.5-1-1.1-11-14.6-22.3-30.1l-20.5-28.2 10.5-3.2c34.5-10.7 59.5-19.3 59.3-20.5-.2-1.4-10.3-33.1-10.6-33.5-.2-.2-64.8 20.6-68.9 22.2-1.7.7-1.8-1.5-1.8-36.4V31h-36zm32-32.2c.6 1.3 1 14.1 1 34.7 0 29.1.2 32.9 1.7 34.6.9 1 2.4 1.8 3.4 1.8 1.1 0 13.9-3.9 28.6-8.6 37.1-12 37.3-12 38.3-10.5 1.9 3.2 8.3 26.2 7.4 27-.5.4-14.6 5.2-31.4 10.6s-31.5 10.4-32.7 11.2c-4.8 2.9-3.7 4.8 24.7 43.3 5.9 8 11.2 15.5 11.9 16.7 1.1 2.2.5 2.8-9.6 10.3-5.9 4.4-11.5 8-12.5 8s-9.1-10.2-21.3-27c-10.9-14.9-20.6-27.5-21.7-28.1-1.4-.8-2.5-.7-4.1.4-1.2.8-10.9 13.4-21.6 28.1C83.5 203 74.1 215 73.3 215c-1.4 0-16.5-10.3-21.1-14.3-2.7-2.5-4.7.8 19.5-32.6 17.8-24.7 19.9-28.5 17-30.9-.7-.6-13.2-5-27.7-9.7-14.6-4.8-29-9.5-32-10.5s-5.6-2.5-5.8-3.3C22.6 111 31 87 32.5 87s51.7 15.9 58.8 18.6c4.9 1.8 7.6 1.8 9.9-.3 1.7-1.5 1.8-3.9 1.8-35.3 0-24.8.3-33.9 1.2-34.8.8-.8 5.4-1.2 14-1.2 11.6 0 12.8.2 13.8 1.9z" fill="red" stroke="red" stroke-width="10"/>
                                    </svg>
                                </span>
                                <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.favorite, 'far': !skill.favorite}"
                                    (click)="skill.favorite = !skill.favorite" tooltip="Favorite" container="true">
                                </span>
                                <span *ngIf="!skill.mandatory && !skill.niceToHave"
                                    class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.preferred, 'far': !skill.preferred}"
                                    (click)="updatePreferred(skill)" tooltip="Preferred" container="true">
                                </span>
                                <span *ngIf="!skill.mandatory && !skill.preferred"
                                    class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.niceToHave, 'far': !skill.niceToHave}"
                                    (click)="updateNiceToHave(skill)" tooltip="Nice to have" container="true">
                                </span>
                                <span class="fas fa-times text-danger cursor-pointer delete-skill"
                                    tooltip="Delete Skill" (click)="deleteSkill(i)" *ngIf="!isViewOnly"></span>
                            </span>
                        </div>
                        <div *ngIf="(skills.length - 1 > i) && isEditSkills" container="true"
                            tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                            [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}"
                            class="cursor-pointer mt-2 d-flex align-items-center px-0 text-muted font-bold">
                            <span *ngIf="skill.priority && skill.priority == 'OR'" (click)="changeSkillPriority(skill, 'NL')"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                            <span *ngIf="!skill.priority || (skill.priority != 'OR' && skill.priority != 'NL')" (click)="changeSkillPriority(skill, 'OR')"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                            <span *ngIf="skill.priority && skill.priority == 'NL'" (click)="changeSkillPriority(skill, 'AND')"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(NL)</span>
                        </div>
                    </div>
                    <!-- <div *ngIf="(sections.length - 1 > i) && isEditSkills" container="true"
                        tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                        [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}"
                        class="cursor-pointer my-2 d-flex align-items-center px-0 text-muted font-bold justify-content-center">
                        <span *ngIf="sections[i].priority" (click)="sections[i].priority = !sections[i].priority"
                            [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                        <span *ngIf="!sections[i].priority" (click)="sections[i].priority = !priority"
                            [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                    </div> -->
                </div>
            </div>

            <div *ngIf="skills.length == 0">
                <strong><span class="asset-value pl-3" style="font-weight: 500;">No skills available.</span></strong>
            </div>
            <div class="col-xs-6" *ngIf="isEditSkills && !isViewOnly">
                <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill('','onAddSkill','skillsInput')"><span
                        class="fas fa-plus"></span> Add Skill</span>
            </div>
            <!-- <div class="col-xs-12 d-flex justify-content-end mt-3" *ngIf="isEditSkills && !isViewOnly">
                <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSection()"><span
                        class="fas fa-plus"></span> Add Section</span>
            </div> -->
        </div>
    </div>

    <!-- Education -->
    <div *ngIf="skillType === 'education'" class="col-xs-12 text-left skills-container">
        <div *ngIf="skills.length > 0">
            <div *ngFor="let education of skills; index as $index; trackBy:$index"
                class="d-flex align-items-center pb-2">
                <!-- <div *ngIf="!isEditSkills && education.degreeName && education.degreeName != ''" class="d-flex pl-3">
                    <div class="mr-2 font-bold">{{education.degreeName}}</div>
                    <div class="mr-1" *ngIf="education.fieldOfStudy && education.fieldOfStudy != ''">in</div>
                    <div class="mr-2 font-bold" *ngIf="education.fieldOfStudy && education.fieldOfStudy != ''">
                        {{education.fieldOfStudy}}</div>
                    <div *ngIf="education.mandatory || education.favorite || education.niceToHave || education.preferred" class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="education.mandatory" tooltip="Mandatory"
                                container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="education.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-check-circle text-success" *ngIf="education.preferred"
                            tooltip="Preferred" container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="education.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                    <div class="pr-2" *ngIf="skills.length - 1 > $index">
                        <span class="text-muted font-bold" tooltip="Priority" container="true">{{education.priority ? '(OR)' : '(AND)'}}</span>
                    </div>
                </div> -->
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-start mt-2 pl-1 col-xs-11">
                        <input 
                            #newEducationInput
                            id="educationInput-{{$index}}"
                            style="font-size: 15px;width:75%"
                            [(ngModel)]="education.displayName" 
                            [typeahead]="educationObservable"
                            [typeaheadAsync]="true"
                            typeaheadOptionField="displayName" 
                            class="d-inline-block mr-3" 
                            placeholder="Enter Education"
                            (typeaheadOnSelect)="onRequisitionSkillSelect($event, education, newEducationInput)"
                            (focus)="education.typeAheadSelected = false"
                            [typeaheadOptionsLimit]="8"
                            container="body"
                            [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="8"
                            (ngModelChange)="onSearchEducationChange($event)"
                            (keyup.enter)="newSkill(education.displayName, 'onEnter', newEducationInput, education)"
                            (keyup.tab)="education.typeAheadSelected = false"
                            autocomplete="off"
                            [disabled]="isViewOnly"
                            [typeaheadOptionsLimit]="100"
                        >
                    <span class="text-primary pt-1 d-flex justify-content-end mandatory-favorite-icon">
                        <span *ngIf="!education.niceToHave && !education.preferred && education.mandatory"
                            class="cursor-pointer color-red pr-2" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(education)" 
                            style="position: relative; bottom: 1px;">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="12.5" height="20" viewBox="0 0 1200 1200">
                                <path fill="red" d="M489.838 29.354v443.603L68.032 335.894 0 545.285l421.829 137.086-260.743 358.876 178.219 129.398L600.048 811.84l260.673 358.806 178.146-129.398-260.766-358.783L1200 545.379l-68.032-209.403-421.899 137.07V29.443H489.84z"/>
                            </svg>
                        </span>
                        <span *ngIf="!education.niceToHave && !education.mandatory && !education.preferred" class="cursor-pointer pr-2" tooltip="Mandatory" container="true" (click)="updateMandatory(education)">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="30" height="40" viewBox="0 0 238 272">
                                <path d="M100 68.1c0 30.5-.2 37-1.3 36.6-.8-.3-9.9-3.3-20.3-6.7s-25.6-8.4-33.8-11.1-15.1-4.8-15.2-4.6c-.4.4-10.6 32.2-10.8 33.5-.1 1.2 46 16.9 64 21.9l6.1 1.6-20.5 28.1c-11.2 15.5-21.4 29.1-22.5 30.4-1.1 1.2-1.6 2.2-1 2.2.5 0 7.2 4.5 14.8 10 7.5 5.6 14.1 10.2 14.5 10.3.6.2 41.9-55.6 43.5-58.8.3-.7.7-.7 1 0 1.7 3.4 42.9 59 43.6 58.8.4-.2 6.7-4.6 14-9.9s13.8-9.8 14.6-10.1c1.1-.4 1-.9-.4-2.5-1-1.1-11-14.6-22.3-30.1l-20.5-28.2 10.5-3.2c34.5-10.7 59.5-19.3 59.3-20.5-.2-1.4-10.3-33.1-10.6-33.5-.2-.2-64.8 20.6-68.9 22.2-1.7.7-1.8-1.5-1.8-36.4V31h-36zm32-32.2c.6 1.3 1 14.1 1 34.7 0 29.1.2 32.9 1.7 34.6.9 1 2.4 1.8 3.4 1.8 1.1 0 13.9-3.9 28.6-8.6 37.1-12 37.3-12 38.3-10.5 1.9 3.2 8.3 26.2 7.4 27-.5.4-14.6 5.2-31.4 10.6s-31.5 10.4-32.7 11.2c-4.8 2.9-3.7 4.8 24.7 43.3 5.9 8 11.2 15.5 11.9 16.7 1.1 2.2.5 2.8-9.6 10.3-5.9 4.4-11.5 8-12.5 8s-9.1-10.2-21.3-27c-10.9-14.9-20.6-27.5-21.7-28.1-1.4-.8-2.5-.7-4.1.4-1.2.8-10.9 13.4-21.6 28.1C83.5 203 74.1 215 73.3 215c-1.4 0-16.5-10.3-21.1-14.3-2.7-2.5-4.7.8 19.5-32.6 17.8-24.7 19.9-28.5 17-30.9-.7-.6-13.2-5-27.7-9.7-14.6-4.8-29-9.5-32-10.5s-5.6-2.5-5.8-3.3C22.6 111 31 87 32.5 87s51.7 15.9 58.8 18.6c4.9 1.8 7.6 1.8 9.9-.3 1.7-1.5 1.8-3.9 1.8-35.3 0-24.8.3-33.9 1.2-34.8.8-.8 5.4-1.2 14-1.2 11.6 0 12.8.2 13.8 1.9z" fill="red" stroke="red" stroke-width="10"/>
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.favorite, 'far': !education.favorite}"
                            (click)="education.favorite = !education.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!education.mandatory && !education.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.preferred, 'far': !education.preferred}"
                            (click)="updatePreferred(education)" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!education.mandatory && !education.preferred"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.niceToHave, 'far': !education.niceToHave}"
                            (click)="updateNiceToHave(education)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill" tooltip="Delete Education"
                            (click)="deleteSkill($index)" *ngIf="!isViewOnly"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                    class="cursor-pointer col-xs-1 d-flex align-items-center justify-content-start px-0 text-muted font-bold"
                    [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}">
                    <span *ngIf="education.priority" (click)="education.priority = !education.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                    <span *ngIf="!education.priority" (click)="education.priority = !education.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Education available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills && !isViewOnly">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill('','onAddSkill','educationInput')"><span
                    class="fas fa-plus"></span> Add Education</span>
        </div>
        <!-- <div *ngIf="isEditSkills" class="d-flex justify-content-end mb-2">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div> -->
    </div>

    <!-- Experience -->
    <div *ngIf="skillType === 'experience'" class="col-xs-12 text-left skills-container">
        <div *ngIf="skills.length > 0">
            <div *ngFor="let experience of skills; index as $index; trackBy: $index"
                class="d-flex align-items-center pb-2">
                <!-- <div *ngIf="!isEditSkills && experience.title && experience.title != ''" class="d-flex pl-3">
                    <div *ngIf="!experience.convertedDisplayName || experience.convertedDisplayName == ''" class="mr-2 font-bold">{{experience.title}}</div>
                    <div *ngIf="experience.convertedDisplayName && experience.convertedDisplayName != ''" class="mr-2 font-bold">{{experience.convertedDisplayName}}</div>
                    <div *ngIf="experience.mandatory || experience.favorite || experience.niceToHave || experience.preferred" class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="experience.mandatory" tooltip="Mandatory"
                            container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="experience.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-check-circle text-success" *ngIf="experience.preferred"
                            tooltip="Preferred" container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="experience.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                    <div class="pr-2" *ngIf="skills.length - 1 > $index">
                        <span class="text-muted font-bold" tooltip="Priority" container="true">{{experience.priority ? '(OR)' : '(AND)'}}</span>
                    </div>
                </div> -->
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-around mt-2 col-xs-11 pl-1">
                        <input 
                            #newExperienceInput
                            id="experienceInput-{{$index}}"
                            style="font-size: 15px;width:75%"
                            [(ngModel)]="experience.displayName" 
                            [typeahead]="experienceObservable"
                            [typeaheadAsync]="true"
                            typeaheadOptionField="displayName" 
                            class="d-inline-block mr-3" 
                            placeholder="Enter Experience"
                            (typeaheadOnSelect)="onRequisitionSkillSelect($event,experience, newExperienceInput)" 
                            (focus)="experience.typeAheadSelected = false"
                            [typeaheadOptionsLimit]="8"
                            container="body"
                            [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="8"
                            (ngModelChange)="onSearchExperienceChange($event)"
                            (keyup.enter)="newSkill(experience.displayName, 'onEnter', newExperienceInput, experience)"
                            (keyup.tab)="experience.typeAheadSelected = false"
                            autocomplete="off"
                            [disabled]="isViewOnly"
                            [typeaheadOptionsLimit]="100"
                        >
                    <span class="text-primary d-flex justify-content-end align-items-center mandatory-favorite-icon">
                        <span *ngIf="!experience.niceToHave && !experience.preferred && experience.mandatory"
                            class="cursor-pointer color-red pr-2" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(experience)" 
                            style="position: relative; top: 4px;">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="12.5" height="20" viewBox="0 0 1200 1200">
                                <path fill="red" d="M489.838 29.354v443.603L68.032 335.894 0 545.285l421.829 137.086-260.743 358.876 178.219 129.398L600.048 811.84l260.673 358.806 178.146-129.398-260.766-358.783L1200 545.379l-68.032-209.403-421.899 137.07V29.443H489.84z"/>
                            </svg>
                        </span>
                        <span *ngIf="!experience.niceToHave && !experience.mandatory && !experience.preferred" class="cursor-pointer pt-3 pr-2" tooltip="Mandatory" container="true" (click)="updateMandatory(experience)">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="30" height="40" viewBox="0 0 238 272">
                                <path d="M100 68.1c0 30.5-.2 37-1.3 36.6-.8-.3-9.9-3.3-20.3-6.7s-25.6-8.4-33.8-11.1-15.1-4.8-15.2-4.6c-.4.4-10.6 32.2-10.8 33.5-.1 1.2 46 16.9 64 21.9l6.1 1.6-20.5 28.1c-11.2 15.5-21.4 29.1-22.5 30.4-1.1 1.2-1.6 2.2-1 2.2.5 0 7.2 4.5 14.8 10 7.5 5.6 14.1 10.2 14.5 10.3.6.2 41.9-55.6 43.5-58.8.3-.7.7-.7 1 0 1.7 3.4 42.9 59 43.6 58.8.4-.2 6.7-4.6 14-9.9s13.8-9.8 14.6-10.1c1.1-.4 1-.9-.4-2.5-1-1.1-11-14.6-22.3-30.1l-20.5-28.2 10.5-3.2c34.5-10.7 59.5-19.3 59.3-20.5-.2-1.4-10.3-33.1-10.6-33.5-.2-.2-64.8 20.6-68.9 22.2-1.7.7-1.8-1.5-1.8-36.4V31h-36zm32-32.2c.6 1.3 1 14.1 1 34.7 0 29.1.2 32.9 1.7 34.6.9 1 2.4 1.8 3.4 1.8 1.1 0 13.9-3.9 28.6-8.6 37.1-12 37.3-12 38.3-10.5 1.9 3.2 8.3 26.2 7.4 27-.5.4-14.6 5.2-31.4 10.6s-31.5 10.4-32.7 11.2c-4.8 2.9-3.7 4.8 24.7 43.3 5.9 8 11.2 15.5 11.9 16.7 1.1 2.2.5 2.8-9.6 10.3-5.9 4.4-11.5 8-12.5 8s-9.1-10.2-21.3-27c-10.9-14.9-20.6-27.5-21.7-28.1-1.4-.8-2.5-.7-4.1.4-1.2.8-10.9 13.4-21.6 28.1C83.5 203 74.1 215 73.3 215c-1.4 0-16.5-10.3-21.1-14.3-2.7-2.5-4.7.8 19.5-32.6 17.8-24.7 19.9-28.5 17-30.9-.7-.6-13.2-5-27.7-9.7-14.6-4.8-29-9.5-32-10.5s-5.6-2.5-5.8-3.3C22.6 111 31 87 32.5 87s51.7 15.9 58.8 18.6c4.9 1.8 7.6 1.8 9.9-.3 1.7-1.5 1.8-3.9 1.8-35.3 0-24.8.3-33.9 1.2-34.8.8-.8 5.4-1.2 14-1.2 11.6 0 12.8.2 13.8 1.9z" fill="red" stroke="red" stroke-width="10"/>
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.favorite, 'far': !experience.favorite}"
                            (click)="experience.favorite = !experience.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!experience.mandatory && !experience.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.preferred, 'far': !experience.preferred}"
                            (click)="updatePreferred(experience)" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!experience.mandatory && !experience.preferred"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.niceToHave, 'far': !experience.niceToHave}"
                            (click)="updateNiceToHave(experience)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill" tooltip="Delete Experience"
                            (click)="deleteSkill($index)" *ngIf="!isViewOnly"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                    class="cursor-pointer col-xs-1 d-flex align-items-center justify-content-start px-0 text-muted font-bold"
                    [ngClass]="{'mt-2 pt-1': isEditSkills, 'mt-0': !isEditSkills}">
                    <span *ngIf="experience.priority" (click)="experience.priority = !experience.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                    <span *ngIf="!experience.priority" (click)="experience.priority = !experience.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Experience available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills && !isViewOnly">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill('','onAddSkill','experienceInput')"><span
                    class="fas fa-plus"></span> Add Experience</span>
        </div>
        <!-- <div *ngIf="isEditSkills" class="d-flex justify-content-end">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div> -->
    </div>

    <!-- Certification -->
    <div *ngIf="skillType === 'certification'" class="col-xs-12 text-left skills-container">
        <div *ngFor="let certification of skills; index as $index; trackBy:$index">
            <div *ngIf="skills.length > 0" class="d-flex align-items-center pb-2">
                <!-- <div *ngIf="!isEditSkills && certification.degreeName && certification.degreeName != ''"
                    class="d-flex pl-3">
                    <div class="mr-2 font-bold">{{certification.degreeName}}</div>
                    <div class="mr-1" *ngIf="certification.fieldOfStudy && certification.fieldOfStudy != ''">in</div>
                    <div class="mr-2 font-bold"
                        *ngIf="certification.fieldOfStudy && certification.fieldOfStudy != ''">
                        {{certification.fieldOfStudy}}</div>
                    <div *ngIf="certification.mandatory || certification.favorite || certification.niceToHave || certification.preferred"
                        class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="certification.mandatory" tooltip="Mandatory"
                            container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="certification.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-check-circle text-success" *ngIf="certification.preferred"
                            tooltip="Preferred" container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="certification.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                    <div class="pr-2" *ngIf="skills.length - 1 > $index">
                        <span class="text-muted font-bold" tooltip="Priority" container="true">{{certification.priority ? '(OR)' : '(AND)'}}</span>
                    </div>
                </div> -->
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-start mt-2 col-xs-11 pl-1">
                    <input 
                        #newCertificationInput
                        id="certificationInput-{{$index}}"
                        style="font-size: 15px;width:75%"
                        [(ngModel)]="certification.displayName" 
                        [typeahead]="certificationsObservable"
                        [typeaheadAsync]="true"
                        typeaheadOptionField="displayName" 
                        class="d-inline-block mr-3" 
                        placeholder="Enter Certification"
                        (typeaheadOnSelect)="onRequisitionSkillSelect($event,certification, newCertificationInput)" 
                        (focus)="certification.typeAheadSelected = false"
                        [typeaheadOptionsLimit]="8"
                        container="body"
                        [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="8"
                        (ngModelChange)="onSearchCertificationChange($event)"
                        (keyup.enter)="newSkill(certification.displayName, 'onEnter', newCertificationInput, certification)"
                        (keyup.tab)="certification.typeAheadSelected = false"
                        autocomplete="off"
                        [disabled]="isViewOnly"
                        [typeaheadOptionsLimit]="100"
                    >
                    <span class="text-primary pt-1 d-flex justify-content-end mandatory-favorite-icon">
                        <span *ngIf="!certification.niceToHave && !certification.preferred && certification.mandatory"
                            class="cursor-pointer color-red pr-2" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(certification)" 
                            style="position: relative; bottom: 1px;">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="12.5" height="20" viewBox="0 0 1200 1200">
                                <path fill="red" d="M489.838 29.354v443.603L68.032 335.894 0 545.285l421.829 137.086-260.743 358.876 178.219 129.398L600.048 811.84l260.673 358.806 178.146-129.398-260.766-358.783L1200 545.379l-68.032-209.403-421.899 137.07V29.443H489.84z"/>
                            </svg>
                        </span>
                        <span *ngIf="!certification.niceToHave && !certification.mandatory && !certification.preferred" class="cursor-pointer pr-2" tooltip="Mandatory" container="true" (click)="updateMandatory(certification)">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                width="30" height="40" viewBox="0 0 238 272">
                                <path d="M100 68.1c0 30.5-.2 37-1.3 36.6-.8-.3-9.9-3.3-20.3-6.7s-25.6-8.4-33.8-11.1-15.1-4.8-15.2-4.6c-.4.4-10.6 32.2-10.8 33.5-.1 1.2 46 16.9 64 21.9l6.1 1.6-20.5 28.1c-11.2 15.5-21.4 29.1-22.5 30.4-1.1 1.2-1.6 2.2-1 2.2.5 0 7.2 4.5 14.8 10 7.5 5.6 14.1 10.2 14.5 10.3.6.2 41.9-55.6 43.5-58.8.3-.7.7-.7 1 0 1.7 3.4 42.9 59 43.6 58.8.4-.2 6.7-4.6 14-9.9s13.8-9.8 14.6-10.1c1.1-.4 1-.9-.4-2.5-1-1.1-11-14.6-22.3-30.1l-20.5-28.2 10.5-3.2c34.5-10.7 59.5-19.3 59.3-20.5-.2-1.4-10.3-33.1-10.6-33.5-.2-.2-64.8 20.6-68.9 22.2-1.7.7-1.8-1.5-1.8-36.4V31h-36zm32-32.2c.6 1.3 1 14.1 1 34.7 0 29.1.2 32.9 1.7 34.6.9 1 2.4 1.8 3.4 1.8 1.1 0 13.9-3.9 28.6-8.6 37.1-12 37.3-12 38.3-10.5 1.9 3.2 8.3 26.2 7.4 27-.5.4-14.6 5.2-31.4 10.6s-31.5 10.4-32.7 11.2c-4.8 2.9-3.7 4.8 24.7 43.3 5.9 8 11.2 15.5 11.9 16.7 1.1 2.2.5 2.8-9.6 10.3-5.9 4.4-11.5 8-12.5 8s-9.1-10.2-21.3-27c-10.9-14.9-20.6-27.5-21.7-28.1-1.4-.8-2.5-.7-4.1.4-1.2.8-10.9 13.4-21.6 28.1C83.5 203 74.1 215 73.3 215c-1.4 0-16.5-10.3-21.1-14.3-2.7-2.5-4.7.8 19.5-32.6 17.8-24.7 19.9-28.5 17-30.9-.7-.6-13.2-5-27.7-9.7-14.6-4.8-29-9.5-32-10.5s-5.6-2.5-5.8-3.3C22.6 111 31 87 32.5 87s51.7 15.9 58.8 18.6c4.9 1.8 7.6 1.8 9.9-.3 1.7-1.5 1.8-3.9 1.8-35.3 0-24.8.3-33.9 1.2-34.8.8-.8 5.4-1.2 14-1.2 11.6 0 12.8.2 13.8 1.9z" fill="red" stroke="red" stroke-width="10"/>
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.favorite, 'far': !certification.favorite}"
                            (click)="certification.favorite = !certification.favorite" tooltip="Favorite"
                            container="true">
                        </span>
                        <span *ngIf="!certification.mandatory && !certification.niceToHave"
                            class="cursor-pointer text-success fa-check-circle pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.preferred, 'far': !certification.preferred}"
                            (click)="certification.preferred = !certification.preferred" tooltip="Preferred" container="true">
                        </span>
                        <span *ngIf="!certification.mandatory && !certification.preferred"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.niceToHave, 'far': !certification.niceToHave}"
                            (click)="updateNiceToHave(certification)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill"
                            tooltip="Delete Certification" (click)="deleteSkill($index)" *ngIf="!isViewOnly"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="Click to change priority"
                    class="cursor-pointer col-xs-1 mt-2 d-flex align-items-center justify-content-start px-0 text-muted font-bold">
                    <span *ngIf="certification.priority"
                        (click)="certification.priority = !certification.priority">(OR)</span>
                    <span *ngIf="!certification.priority"
                        (click)="certification.priority = !certification.priority">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Certification available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills && !isViewOnly">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill('','onAddSkill','certificationInput')"><span
                    class="fas fa-plus"></span> Add Certification</span>
        </div>
        <!-- <div *ngIf="isEditSkills" class="d-flex justify-content-end mt-4">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div> -->
    </div>
</div>