import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissionControlPageComponent } from './mission-control-page.component';
import { RoleEntityScopePageModule } from './role-entity-scope-page/role-entity-scope-page.module';
import { FormsModule } from '@angular/forms';
import { ChangePasswordPageModule } from './change-password-page/change-password-page.module';
import { DashboardPageModule } from './dashboard-page/dashboard-page.module';
import { ManageSkillsPageModule } from './manage-skills-page/manage-skills-page.module';
import { EmployeeManagementPageModule } from './employee-management-page/employee-management-page.module';
import { EmployeesPageModule } from './employees-page/employees-page.module';
import { BulkUploadPageModule } from './bulk-upload-page/bulk-upload-page.module';
import { AssessmentsManagementModule } from './assessments-management/assessments-management.module';
import { ClientOrBusPageModule } from './client-or-bus-page/client-or-bus-page.module';
import { NotificationsPageModule } from './notifications-page/notifications-page.module';
import { NotificationPreferencePageModule } from './notification-preference-page/notification-preference-page.module';
import { ManageRequisitionEntitiesPageModule } from './manage-requisition-entities-page/manage-requisition-entities-page.module';
import { ManageMasterListsPageModule } from './manage-master-lists-page/manage-master-lists-page.module';
import { CandidateJobComparisonPageModule } from './candidate-job-comparison-page/candidate-job-comparison-page.module';
import { JobProfilePageModule } from './job-profile-page/job-profile-page.module';
import { FeedbackFormsPageModule } from './feedback-forms-page/feedback-forms-page.module';
import { VendorsPageModule } from './vendors-page/vendors-page.module';
import { CandidatesPageModule } from './candidates-page/candidates-page.module';
import { CandidateOutreachPageModule } from './candidate-outreach-page/candidate-outreach-page.module';

@NgModule({
  declarations: [MissionControlPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    RoleEntityScopePageModule,
    ChangePasswordPageModule,
    DashboardPageModule,
    ManageSkillsPageModule,
    EmployeeManagementPageModule,
    EmployeesPageModule,
    BulkUploadPageModule,
    AssessmentsManagementModule,
    ClientOrBusPageModule,
    NotificationsPageModule,
    NotificationPreferencePageModule,
    ManageRequisitionEntitiesPageModule,
    ManageMasterListsPageModule,
    CandidateJobComparisonPageModule,
    JobProfilePageModule,
    FeedbackFormsPageModule,
    VendorsPageModule,
    CandidatesPageModule,
    CandidateOutreachPageModule
  ]
})
export class MissionControlPageModule { }
