import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorsPageComponent } from './vendors-page.component';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { DeleteEntityModule } from 'src/app/shared/delete-entity/delete-entity.module';
import { MomentModule } from 'ngx-moment';
import { AssociateVendorActionModalComponent } from './associate-vendor-action-modal/associate-vendor-action-modal.component';
import { AssociateVendorsModule } from './associate-vendors/associate-vendors.module';

@NgModule({
  declarations: [VendorsPageComponent, AssociateVendorActionModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    DataTablesModule,
    DeleteEntityModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    AssociateVendorsModule
  ],
  entryComponents: [VendorsPageComponent, AssociateVendorActionModalComponent],
  exports: [VendorsPageComponent, AssociateVendorActionModalComponent]
})
export class VendorsPageModule { }
