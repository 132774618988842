import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(@Inject(HttpClient) private http: HttpClient) {

  }

  _handleError(error) {
    if (!_.isUndefined(error.developerMsg) && !_.isNull(error.developerMsg) && error.developerMsg != '') {
      error.message = error.developerMsg;
    }
    return error;
  }

  cancelRequest(promise) {
    // If the promise does not contain a hook into the deferred timeout,
    // the simply ignore the cancel request.
    if (promise && promise._httpTimeout && promise._httpTimeout.resolve) {
      promise._httpTimeout.resolve('user cancellation');
    }
  }

  getObjects(url) {
    return this.http.get(url, { observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body)
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )

  }

  getObjectById(url) {
    return this.http.get(url, { observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )

  }


getObjectWithOptions(url, options:any) {
    let setOptions:object;
    setOptions["observe"] = 'response';
    setOptions["responseType"] = options.responseType;

    return this.http.get(url, setOptions).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  addObject(url, object) {

    return this.http.post(url, object, { observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )

  }

  addObjectById(url) {

    return this.http.post(url, {}, { observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  addObjectWithHeaders(url, object, headers) {

    return this.http.post(url, object, { observe: 'response', headers:headers }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  addObjectWithHeadersAndResponseType(url, object, headers) {

    return this.http.post(url, object, { observe: 'response', headers:headers, responseType: 'blob' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  putObject(url, object) {

    return this.http.put(url, object, { observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body)
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )

  }

  deleteObject(url, object:object) {
    let options:object = {
      body: object,
      observe: 'response'
    }
    return this.http.delete(url, options ).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body)
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  
  deleteObjectById(url) {
    return this.http.delete(url, {observe: 'response'}).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body)
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  uploadFile(url, object) {

    const headers = new HttpHeaders().set('Content-Type', undefined)

    return this.http.post(url, object, { 'headers': headers, observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )

  }

  downloadFile(url) {
    return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(
      shareReplay(),
      tap((response: HttpResponse<any>) => {
        if (response.status == 200) {
          return response.body
        } else {
          return this._handleError(response.body);
        }
      }, (error) => {
        error.error = this._handleError(error.error);
        return error;
      }
      )
    )
  }

  updateObject(url, object) {
    return this.http.patch(url, object, { observe: 'response' }).pipe(
      shareReplay(),
      tap(
        (response: HttpResponse<any>) => {
          if (response.status === 200) {
            return response.body;
          } else {
            return this._handleError(response.body);
          }
        },  
        (error) => {
          error.error = this._handleError(error.error);
          return error;
        }
      )
    );
  }  

}
