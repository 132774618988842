import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-send-candidate-outreach-email',
  templateUrl: './send-candidate-outreach-email.component.html',
  styleUrls: ['./send-candidate-outreach-email.component.css']
})
export class SendCandidateOutreachEmailComponent implements OnInit {

  @Input() outreachEmailId;
  @Input() sendOutreachEmail;
  @Output() sendOutreachEmailUpdated = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  isSendOutreachEmailChange() {
    this.sendOutreachEmailUpdated.emit(this.sendOutreachEmail);
  }

}
