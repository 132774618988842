import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { JobService } from 'src/app/core/services/job.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'manage-entity-location',
  templateUrl: './manage-entity-location.component.html',
  styleUrls: ['./manage-entity-location.component.css']
})
export class ManageEntityLocationComponent implements OnInit {
  @ViewChild('locationForm', null) locationForm: NgForm;

  @Input() entityType;
  @Input() entityId;
  @Input() showSave;
  @Input() isRequired;
  @Input() location;
  @Input() context;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();
  @Output() clearLocationCallback = new EventEmitter<any>();

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;

  originalLocationObject: any = null;
  geoLocations: any = [];
  temp: any;

  // flags
  isZipCodeValidFlag: boolean = true;
  doesCountyExistFlag: boolean = false;
  savingFlag: boolean = false;
  cancellingFlag: boolean = false;
  invalidZipcode: boolean;
  isLocationLoading: boolean = false;
  isLocationCleared: boolean = false;

  constructor(private alertsAndNotificationsService: AlertAndNotificationsService, private jobService: JobService,
    private candidateService: CandidateService, private authService: AuthService, private http: HttpClient) { }

  ngOnInit() {

    if (_.isNull(this.location) || _.isUndefined(this.location)) {
      this._setLocationObject();
      // create a copy of the original object
      this.originalLocationObject = _.cloneDeep(this.location);
      setTimeout(() => {this.isLocationLoading = true}, 200);
    } else {
      this.location = _.cloneDeep(this.location);
      // delete this.location.zipcode;
      // create a copy of the original object
      this.originalLocationObject = _.cloneDeep(this.location);
      //if zipCode is not present but other details are present
      if (!this.location.zipCode || !this.location.zipcode) {
        this.geoLocations.push(
          {
            city: this.location.city,
            state: this.location.state,
            county: this.location.county,
            country: this.location.country,
            countryFullName: this.location.country,
          }
        )
        setTimeout(() => {this.isLocationLoading = true}, 200);
      } else {
        this.setZipCodeDetails();
      }
    }

  }

  _setLocationObject() {
    this.location = {
      address1: null,
      address2: null,
      city: null,
      county: null,
      country: null,
      countryFullName: null,
      countryCode: null,
      region: null,
      municipality: null,
      state: null,
      zipcode: null,
      zipCode: null
    }
  }

  clearLocation() {
    this.alertsAndNotificationsService.showConfirm("Clear Location", "Are you sure you want to clear the location?", "warning", false, () => {
      this.isLocationCleared = true;
      // api call to clear the location
      this.jobService.clearJobLocation(this.entityId, (data) => {
        this.isLocationCleared = false;
        this.alertsAndNotificationsService.showBannerMessage("Location cleared successfully", "success");
        // Reset for
        this._setLocationObject();

        if (this.clearLocationCallback) {
          this.clearLocationCallback.emit();
        }
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
      });

    }, () => {
      //do nothing
    });
  }

  saveLocation() {
    if (!this.locationForm.invalid) {
      var locationObject = _.cloneDeep(this.location);
      delete locationObject.countryFullName;
      delete locationObject.zipcode;
      if (!_.isEqual(this.originalLocationObject, locationObject)) {
        this.savingFlag = true;
        if (this.entityType == 'job') {
          if (_.isNull(this.entityId) || _.isUndefined(this.entityId) || this.entityId == '') {
            locationObject.jobId = this.entityId;
            this.originalLocationObject = _.cloneDeep(locationObject);
            this.savingFlag = false;
            if (this.saveCallback) {
              this.saveCallback.emit(locationObject);
            }
          // this.jobService.updateJobLocation(locationObject, (data) => {
          //   this.originalLocationObject = _.cloneDeep(locationObject);
          //   this.savingFlag = false;
          //   this.alertsAndNotificationsService.showBannerMessage("Job's Location updated successfully.", "success");
          //   if (!_.isUndefined(this.saveCallback)) {
          //     this.saveCallback.emit(locationObject);
          //   }
          //   }, (error) => {
          //     this.savingFlag = false;
          //     this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
          //   });
          } else {
            locationObject.jobId = this.entityId;
            //delete locationObject.countryFullName;
            this.jobService.updateJobLocation(locationObject, (data) => {
              this.originalLocationObject = angular.copy(locationObject);
              this.savingFlag = false;
              this.alertsAndNotificationsService.showBannerMessage("Job's Location updated successfully.", "success");
              if (!_.isUndefined(this.saveCallback)) {
                this.saveCallback.emit(locationObject);
              }
            }, (error) => {
              this.savingFlag = false;
              this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
            });
          }
        } else if (this.entityType == 'candidate') {
          // locationObject.country = locationObject.countryFullName;
          // delete locationObject.countryFullName;
          var contactObject: any = {};
          contactObject.candidateId = this.entityId;
          contactObject.companyId = this.authService.getUserDetails().company.companyId;
          contactObject.address = locationObject;
          contactObject.address.zipcode = contactObject.address.zipCode;
          contactObject.address.region = null;
          contactObject.address.municipality = null;
          this.candidateService.addUpdateCandidateAddress(contactObject, (data) => {
            this.originalLocationObject = _.cloneDeep(locationObject);
            this.savingFlag = false;
            this.alertsAndNotificationsService.showBannerMessage("Candidate's Location updated successfully.", "success");
            if (!_.isUndefined(this.saveCallback)) {
              this.saveCallback.emit({ contactObject: contactObject });
            }
          }, (error) => {
            this.savingFlag = false;
            this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
          });
        }
      } else {
        this.alertsAndNotificationsService.showBannerMessage("Nothing to update.", "info");
      }
    }
  }

  _resetLocationObject() {
    setTimeout(() => {
      this.location.city = null;
      this.location.state = null;
      this.location.county = null;
      this.location.country = null;
      this.location.countryFullName = null;
      this.doesCountyExistFlag = false;
      if (this.saveCallback && this.entityType == 'candidateId') {
        this.saveCallback.emit(this.location);
      }
    }, 100);
  }

  cancelLocationUpdate() {
    if (!_.isUndefined(this.cancelCallback)) {
      this.cancelCallback.emit();
    }
  }

  setZipCodeDetails() {
    this.getAddressDetails(this.location.zipCode, () => {
      if (this.location.city != this.originalLocationObject.city) {
        this.location.city = this.originalLocationObject.city;
      }
    });
  }

  getZipCodeDetails() {
    // Check with console zipcode value
    if (this.locationForm.control.get('zipCode').valid) {
      this.getAddressDetails(this.location.zipCode, () => {
        // Do Nothing
      });
    } else {
      // this.geoLocations = [];
      this.doesCountyExistFlag = false;
      // this._resetLocationObject();
    }
  }

  public getJSON(zipCode): Observable<any> {
    let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + zipCode + '&key=' + this.MESSAGECONSTANTS.GEOCODING.API_KEY
    return this.http.get(url)
  }

  getAddressDetails(zipCode, successCallBack) {
    if (this.locationForm && !_.isUndefined(this.locationForm.control.get('zipCode').value) && this.locationForm.control.get('zipCode').valid) {
      this.isZipCodeValidFlag = true;
    } else {
      this.isZipCodeValidFlag = false;
    }
    let locations = [];

    if (!_.isUndefined(zipCode) && !_.isEqual(zipCode, '')) {
      let locationObjectCopy = _.cloneDeep(this.location);
      this.getJSON(zipCode).subscribe((data) => {
        this.location = locationObjectCopy;
        var createGeoLocation = (addressdetails, postalname) => {
          var addressobj: any = {};
          for (var p = 0; p < addressdetails.address_components.length; p++) {
            for (var t = 0; t < addressdetails.address_components[p].types.length; t++) {
              switch (addressdetails.address_components[p].types[t]) {
                case 'country':
                  addressobj.country = addressdetails.address_components[p].short_name;
                  addressobj.countryFullName = addressdetails.address_components[p].long_name;
                  break;
                case 'administrative_area_level_2':
                  addressobj.county = addressdetails.address_components[p].long_name;
                  break;
                case 'administrative_area_level_1':
                  addressobj.state = addressdetails.address_components[p].long_name;
                  break;
                case 'locality':
                  if (!_.isUndefined(postalname) && postalname !== '' && !_.isNull(postalname)) {
                    addressobj.city = postalname;
                  } else {
                    addressobj.city = addressdetails.address_components[p].long_name;
                  }
                  break;
                default:
                  break;
              }
            }
          }

          if (_.isUndefined(addressobj.city) || _.isUndefined(addressobj.state) || _.isUndefined(addressobj.country)) {
            for (var p = 0; p < addressdetails.address_components.length; p++) {
              for (var t = 0; t < addressdetails.address_components[p].types.length; t++) {
                switch (addressdetails.address_components[p].types[t]) {
                  case 'locality':
                    if (_.isUndefined(addressobj.state)) {
                      addressobj.state = addressdetails.address_components[p].long_name;
                    }
                    if (_.isUndefined(addressobj.country)) {
                      addressobj.country = addressdetails.address_components[p].short_name;
                      addressobj.countryFullName = addressdetails.address_components[p].long_name;
                    }
                    break;
                  case 'administrative_area_level_2':
                    if (_.isUndefined(addressobj.city)) {
                      if (!_.isUndefined(postalname) && postalname !== '') {
                        addressobj.city = postalname;
                      } else {
                        addressobj.city = addressdetails.address_components[p].long_name;
                      }
                    }
                    if (_.isUndefined(addressobj.state)) {
                      addressobj.state = addressdetails.address_components[p].long_name;
                    }
                    if (_.isUndefined(addressobj.country)) {
                      addressobj.country = addressdetails.address_components[p].short_name;
                      addressobj.countryFullName = addressdetails.address_components[p].long_name;
                    }

                    break;
                  case 'administrative_area_level_3':
                    if (_.isUndefined(addressobj.city)) {
                      if (!_.isUndefined(postalname) && postalname !== '') {
                        addressobj.city = postalname;
                      } else {
                        addressobj.city = addressdetails.address_components[p].long_name;
                      }
                    }
                    break;
                  case 'administrative_area_level_4':
                    if (_.isUndefined(addressobj.city)) {
                      if (!_.isUndefined(postalname) && postalname !== '') {
                        addressobj.city = postalname;
                      } else {
                        addressobj.city = addressdetails.address_components[p].long_name;
                      }
                    }
                    break;
                  case 'sublocality' || 'sublocality_level_1' || 'sublocality_level_5':
                    if (!_.isUndefined(postalname) && postalname !== '') {
                      addressobj.city = postalname;
                    } else {
                      addressobj.city = addressdetails.address_components[p].long_name;
                    }
                    break;
                  case 'administrative_area_level_1':
                    if (_.isUndefined(addressobj.city)) {
                      if (!_.isUndefined(postalname) && postalname !== '') {
                        addressobj.city = postalname;
                      } else {
                        addressobj.city = addressdetails.address_components[p].long_name;
                      }

                      if (_.isUndefined(addressobj.country)) {
                        addressobj.country = addressdetails.address_components[p].short_name;
                        addressobj.countryFullName = addressdetails.address_components[p].long_name;
                      }
                    }
                    break;
                }
              }
            }
          }
          return addressobj;
        };

        var checkCountyExists = (locations, company) => {
          for (var i = 0; i < locations.length; i++) {
            if (_.isEqual(locations[i].city, company.city) &&
              !_.isUndefined(locations[i].county) && (locations.county !== null) &&
              !_.isUndefined(company.county) &&
              (company.county !== null) && _.isEqual(locations[i].county, company.county)) {
              return true;
            }
          }
          return false;
        };

        if (data.status === 'OK') {
          for (var i = 0; i < data.results.length; i++) {
            if (!_.isUndefined(data.results[i].postcode_localities) && (data.results[i].postcode_localities.length > 0)) {
              for (var k = 0; k < data.results[i].postcode_localities.length; k++) {
                if ((data.results[i].types[0] === 'postal_code')) {
                  console.log('entered zipCode is a postal code');
                  var location = createGeoLocation(data.results[i], data.results[i].postcode_localities[k]);
                  locations.push(_.cloneDeep(location));
                } else {
                  this.isZipCodeValidFlag = false;
                  console.log('entered zipCode is not a postal code');
                }
              }
            } else {
              if ((data.results[i].types[0] === 'postal_code')) {
                console.log('entered zipCode is a postal code');
                var location = createGeoLocation(data.results[i], null);
                locations.push(_.cloneDeep(location));
              } else {
                this.isZipCodeValidFlag = false;
                console.log('entered zipCode is not a postal code');
              }
            }
          }

          this.temp = locations;
          if (locations.length) {
            this.temp.city = locations[0].city;
            this.temp.state = locations[0].state;
            this.temp.county = (_.isUndefined(locations[0].county)) ? null : locations[0].county;
            if (!_.isUndefined(locations[0].county) && (locations[0].county !== null)) {
              this.doesCountyExistFlag = true;
            } else {
              this.doesCountyExistFlag = false;
            }
            this.temp.country = locations[0].country;
            this.temp.countryFullName = locations[0].countryFullName;
          }

          if (locations.length) {
            this.isZipCodeValidFlag = true;
            this.location.city = locations[0].city;
            this.location.state = locations[0].state;
            this.location.county = _.isUndefined(locations[0].county) ? null : locations[0].county;
            this.location.country = locations[0].country;
            this.location.countryFullName = locations[0].countryFullName;
            this.location.city = locations[0].city;
          } else {
            this._resetLocationObject();
          }
        } else if (data.status === 'ZERO_RESULTS') {
          this.isZipCodeValidFlag = false;
          this._resetLocationObject();

        }

        this.geoLocations = locations;
        if (!this.geoLocations.length) {
          this.isZipCodeValidFlag = false;
          this._resetLocationObject();
        }

        this.isLocationLoading = true;
        if (this.saveCallback && this.entityType == 'candidateId') {
          this.saveCallback.emit(this.location);
        }
        if(successCallBack) {
          successCallBack();
        }
      }, (error) => {
        this.invalidZipcode = true;
        this.doesCountyExistFlag = false;
        this.isLocationLoading = true;
      });
    } else {
      this.geoLocations = [];
      this.doesCountyExistFlag = false;
      this._resetLocationObject();
    }
  }

  changeLocationDetails() {
    if (this.saveCallback && this.entityType == 'candidateId') {
      this.saveCallback.emit(this.location);
    }
  }

}
