<div class="row">
    <div class="col-xs-12">
        <div class="main-box clearfix" style="padding-top: 10px;">
            <div class="main-box-body clearfix">
                <div class="row" *ngIf="isGettingVendorsInProgress">
                    <div class="col-xs-12 no_candidates_found text-center">
                        <span>Loading</span>
                        <span class="fa fa-spinner fa-spin"></span>
                    </div>
                </div>
                <div class="row" *ngIf="!isGettingVendorsInProgress">
                    <div class="col-xs-12">
                        <div class="row associate-vendors-search-container">
                            <input name="searchVendorContainer" type="text" placeholder="Search Vendors"
                                [(ngModel)]="searchTerm" max="30" class="text-center filter-style inputBoxBorder">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!isGettingVendorsInProgress">
                    <div class="col-xs-12">
                        <div class="row" class="associate-vendors-table-header-row">
                            <div class="col-xs-1 associate-vendors-table-header-text pl-0">
                                Select
                            </div>
                            <div class="col-xs-11">
                                <div class="col-xs-3 associate-vendors-table-header-text pl-0">
                                    Name
                                </div>
                                <div class="col-xs-3 associate-vendors-table-header-text pl-0">
                                    Contact Name
                                </div>
                                <div class="col-xs-3 associate-vendors-table-header-text pl-0">
                                    Phone Number
                                </div>
                                <div class="col-xs-3 associate-vendors-table-header-text pl-0">
                                    Email
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!isGettingVendorsInProgress" style="height:300px; overflow-y: auto">
                    <div class="col-xs-12" *ngFor="let vendor of vendors | vendorFilter:searchTerm">
                        <div class="row associate-vendors-table-row"
                            [ngClass]="{'associate-vendors-disabled-bg': vendor.isDisabled, 'green-bg': vendor.selectedFlag}">

                            <div class="col-xs-1 text-overflow-ellipsis">
                                <div class="pl-2">
                                    <input type="checkbox" [(ngModel)]="vendor.selectedFlag"
                                        [disabled]="vendor.isDisabled" (change)="vendorToAssociateToggle(vendor)" />
                                </div>
                            </div>
                            <div class="col-xs-11">
                                <div class="col-xs-3 text-overflow-ellipsis" [attr.title]="vendor.name">
                                    {{ vendor.name }}
                                </div>
                                <div class="col-xs-3 text-overflow-ellipsis" [attr.title]="vendor.contactName">
                                    {{ vendor.contactName }}
                                </div>
                                <div class="col-xs-3 text-overflow-ellipsis pl-1"
                                    [attr.title]="vendor.contactList[0]?.workphone">
                                    {{ vendor.contactList[0]?.workphone }}
                                </div>
                                <div class="col-xs-3 text-overflow-ellipsis pl-2"
                                    [attr.title]="vendor.contactList[0]?.email">
                                    {{ vendor.contactList[0]?.email }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" *ngIf="!isGettingVendorsInProgress && vendors.length == 0">
                    No Vendors Available.
                </div>
                <div class="row">
                    <div class="col-xs-12 clearfix">
                        <div class="pull-right">
                            <button type="button" class="btn btn-success" style="margin-top:20px"
                                (click)="associateVendors()"
                                [disabled]="(vendorsToAssociate.length == 0) || associatingVendorsInProgress">
                                <span *ngIf="!associatingVendorsInProgress">Associate Selected Vendors</span>
                                <span *ngIf="associatingVendorsInProgress">Associating Vendors ...</span>
                            </button>
                            <button type="button" class="btn btn-danger ml-2" style="margin-top:20px"
                                (click)="cancel()">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>