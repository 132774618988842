import { Directive, Input, Output, ElementRef, Injector, SimpleChanges, EventEmitter } from '@angular/core';
import { UpgradeComponent } from "@angular/upgrade/static";

// This Angular directive will act as an interface to the "upgraded" AngularJS component
@Directive({ selector: 'candidate-job-match-profile' })
export class CandidateJobMatchProfileComponentWrapper extends UpgradeComponent {
    // The names of the input and output properties here must match the names of the
    // `<` and `&` bindings in the AngularJS component that is being wrapped
    @Input() jobMatchId: any;
    @Input() jobId: any;
    @Input() candidateId: any;
    @Input() feedbackUserIdsList: any;
    @Input() context: any;
    @Input() candidateType: any;
    @Input() documentDtoMap: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        // We must pass the name of the directive as used by AngularJS to the super
        super('candidateJobMatchProfile', elementRef, injector);
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }
    ngDoCheck() { super.ngDoCheck(); }
    ngOnDestroy() { super.ngOnDestroy(); }
}