import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  urlConstants: any = {
    GET_ALL_JOB_TYPES: 'api/requisition/jobtypes',
    GET_ALL_SPONSORSHIPS: 'api/requisition/sponsorshiptypes',
    GET_RECRUITERS: 'api/requisition/getrecruiterlist',
    GET_LICENSE_PREFERENCE: 'api/requisition/getlicensepreferences',
    GET_JOB: 'api/requisition/findjob',
    SAVE_JOB: 'api/requisition/savejob',
    RUN_JOB_FOR_SCORING: 'api/requisition/runjobforscoring',
    SET_JOB_STATUS: 'api/requisition/setjobstatus',
    GET_CANDIDATE_COUNT: 'api/requisition/getcandidatecountbyreqtransaction',
    CREATE_JOB_DETAILS: 'api/job/createjob',
    UPDATE_JOB_DETAILS: 'api/job/updatejob',
    GET_ALL_STATUS_TYPES: 'api/job/statustypes',
    SEARCH_BY_REQNUMS: 'api/job/searchbyreqnum',
    GET_ROLES: 'api/job/getroles',
    SEARCH_BY_TEXT_JOBS: 'api/job/searchjob',
    GET_JOB_PROFILE: 'api/job/jobdetails',
    GET_CANDIDATES_BY_REQ_TRANSACTION: 'api/requisition/getcandidatesbyreqtransaction',
    GET_ACTIVITY_TYPES: 'api/job/activitytypes',
    GET_ACTIVITY_TYPES_FOR_FILTER: 'api/job/activitytypesforfilter',
    GET_ENABLED_WORKFLOW_STEPS: 'api/job/enabledsteps',
    GET_DASHBOARD_JOB_COUNT: 'api/job/dashboardreqcount',
    MULTI_CANDIDATE_SINGLE_JOB_COMPARISON: 'api/job/multiplecandidateprofilesinglejobcomparison',
    FIND_MATCHING_CANDIDATES_FOR_JOB: 'api/candidate/findcandidatesforrequisition',
    GET_ASSESSMENT_ACCESS_TIME: 'api/assessment/getaccesstime',
    UPDATE_JOB_4DOT5_SCORE: 'api/job/updatefourdotfiveintelligencescore',
    UPDATE_JOB_LOCATION: 'api/job/updatelocation',
    GET_RESUMES_FROM_JOB_BOARDS: 'api/job/getMoreJBCandidates',
    GET_REQUISITION_CARD: 'api/requisition/getRequisitionCard',
    GET_JOB_BOARD_PARAMETERS: 'api/requisition/getjobboardparameters',
    GET_RADIUS_AND_RECENCY_OPTIONS: 'api/requisition/getradiusandrecencyoptions',
    DELETE_JOB: 'api/job/delete',
    GET_SEARCH_INTERFACE: 'api/job/getsearchinterface',
    GET_GET_MORE_RESUMES_JOB_STATUS: 'api/job/isgetmoreresumesinprogress',
    SAVE_DAXTRA_PARAMETERS: 'api/job/savedaxtraparameters',
    GET_JOB_SEARCH_HISTORY: 'api/job/getjobboardsearchhistory',
    GET_CANDIDATE_COUNT_BY_JOB_ID: 'api/job/getcandidatecountbyreqid',
    GET_CANDIDATE_JOB_MATCH_RESULTS: 'api/job/getcandidatejobmatchinfo',
    REMOVE_DAXTRA_PARAMETERS: 'api/job/removedaxtraparameters',
    CHECK_JOB_BOARDS_SEARCH_AVAILABILITY: 'api/job/jobboardsearchavailabilitycheck',
    ADD_JOB_BOARD_CREDENTIALS: 'api/job/addjobboardcredentials',
    DELETE_JOB_BOARD_CREDENTIALS: 'api/job/deletejobboardcredentials',
    GET_BU_VENDORS: 'api/requisition/getvendorlist',
    COPY_REQUISITION: 'api/job/copy/requisition',
    GET_REQUISITIONS_WORK_FLOW_STEP: 'api/requisition/getrequisitiondetailsbyclientorbuid',
    GET_SALARY_DURATION_OPTIONS: 'api/requisition/salarydurationoptions',
    GET_CURRENCY_CODES: 'api/requisition/currencycodes',
    GET_ASSIGNED_VENDORS: 'api/requisition/getassignedvendors',
    GET_CANDIDATE_RELEASED_JOB_DETAILS: 'api/job//getreleasedjobdetails',
    GET_LAST_ADDED_CANDIDATE_SOURCE_TYPE_ID: 'api/job/getlastaddedcandidatesourcetypeid',
    GET_POC_ON_JOB: 'api/job/getpointofcontact',
    GET_ASSESSMENT_DETAILS: 'api/job/getsentassessmentdetailsbyassessmentid',
    GET_REQUISITIONS_BY_ASSESSMENT: '/api/job/getrequisitionsbyassement',
    GET_SHARED_JOB_WORKFLOW_DETAILS: 'api/job/getsharedjobworkflowdetails',
    GET_LAST_CANDIDATE_ADD_OPTION: 'api/job/getlastaddcandidateoption',
    GET_ALL_JOBS_BY_SCOPE: 'api/job/getAllJobsByScope',
    GET_CANDIDATES_FOR_REQ_STATUS: 'api/job/getcandidatesforcurrentstatus',
    PERFORM_JOB_LEFT: 'api/job/performJobLeft',
    GET_CANDIDATE_JOB_LEFT_DETAILS: 'api/job/getCandidateJobLeaveDetails',
    CLEAR_JOB_LOCATION: 'api/job/clearLocation',
    GET_WORK_LOCATIONS: 'api/job/getAllWorkLocation',
    ADD_SHARED_REQUISITION_NOTE: 'api/job/addSharedRequisitionNote',
    GET_SHARED_REQUISITION_NOTE: 'api/job/getSharedRequisitionNote',
    GET_CANDIDATE_JOB_COMPARISON: 'api/job/candidateprofilecomparisonwithjobandcandidate',
    DELETE_REQUISITION_ELEMENTS: 'api/job/deleteRequisitionElements',
    UPDATE_JOB_MATCH_SCORES: 'api/job/updateJobMatchScores',
    GET_JOB_REPORT_PDF: 'api/job',
    GET_ALL_JOB_JOB_SUB_TYPES: 'api/job/getJobSubTypes',
    ADD_END_CLIENT: 'api/job/addUpdateEndClient',
    UPDATE_END_CLIENT: 'api/job/addUpdateEndClient',
    DELETE_END_CLIENT: 'api/job/deleteEndClient',
    GET_END_CLIENTS: 'api/job/getEndClients',
    GET_ALL_SKILLS: 'api/manage/skills',
    CREATE_JOB_DESCRIPTION: 'api/job/jobDescription',
  };
  constructor(@Inject(GenericService) private genericService: GenericService,
    @Inject(StorageService) private StorageService: StorageService, @Inject(AuthService) private authService: AuthService) {

  }

  getJob(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_JOB + '/' + jobId + '?companyId=' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllJobTypes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_JOB_TYPES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllSponsorshipTypes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SPONSORSHIPS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllRecruiters(userDetails, company, clientOrBuId, requisitionId, clientOrBuIds, requisitionNumbers, isUploadOrEditReq, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_RECRUITERS + '/' + userDetails.id + '/' + company.companyId;
    if (!_.isNull(clientOrBuId)) {
      url = url + '?clientId=' + clientOrBuId;
    }
    if (!_.isNull(requisitionId)) {
      let separator = url.includes("clientId") ? '&' : '?';
      url = url + separator + 'requisitionId=' + requisitionId;
    }
    let separator = url.includes('clientId') || url.includes('requisitionId') ? '&' : '?';
    url = url + separator + 'isJobWorkflow=' + isUploadOrEditReq;

    let payload = { clientOrBuIds: clientOrBuIds, requisitionNumbers: requisitionNumbers };
    this.genericService.addObject(url, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getLicensePreferences(company, clientorbu, jobId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_LICENSE_PREFERENCE + '/' + company.companyId + '/' + jobId;
    if (clientorbu) {
      url = url + '?clientorbuid=' + clientorbu.id;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveJob(job, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_JOB, job).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  setJobStatusToOpen(jobId, successCallback, errorCallback) {
    let jobStateObject = {
      "jobId": jobId,
      "jobStateType": "OPEN"
    };
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SET_JOB_STATUS, jobStateObject).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  runJobForScoring(companyId, jobId, transactionId, successCallback, errorCallback) {
    let transactionType = 'RequisitionTransaction';
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.RUN_JOB_FOR_SCORING + '/' + companyId + '/' + jobId + '/' + transactionType + '/' + transactionId + '/' + transactionId, {}).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateCount(jobId, score, transactionId, isNew, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_COUNT + '/' + jobId + '?score=' + score + '&requisitionTransactionId=' + transactionId + '&isNewReq=' + isNew).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  createUpdateJobDetails(updateFlag, saveObject, companyId, clientOrBuId, userId, successCallback, errorCallback) {
    let url = this.urlConstants.CREATE_JOB_DETAILS + '/' + companyId;
    if (updateFlag) {
      url = this.urlConstants.UPDATE_JOB_DETAILS + '/' + companyId + '/' + userId;
    }
    if(saveObject.asyncActionType) {
      url = this.urlConstants.UPDATE_JOB_DETAILS + '/' + companyId + '/' + userId + '/async';
    }
    this.genericService.addObject(this.StorageService.get('baseurl') + url + '?clientOrBuid=' + clientOrBuId, saveObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  searchByReqNum(userId, companyId, searchText, filter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SEARCH_BY_REQNUMS + '/' + userId + '/' + companyId + '/' + searchText, filter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getRoles(userId, companyId, searchText, clientOrBuIds, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ROLES + '/' + userId + '/' + companyId + '/' + searchText, clientOrBuIds).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  searchJobsByText(userId, companyId, searchText, filterObj, successCallback, errorCallback) {
    filterObj.searchText = searchText;
    let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SEARCH_BY_TEXT_JOBS + '/' + userId + '/' + companyId, filterObj);
    promise.subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
    return promise;
  }

  getAllStatusTypes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_STATUS_TYPES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobProfile(jobId, successCallback, errorCallback) {
    let url = `${this.StorageService.get('baseurl')}${this.urlConstants.GET_JOB_PROFILE}/${jobId}?companyId=${this.authService.getUserDetails().company.companyId}`;
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidatesByRequisitionTransaction(jobId, companyId, score, reqTransactionId, countType, successCallback, errorCallback) {
    let requestParams = '?' + 'requisitionTransactionId=' + reqTransactionId + '&' + 'score=' + score;
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATES_BY_REQ_TRANSACTION + '/' + jobId + '/' + countType + '/' + companyId + requestParams).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    })
  }

  getActivityTypes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ACTIVITY_TYPES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getActivityTypesForFilter(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ACTIVITY_TYPES_FOR_FILTER).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getEnabledWorkflowSteps(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ENABLED_WORKFLOW_STEPS + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobCountByStatus(userId, companyId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_DASHBOARD_JOB_COUNT + '/' + userId + '/' + companyId;
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  multipleCandidatesSingleJobComparison(jobId, stringOfCandidateIds, companyId, successCallback, errorCallback) {
    if (_.isNull(stringOfCandidateIds) || _.isUndefined(stringOfCandidateIds)) {
      stringOfCandidateIds = '';
    }
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.MULTI_CANDIDATE_SINGLE_JOB_COMPARISON + '/' + jobId + '?candidateIds=' + stringOfCandidateIds + '&companyId=' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  findMatchingCandidatesForJob(searchObject, successCallback, errorCallback) {
    let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.FIND_MATCHING_CANDIDATES_FOR_JOB, searchObject);
    promise.subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
    return promise;
  }

  getAssessmentAccessTime(jobId, licensePreferenceEnum, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_ASSESSMENT_ACCESS_TIME;
    let payload = {
      jobId: jobId,
      licensePreferenceEnum: licensePreferenceEnum
    };
    this.genericService.addObject(url, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  update4dot5Score(scoreObject, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_JOB_4DOT5_SCORE, scoreObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateJobLocation(locationObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_JOB_LOCATION, locationObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getGetMoreResumesJobStatus(jobId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_GET_MORE_RESUMES_JOB_STATUS + '/' + jobId;
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getResumesFromJobBoards(jobId, jobBoardDetails, resumesToPullPerJobBoardCount, radius, recency, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_RESUMES_FROM_JOB_BOARDS;
    let payload = {
      jobId: jobId,
      jobBoardDetails: jobBoardDetails,
      resumesToPullPerJobBoardCount: resumesToPullPerJobBoardCount,
      radius: radius,
      recency: recency,
      currentCompanyId: this.authService.getUserDetails().company.companyId
    };
    // payload.jobId = jobId;
    // payload.jobBoardDetails = jobBoardDetails;
    // payload.resumesToPullPerJobBoardCount = resumesToPullPerJobBoardCount;
    // payload.radius = radius;
    // payload.recency = recency;
    // payload.currentCompanyId = this.$rootScope.userDetails.company.companyId;
    this.genericService.addObject(url, payload).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getRequisitionDetails(jobId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITION_CARD + '/' + jobId + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobBoardParameters(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_JOB_BOARD_PARAMETERS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobBoardSearchInterface(searchObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_SEARCH_INTERFACE, searchObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobBoardsSearchHistory(jobId, page, size, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_JOB_SEARCH_HISTORY + '/' + jobId + '?page=' + page + '&size=' + size).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteJob(deleteJobObject, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_JOB, deleteJobObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getRadiusAndRecencyOptions(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_RADIUS_AND_RECENCY_OPTIONS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveDaxtraParameters(jobId, jobBoardParamsObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_DAXTRA_PARAMETERS + '/' + jobId, jobBoardParamsObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobBoardPullCounts(jobId, jbiTransactionId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_COUNT_BY_JOB_ID + '/' + jobId;
    if (!_.isNull(jbiTransactionId)) {
      url += '?jbiTransactionId=' + jbiTransactionId;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobBoardPullMatchInfo(jbiTransactionId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_JOB_MATCH_RESULTS + '/' + jbiTransactionId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  removeDaxtraParameters(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.REMOVE_DAXTRA_PARAMETERS + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  checkJobBoardsSearchAvailability(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHECK_JOB_BOARDS_SEARCH_AVAILABILITY).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getParsedJobLocationText(location, isHtml) {
    let locationText = "";
    if (isHtml) {
      if (!(_.isNull(location.city))) {
        locationText = '<span><b> @ </b>' + location.city + '</span>';
      }
      if (!_.isNull(location.state)) {
        locationText = locationText + '<span>, ' + location.state + '</span>';
      }
    } else {
      if (!(_.isNull(location.city))) {
        locationText = " @ " + location.city;
      }
      if (!_.isNull(location.state)) {
        locationText = locationText + ", " + location.state;
      }
    }
    return locationText;
  }

  getJobLocationInfoText(jobInfo, isCorporateFlag) {
    let headerInfo = "";
    let jobLocation = "";
    if (!jobInfo.sharedRequisition || (jobInfo.sharedRequisition && isCorporateFlag)) {
      if (!jobInfo.sharedRequisition && jobInfo.clientName != null && jobInfo.clientName != '') {
        headerInfo = headerInfo + " at " + jobInfo.clientName;
      }
      if (jobInfo.sharedRequisition && isCorporateFlag && jobInfo.corporateBU != null && jobInfo.corporateBU != '') {
        headerInfo = headerInfo + " at " + jobInfo.corporateBU;
      }
      if (jobInfo.location != null && jobInfo.location.city != null && jobInfo.location.city.name != null && jobInfo.location.city.name != '') {
        let city = headerInfo + " @ " + jobInfo.location.city.name;
        headerInfo = city;
        jobLocation = city;
      }
      if (jobInfo.location != null && jobInfo.location.state != null && jobInfo.location.state != '') {
        let state = headerInfo + ", " + jobInfo.location.state;
        headerInfo = state;
        jobLocation = state;
      }
    }

    if (jobInfo.sharedRequisition && !isCorporateFlag) {
      headerInfo = headerInfo + " at " + jobInfo.corporateName;
      if (jobInfo.corporateBU != null && jobInfo.corporateBU != '') {
        headerInfo = headerInfo + " at " + jobInfo.corporateBU;
      }
      if (jobInfo.location != null && jobInfo.location.city != null && jobInfo.location.city.name != null && jobInfo.location.city.name != '') {
        headerInfo = headerInfo + " @ " + jobInfo.location.city.name;
      }
      if (jobInfo.location != null && jobInfo.location.state != null && jobInfo.location.state != '') {
        headerInfo = headerInfo + ", " + jobInfo.location.state;
      }
    }
    return { headerInfo: headerInfo, jobLocation: jobLocation };
  }

  addJobBoardCredentials(credentialsObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_JOB_BOARD_CREDENTIALS, credentialsObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteJobBoardCredentials(accountToken, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.DELETE_JOB_BOARD_CREDENTIALS + '?accountToken=' + accountToken).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getBuVendors(companyId, buId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_BU_VENDORS + '/' + companyId;
    if (!_.isNull(buId)) {
      url += '?buId=' + buId;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  copyRequisition(requisitionId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.COPY_REQUISITION + '/' + requisitionId + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getRequisitionsInWorkFlowStep(stepDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITIONS_WORK_FLOW_STEP, stepDetails).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSalaryDurationOptions(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SALARY_DURATION_OPTIONS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCurrencyCodes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CURRENCY_CODES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAssignedVendors(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ASSIGNED_VENDORS + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getReleasedCandidateJobDetails(candidateId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_RELEASED_JOB_DETAILS + '/' + candidateId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getLastAddedCandidateSourceTypeId(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_LAST_ADDED_CANDIDATE_SOURCE_TYPE_ID + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPocOnJob(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_POC_ON_JOB + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAssessmentDetails(assessmentId, jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ASSESSMENT_DETAILS + '/' + assessmentId + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getRequisitionsByAssessment(assessmentDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_REQUISITIONS_BY_ASSESSMENT, assessmentDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSharedJobWorkflowDetails(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SHARED_JOB_WORKFLOW_DETAILS + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getLastCandidateAddOption(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_LAST_CANDIDATE_ADD_OPTION + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllJobsByScope(filters, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_JOBS_BY_SCOPE, filters).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidatesByJobStatus(jobId, companyId, jobStatus, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATES_FOR_REQ_STATUS + '/' + jobId + '/' + jobStatus + '?forJobLeft=true' + "&companyId=" + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  performJobLeft(jobLeftDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.PERFORM_JOB_LEFT, jobLeftDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateJobLeaveDetails(jobId, candidateId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_JOB_LEFT_DETAILS + '/' + candidateId + '/' + companyId + '?jobId=' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  clearJobLocation(jobId, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.CLEAR_JOB_LOCATION + '/' + jobId, {}).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getWorkLocations(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_WORK_LOCATIONS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addSharedRequisitionNote(requisitionNote, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_SHARED_REQUISITION_NOTE, requisitionNote).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSharedRequisitionNote(jobId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SHARED_REQUISITION_NOTE + '/' + jobId + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateJobComparison(candidateId, jobId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_JOB_COMPARISON + '/' + candidateId + '/' + jobId + '?companyId=' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteRequisitionElements(requisitionElements, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_REQUISITION_ELEMENTS, requisitionElements).subscribe((data) => {
      successCallback(data.body)
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobMatchScores(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_JOB_MATCH_SCORES + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobReportPdfUrl(jobId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_JOB_REPORT_PDF + "/" + jobId + "/report";
  }

  getJobDownloadPdfUrl(jobId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_JOB_REPORT_PDF + "/" + jobId + "/pdf_download";
  }
  
  getAllJobSubTypes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_JOB_JOB_SUB_TYPES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getEndClients(companyId, jobId, endClientName) {
    let url = endClientName ? (this.urlConstants.GET_END_CLIENTS + "/" + companyId + "?jobId=" + jobId + "&name=" + endClientName) : (this.urlConstants.GET_END_CLIENTS + "/" + companyId)
    return new Promise((resolve, reject) => {
      this.genericService.getObjects(this.StorageService.get('baseurl') + url).subscribe((data) => {
        resolve(data.body)
      })
    })
  }

  addEndClient(userID, endClientDetails, successCallback, errorCallback) {
    let url = userID ? (this.urlConstants.ADD_END_CLIENT + "?userId=" + userID) : (this.urlConstants.ADD_EDIT_END_CLIENT)
    this.genericService.addObject(this.StorageService.get('baseurl') + url, endClientDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  editEndClient(userID, endClientId, endClientDetails, successCallback, errorCallback) {
    let url = this.urlConstants.UPDATE_END_CLIENT + "?userId=" + userID + "&jobId=" + endClientId;
    this.genericService.addObject(this.StorageService.get('baseurl') + url, endClientDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteEndClient(endClientId, successCallback, errorCallback) {
    let url = this.urlConstants.DELETE_END_CLIENT + "/" + endClientId;
    this.genericService.getObjects(this.StorageService.get('baseurl') + url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getJobScoringJobStatus(jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + `api/job/${jobId}/async/status`).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllSkills(skillFilterObject, successCallback, errorCallback) {
    let queryParams = [];
    if (skillFilterObject.searchSkill && skillFilterObject.searchSkill != '') {
      queryParams.push(`searchSkill=${skillFilterObject.searchSkill}`);
    }
    if (skillFilterObject.searchByParentSkill && skillFilterObject.searchByParentSkill != '') {
      queryParams.push(`searchByParentSkill=${skillFilterObject.searchByParentSkill}`);
    }
    if (skillFilterObject.searchCategory && skillFilterObject.searchCategory != '') {
      queryParams.push(`searchCategory=${skillFilterObject.searchCategory}`);
    }
    if (skillFilterObject.sortColumn) {
      queryParams.push(`sortColumn=${skillFilterObject.sortColumn}`);
    }
    if (skillFilterObject.sortDir) {
      queryParams.push(`sortDir=${skillFilterObject.sortDir}`);
    }
    if (!_.isNull(skillFilterObject.includeAlternateSkills) || !_.isUndefined(skillFilterObject.includeAlternateSkills)) {
      queryParams.push(`includeAlternateSkills=${skillFilterObject.includeAlternateSkills}`);
    }

    let filterObj = queryParams.length ? `?${queryParams.join('&')}` : '';

    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SKILLS + filterObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  createJobDescription(saveObject, companyId, clientOrBuId, userId, successCallback, errorCallback) {
    let url = this.urlConstants.CREATE_JOB_DESCRIPTION + `?companyId=${companyId}` + `&isSharedClientRequisition=${saveObject.isSharedClientRequisition}` + `&buId=${clientOrBuId}` + `${ saveObject.sharedClientBuOrCorpId ? `&sharedClientBuOrCorpId=${saveObject.sharedClientBuOrCorpId}` : ""}` + `&userId=${userId}`;
    this.genericService.addObject(this.StorageService.get('baseurl') + url, saveObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

}
