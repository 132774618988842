export class JobLeftInfo {
  jobId: any;
  companyId: any;
  candidate: any;
  candidateIds: any = [];
  jobLeftDate: any = "";
  reasonCodeDTO: any;
  former: any;
  reduceJobOpening: boolean = false;
  hireAgain: string = "HIRE_ON_COMPANY_POLICY";
  doNotHireAtClient: any;
  later: any;
  hireAgainBasedOnCompanyPolicy: any;
  hireAgainAsap: any;
  joiningAnotherRoleWithInCompany: any;
  dontHireAgain: any;
  dontHireAgainCompanyIds: any;
  note: any;
  externalNote: any;

  constructor(jobId, companyId) {
    this.jobId = jobId;
    this.companyId = companyId;
  }
}