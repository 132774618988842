<div *ngIf="!isCandidateDetailsUpdatedLoading" class="col-xs-12" style="max-height: 600px; overflow-y: auto;">
    <label style="position:relative; top:40px; z-index:1">
        Show 
        <select name="limit" id="limit" style="width:60px"
            [(ngModel)]="jobMatchesLimit" 
            (change)="getPossibleJobMatchesOfCandidate()" 
            required>
            <option *ngFor="let limit of jobMatchesLimits; let i = index" [value]="limit.value">
                {{ limit.name }}
            </option>
        </select> 
        entries
    </label>
    <div *ngIf="!loadingFlag" class="cursor-default table-responsive client-or-bu-table">
        <table id="jobMatchesTable" class="table" datatable [dtOptions]="dtOptions">
            <thead>
                <tr>
                    <!-- <th class="text-center">
                        Rank
                    </th>  -->
                    <th class="text-left">
                        Job Title
                    </th>
                    <th class="text-left">
                        Job Match Details
                    </th>
                    <th class="text-left">
                        Company
                    </th> 
                    <th class="text-left">
                        Req# (Status)
                    </th>
                    <th class="text-left">
                        Recruiter (<sup class="fa fa-star emerald" style="font-size: 0.7em"></sup> = Assigned)
                    </th>
                    <th class="text-center">
                        Job Match Score
                    </th>
                    <th class="text-center">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobMatch of jobMatches; let i = index" [ngClass]="{'job-match-disabled': jobMatch.jobMatchDisabled, 
                    'job-match-restricted-access': jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser}">
                    <!-- <td class="text-center">
                        <span tooltip="Job Match Rank" tooltip-placement="top-left">
                            {{ :: jobMatch.jobMatchRank}}
                        </span>
                    </td>  -->
                    <td class="text-left">
                        <span tooltip="{{jobMatch.title}}"
                            (click)="goToJobProfile(jobMatch.jobId, jobMatch.jobMatchDisabled)">
                            <a class="cursor-pointer"
                                *ngIf="!jobMatch.jobMatchDisabled">{{jobMatch.title | truncatePipe : 25}}
                                <span *ngIf="jobMatch.title.length > 25">..</span>
                            </a>
                            <span *ngIf="jobMatch.jobMatchDisabled">
                                {{jobMatch.title| truncatePipe : 25}}
                                <span *ngIf="jobMatch.title.length > 25">..</span>
                            </span>
                        </span>

                       <app-info-icon *ngIf="jobMatch.requisitionNote" style="font-size:10px;"
                            [info]="jobMatch.requisitionNote" 
                            infoTitle="Requisition Info">
                        </app-info-icon>
                    </td>
                    <td class="text-left">
                        <app-candidate-card-icons context="candidatePossibleJobMatches" [candidate]="jobMatch"
                            [candidateAdditionalDetails]="jobMatch.candidateAdditionalDetailsCard"
                            [candidateSalaryDurationOptions]="salaryDurationOptions" [jobTypeList]="jobTypeList">
                        </app-candidate-card-icons>
                        <div *ngIf="jobMatch.contractDuration">
                            {{jobMatch.contractDuration | contractDurationPipe}}
                        </div>
                    </td>
                    <td class="text-left" [ngClass]="{'possible-job-match-shared-requisition-color': jobMatch.sharedJob}">
                        <span tooltip="{{jobMatch.company}}">
                               {{jobMatch.company | truncatePipe : 25}}
                            <span *ngIf="jobMatch.company.length > 25">..</span>
                        </span>
                        <app-info-icon *ngIf="jobMatch.clientNote" style="font-size:10px;"
                            [info]="jobMatch.clientNote" 
                            infoTitle="Info">
                        </app-info-icon>
                        <div tooltip="{{jobMatch.jobDetailsText}}">
                                {{jobMatch.jobDetailsText | truncatePipe : 25}}
                             <span *ngIf="jobMatch.jobDetailsText && jobMatch.jobDetailsText.length > 25">..</span>
                        </div>
                    </td> 
                    <td class="text-left">
                        <span tooltip="{{jobMatch.requisitionNumber}}" (click)="goToWorkflow(jobMatch, jobMatch.jobMatchDisabled)">
                            <a *ngIf="!jobMatch.jobMatchDisabled">{{jobMatch.requisitionNumber | truncatePipe : 12}}
                                <span *ngIf="jobMatch.requisitionNumber.length > 12">..</span>
                            </a>
                            <span *ngIf="jobMatch.jobMatchDisabled">{{jobMatch.requisitionNumber | truncatePipe : 12}}
                                 <span *ngIf="jobMatch.requisitionNumber.length > 12">..</span>
                            </span>
                        </span>
                        <span tooltip="{{jobMatch.requisitionStatus}}" [ngClass]="{'possible-job-match-shared-requisition-color': jobMatch.sharedJob}">
                            ({{jobMatch.requisitionStatus | truncatePipe : 8}})
                            <span *ngIf="jobMatch.requisitionStatus.length > 8">..</span>
                        </span>
                    </td>
                    <td class="text-left">
                        <div *ngFor="let recruiter of jobMatch.recruitersList; let $index=index">
                            <span tooltip="{{recruiter.name}}" [innerHtml]="recruiter.displayName" 
                                [ngClass]="{'possible-job-match-job-vendor-recruiter': jobMatch.sharedJob && ((!isStaffingCompany && recruiter.vendorRecruiter) || (isStaffingCompany && !recruiter.vendorRecruiter)),
                                'emerald': jobMatch.sharedJob && ((!isStaffingCompany && !recruiter.vendorRecruiter) || (isStaffingCompany && recruiter.vendorRecruiter))}"></span>
                            <sup tooltip="Assigned Recruiter of Job Match" *ngIf="recruiter.assignedRecruiter" class="fa fa-star emerald" style="font-size: 0.7em"></sup>
                            <sup tooltip="Point of contact" style="font-size: 0.7em"  *ngIf="recruiter.assignedRecruiter && (recruiter.name == jobMatch.poc)"
                                 [ngClass]="{'possible-job-match-job-vendor-recruiter': (!isStaffingCompany && !recruiter.vendorRecruiter) || (isStaffingCompany && recruiter.vendorRecruiter)}">POC</sup>
                            <span *ngIf="recruiter.primaryHiringManager" tooltip="Primary Hiring Manager" class="possible-job-matches-primary-hiring-manager-icon emarald">P</span>
                        </div>
                    </td>
                    <td class="text-center">
                        <span class="job-match-score" [ngClass]="{'dark-orange-bg': jobMatch.fourDotFiveIntelligentScore<jobMatch.fourDotFiveIntelligentThresholdScore, 'green-bg': jobMatch.fourDotFiveIntelligentScore>=jobMatch.fourDotFiveIntelligentThresholdScore, 'grey-bg': jobMatch.fourDotFiveIntelligentScore == 'N/A' }">
                            <span tooltip="Threshold score is {{jobMatch.fourDotFiveIntelligentThresholdScore}}" (click)="jobCompare(jobMatch)">
                                <a class="cursor-pointer" style="color:white">
                                    <b>{{jobMatch.fourDotFiveIntelligentScore}}</b>
                                </a>
                            </span>
                        </span>
                    </td>
                    <td class="text-center">
                        <button class=" btn btn-primary" [disabled]="jobMatch.jobMatchDisabled" (click)="onAssociateToJobClick(jobMatch)">Associate to Job</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="isCandidateDetailsUpdatedLoading" style="height: 200px; overflow-y: auto;"
    class="row d-flex justify-content-center align-items-center">
    <div class="col-xs-12 no_candidates_found text-center">
        <span>Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>

<ng-template #candidateJobMatchActionsTempalte>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span>{{candidateJobMatchActionsLabel}}</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeCandidateJobMatchActionsModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row"> 
            <div class="col-xs-12" *ngIf="candidateJobMatchActionsActionType === 'jobProfile'">
                <app-job-profile [jobId]="candidateJobMatchActionsJobId"></app-job-profile>
            </div>
            <div class="col-xs-12" *ngIf="candidateJobMatchActionsActionType === 'candidateJobComparison'">
                <candidate-job-match-profile 
                    [jobId]="candidateJobMatchActionsJobId"
                    [candidateId]="candidateId"
                    [jobMatchId]="candidateJobMatchActionsJobMatchId"
                    context="candidateJobComparison">
                </candidate-job-match-profile>
            </div>
        </div>
    </div>
</ng-template>