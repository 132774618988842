<div class="row fuelux">
	<div class="col-lg-12">
		<div class="main-box clearfix">
			<div class="main-box-body clearfix wizardmainbox">
				<div class="row">
					<div class="col-xs-12 main-box-header d-flex flex-row-reverse">
					   <button type="button" class="btn btn-danger btn-cancel" (click)="wizardCancel()">
						<i class="fas fa-times"></i> Cancel
						</button>
					</div>
			   </div>
				<div class="wizard" data-initialize="wizard" id="uploadFileWizard">
					<div class="steps-container">
						<ul class="steps">
							<li data-step="1" data-name="upload" class="active">
								<span class="badge">1</span>Upload<span class="chevron"></span>
							</li>
							<li data-step="2" data-name="scoring">
								<span class="badge">2</span>
								<span *ngIf="jobContextExistsFlag">Review</span>
								<span *ngIf="!jobContextExistsFlag">View Results</span>
								<span class="chevron"></span>
							</li>
						</ul>
					</div>
					<div class="actions">
						<button type="button" class="btn btn-info btn-prev mr-2"
							[disabled]="disablePreviousButtonFlag">
							<span class="glyphicon glyphicon-arrow-left"></span>Prev
						</button>
						<button type="button" class="btn btn-info btn-next" data-last="Complete"
							[disabled]="disableNextButtonFlag">
							Next<span class="glyphicon glyphicon-arrow-right"></span>
						</button>
					</div>
					<div class="step-content">
						<div class="step-pane active" data-step="1">
							<div class="row"> 
								<div class="col-lg-12">
									<div class="main-box clearfix" style="min-height: 200px;">
										<div class="row">
										</div>
										<div class="main-box-body clearfix col-lg-10 col-lg-offset-1"
											style="margin-top:50px">
											<p class="text-center">Upload CSV or Excel file.
												<a class="cursor-pointer" (click)="showFileFormat()"> Click here</a> for format.
											</p>
											<div>
												<form name="fileUploadDropzone" id="fileUploadDropzone"
													class="dropzone dz-clickable" action=""
													enctype="multipart/form-data">
													<div class="dz-a dz-default dz-message">
														<span>
															<h3>Drop file here to upload</h3>(or click)
														</span>
													</div>
												</form>
											</div>
										</div>
										<!-- Upload candidates button -->
										<div class="row buttonrow" style="margin-bottom: 200px">
											<div class="col-xs-12 col-sm-offset-4 col-sm-4">
												<button type="button" class="btn btn-success col-xs-12" id="uploadFile"
													(click)="uploadFile()"
													[disabled]="disableUploadButtonFlag">
													<i class="fa fa-upload mr-2"></i>
													<span *ngIf="!fileUploadInProgressFlag">Upload
													</span><span
														*ngIf="fileUploadInProgressFlag">Uploading... </span>
													File
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="step-pane" data-step="2">
							<div class="row">
								<div class="col-xs-12">
									<div class="main-box clearfix" style="min-height: 150px;">
										<div class="row">
												<div class="upload-resumes-counts-heading">Candidate Counts</div>
										</div>
										<div class="row upload-resumes-counts-row">
											<div class="col-xs-offset-1 col-xs-3">
												<span>Uploaded Candidates :</span>
												<span class="pull-right">
													<span *ngIf="candidateCounts">{{candidateCounts.uploadedCandidateCount}}</span>
												</span>
											</div>
											<div class="col-xs-offset-1 col-xs-3">
												<span>Not Processed Candidates :</span>
												<span class="pull-right">
													<span *ngIf="candidateCounts">{{candidateCounts.notProcessedCandidateCount}}</span>
                                                </span>
											</div>
										</div>
										<div class="row upload-resumes-counts-row">
											<div class="col-xs-offset-1 col-xs-3">
												<span>Matched Candidates :</span>
												<span class="pull-right">
													<span *ngIf="candidateCounts">{{candidateCounts.autoMatchedCandidateCount}}</span>
												</span>
											</div>
											<div class="col-xs-offset-1 col-xs-3">
												<span>Unmatched Candidates :</span>
												<span class="pull-right">
													<span *ngIf="candidateCounts">{{candidateCounts.unmatchedCandidateCount}}</span>
												</span>
											</div> 
										</div> 
										<div class="row upload-resumes-counts-row">
											<div class="col-xs-offset-1 col-xs-3" *ngIf="jobContextExistsFlag">
												<span>Manually Matched Candidates :</span>
												<span class="pull-right">
													<span *ngIf="candidateCounts">{{candidateCounts.manuallyMatchedCandidateCount}}</span>
												</span>
											</div>
										</div> 
									</div>
								</div>
								<div class="col-xs-12">
									<div class="main-box clearfix">
										<div class="main-box-body clearfix bulk-upload-table-container">
											<table class="table table-bordered table-responsive user-configuration-table">
												<tbody>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Name </th>
                                                        <th>Phone number</th>
                                                        <th>Email</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    <tr *ngFor="let candidate of candidateList; let $index = index">
                                                        <td class="text-center">{{$index + 1}}.</td>
                                                        <td>{{candidate.name}}</td>
                                                        <td>{{candidate.phoneNumber}}</td>
                                                        <td>{{candidate.email}}</td>
                                                        <td>
                                                            <i class="fas" [ngClass]="{'fa-check green':candidate.status !== 'FAILED'}"></i>
                                                            <i class="fas" [ngClass]="{'fa-times entity-upload-failed-icon text-danger':candidate.status == 'FAILED'}"></i>
                                                            <span [innerHtml]="candidate.message" class="ml-2"></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-12 px-0 mb-3" *ngIf="isOutreachEnabled">
								<app-send-candidate-outreach-email [outreachEmailId]="candidateFileUpload" [sendOutreachEmail]="sendOutreachEmail" (sendOutreachEmailUpdated)="sendOutreachEmail = $event"></app-send-candidate-outreach-email>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #viewCandidateFileUploadFormat>
    <div class="modal-header" style="padding: 15px !important; border-bottom: 1px solid #e5e5e5;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
            (click)="closeFileFormateModal()">×</button>
        <h4 class="modal-title wrap-text-in ng-binding">
            Candidates Upload File Format
        </h4>
    </div>

    <div class="modal-body">
        <app-upload-file-sample-format [uploadType]="{action: 'candidateFileUpload'}"></app-upload-file-sample-format>
    </div>
</ng-template>