import { downgradeComponent } from "@angular/upgrade/static";
import { VendorsPageComponent } from './vendors-page.component';

export const VendorsPageAjsModule = angular
  .module('vendorsPage', [])
  .directive('vendorsPage', downgradeComponent({ component: VendorsPageComponent }))
  .config(
    ['$stateProvider', ($stateProvider) => {
      $stateProvider.state('missioncontrol.vendors', {
        url: '/vendors',
        component: VendorsPageComponent,
        data: {
          requireLogin: true
        }
      });
    }
    ])
  .name;
