import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageRequisitionEntitiesPageComponent } from './manage-requisition-entities-page.component';
import { RequisitionEntitiesUploadComponent } from './requisition-entities-upload/requisition-entities-upload.component';
import { UploadFilesModule } from 'src/app/shared/upload-files/upload-files.module';
import { TabsModule, TooltipModule } from 'ngx-bootstrap';
import { ManageSkillsPageModule } from '../manage-skills-page/manage-skills-page.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { AddEditEntitiesComponent } from './add-edit-entities/add-edit-entities.component';
import { TagInputModule } from 'ngx-chips';
import { ReqEntitiesActivitiesComponent } from './req-entities-activities/req-entities-activities.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { UploadReqEntitiesResultsComponent } from './upload-req-entities-results/upload-req-entities-results.component';
import { ViewUploadReqEntitiesComponent } from './view-upload-req-entities/view-upload-req-entities.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [ManageRequisitionEntitiesPageComponent, RequisitionEntitiesUploadComponent, AddEditEntitiesComponent, ReqEntitiesActivitiesComponent, UploadReqEntitiesResultsComponent, ViewUploadReqEntitiesComponent],
  imports: [
    CommonModule,
    FormsModule,
    UploadFilesModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    ManageSkillsPageModule,
    NgxDatatableModule,
    TagInputModule,
    PipesModule,
    NgSelectModule
  ],
  exports: [ManageRequisitionEntitiesPageComponent, RequisitionEntitiesUploadComponent, ReqEntitiesActivitiesComponent, UploadReqEntitiesResultsComponent, ViewUploadReqEntitiesComponent],
  entryComponents: [ManageRequisitionEntitiesPageComponent, RequisitionEntitiesUploadComponent, ReqEntitiesActivitiesComponent, UploadReqEntitiesResultsComponent, ViewUploadReqEntitiesComponent]
})
export class ManageRequisitionEntitiesPageModule { }
